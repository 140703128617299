// returns true if device uses "american" style dates e.g 01/31/2019
export default function dateFormatDetect() {
    // use a date where this is no ambiguity between date and month
    const testDate = '2020-01-31T14:44:48.724Z';

    // get local date string
    const ls = new Date(testDate).toLocaleDateString();

    if (ls === '1/31/2020') {
        return true;
    }

    return false;
}
