(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.b8.be === region.cs.be)
	{
		return 'on line ' + region.b8.be;
	}
	return 'on lines ' + region.b8.be + ' through ' + region.cs.be;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dF,
		impl.d8,
		impl.d3,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		aR: func(record.aR),
		b9: record.b9,
		b4: record.b4
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.aR;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.b9;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.b4) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dF,
		impl.d8,
		impl.d3,
		function(sendToApp, initialModel) {
			var view = impl.eb;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dF,
		impl.d8,
		impl.d3,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.b7 && impl.b7(sendToApp)
			var view = impl.eb;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.dl);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.d6) && (_VirtualDom_doc.title = title = doc.d6);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dS;
	var onUrlRequest = impl.dT;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		b7: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cU === next.cU
							&& curr.cC === next.cC
							&& curr.cP.a === next.cP.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dF: function(flags)
		{
			return A3(impl.dF, flags, _Browser_getUrl(), key);
		},
		eb: impl.eb,
		d8: impl.d8,
		d3: impl.d3
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dA: 'hidden', dn: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dA: 'mozHidden', dn: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dA: 'msHidden', dn: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dA: 'webkitHidden', dn: 'webkitvisibilitychange' }
		: { dA: 'hidden', dn: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		c1: _Browser_getScene(),
		db: {
			df: _Browser_window.pageXOffset,
			dg: _Browser_window.pageYOffset,
			de: _Browser_doc.documentElement.clientWidth,
			cA: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		de: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		cA: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			c1: {
				de: node.scrollWidth,
				cA: node.scrollHeight
			},
			db: {
				df: node.scrollLeft,
				dg: node.scrollTop,
				de: node.clientWidth,
				cA: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			c1: _Browser_getScene(),
			db: {
				df: x,
				dg: y,
				de: _Browser_doc.documentElement.clientWidth,
				cA: _Browser_doc.documentElement.clientHeight
			},
			dt: {
				df: x + rect.left,
				dg: y + rect.top,
				de: rect.width,
				cA: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.dv.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.dv.b, xhr)); });
		$elm$core$Maybe$isJust(request.d7) && _Http_track(router, xhr, request.d7.a);

		try {
			xhr.open(request.dK, request.d9, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.d9));
		}

		_Http_configureRequest(xhr, request);

		request.dl.a && xhr.setRequestHeader('Content-Type', request.dl.a);
		xhr.send(request.dl.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.dz; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.d4.a || 0;
	xhr.responseType = request.dv.d;
	xhr.withCredentials = request.dj;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		d9: xhr.responseURL,
		d0: xhr.status,
		d1: xhr.statusText,
		dz: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			d_: event.loaded,
			c3: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			dY: event.loaded,
			c3: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.dP) { flags += 'm'; }
	if (options.dm) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $author$project$Main$LinkClicked = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 8, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.k) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.m),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.m);
		} else {
			var treeLen = builder.k * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.n) : builder.n;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.k);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.m) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.m);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{n: nodeList, k: (len / $elm$core$Array$branchFactor) | 0, m: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cx: fragment, cC: host, cN: path, cP: port_, cU: protocol, cV: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Main$BoardPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$BoardPageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Board$DemoBoardLoaded = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Device$Device = F3(
	function (isTouchDevice, timezone, mmddDateFormat) {
		return {dH: isTouchDevice, dN: mmddDateFormat, d5: timezone};
	});
var $author$project$Main$ErrorPage = {$: 7};
var $author$project$Session$Guest = {$: 0};
var $author$project$Session$LoggedIn = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$NotFoundPage = {$: 6};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Errors$captureErrorToJs = _Platform_outgoingPort('captureErrorToJs', $elm$json$Json$Encode$string);
var $author$project$Errors$captureErrorString = F2(
	function (title, err) {
		return $author$project$Errors$captureErrorToJs('(' + (title + (') ' + err)));
	});
var $author$project$Errors$captureDecodeError = F2(
	function (title, err) {
		return A2(
			$author$project$Errors$captureErrorString,
			title,
			$elm$json$Json$Decode$errorToString(err));
	});
var $author$project$Main$Flags = F4(
	function (now, browserTimezone, demoBoard, mmddDateFormat) {
		return {ce: browserTimezone, cm: demoBoard, dN: mmddDateFormat, ai: now};
	});
var $author$project$Pages$Board$BoardData = F9(
	function (today, startDate, endDate, org, user, spaceGroups, users, rawAssignments, history) {
		return {w: endDate, S: history, bh: org, bI: rawAssignments, Z: spaceGroups, t: startDate, c7: today, ea: user, z: users};
	});
var $author$project$Pages$Board$Assignment = F4(
	function (day, spaceId, userId, label) {
		return {g: day, bd: label, p: spaceId, j: userId};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Pages$Board$assignmentDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'label',
	$elm$json$Json$Decode$string,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'userId',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'spaceId',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'day',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Pages$Board$Assignment)))));
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {cj: col, $7: contextStack, cR: problem, c$: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.c$, s.cj, x, s.c));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cj: 1, c: s.c, h: s.h, b: s.b + 1, c$: s.c$ + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cj: s.cj + 1, c: s.c, h: s.h, b: newOffset, c$: s.c$, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.cR;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.c$, s.cj, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{cj: newCol, c: s.c, h: s.h, b: newOffset, c$: newRow, a: s.a});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {cj: col, cR: problem, c$: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.c$, p.cj, p.cR);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{cj: 1, c: _List_Nil, h: 1, b: 0, c$: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($justinmimbs$date$Date$deadEndToString),
			$elm$core$String$join('; '))));
var $author$project$Pages$Board$dateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (val) {
		var _v0 = $justinmimbs$date$Date$fromIsoString(val);
		if (_v0.$ === 1) {
			var err = _v0.a;
			return $elm$json$Json$Decode$fail(err);
		} else {
			var date = _v0.a;
			return $elm$json$Json$Decode$succeed(date);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Pages$Board$HistoryItem = F6(
	function (day, spaceId, userId, changedBy, timestamp, wasUnassigned) {
		return {ch: changedBy, g: day, p: spaceId, c6: timestamp, j: userId, bL: wasUnassigned};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$Pages$Board$timestampDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (val) {
		return $elm$json$Json$Decode$succeed(
			$elm$time$Time$millisToPosix(val * 1000));
	},
	$elm$json$Json$Decode$int);
var $author$project$Pages$Board$historyItemDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'wasUnassigned',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'timestamp',
		$author$project$Pages$Board$timestampDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'changedBy',
			$elm$json$Json$Decode$string,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'userId',
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'spaceId',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'day',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Pages$Board$HistoryItem)))))));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Pages$Board$Org = F3(
	function (id, name, timezone) {
		return {dC: id, U: name, d5: timezone};
	});
var $author$project$Pages$Board$orgDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'timezone',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Pages$Board$Org))));
var $author$project$SpaceGroup$SpaceGroup = F4(
	function (id, label, position, spaces) {
		return {dC: id, bd: label, cQ: position, bn: spaces};
	});
var $author$project$SpaceGroup$Space = F4(
	function (id, label, position, spaceGroupId) {
		return {dC: id, bd: label, cQ: position, d$: spaceGroupId};
	});
var $author$project$SpaceGroup$spaceDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'spaceGroupId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'position',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'label',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$SpaceGroup$Space)))));
var $author$project$SpaceGroup$spaceGroupDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'spaces',
	$elm$json$Json$Decode$list($author$project$SpaceGroup$spaceDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'position',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'label',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$SpaceGroup$SpaceGroup)))));
var $author$project$User$User = F7(
	function (id, name, email, userType, orgId, canAssign, orgName) {
		return {a0: canAssign, R: email, dC: id, U: name, dU: orgId, cL: orgName, c9: userType};
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$User$userPermsDecoder = function (perm) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (l) {
			var found = A2($elm$core$List$member, perm, l);
			return $elm$json$Json$Decode$succeed(found);
		},
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
};
var $author$project$User$Admin = 0;
var $author$project$User$ReadOnly = 1;
var $author$project$User$userTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'admin':
				return $elm$json$Json$Decode$succeed(0);
			case 'read_only':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$succeed(1);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$User$userDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'orgName',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'permissions',
		$author$project$User$userPermsDecoder('can_assign'),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'orgId',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'userType',
				$author$project$User$userTypeDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'email',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$User$User))))))));
var $author$project$Pages$Board$usersDecoder = $elm$json$Json$Decode$list($author$project$User$userDecoder);
var $author$project$Pages$Board$boardDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'history',
	$elm$json$Json$Decode$list($author$project$Pages$Board$historyItemDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'assignments',
		$elm$json$Json$Decode$list($author$project$Pages$Board$assignmentDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'users',
			$author$project$Pages$Board$usersDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'spaceGroups',
				$elm$json$Json$Decode$list($author$project$SpaceGroup$spaceGroupDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'user',
					$author$project$User$userDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'org',
						$author$project$Pages$Board$orgDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'endDate',
							$author$project$Pages$Board$dateDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'startDate',
								$author$project$Pages$Board$dateDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'today',
									$author$project$Pages$Board$dateDecoder,
									$elm$json$Json$Decode$succeed($author$project$Pages$Board$BoardData))))))))));
var $author$project$Main$dateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (val) {
		var _v0 = $justinmimbs$date$Date$fromIsoString(val);
		if (_v0.$ === 1) {
			var err = _v0.a;
			return $elm$json$Json$Decode$fail(err);
		} else {
			var date = _v0.a;
			return $elm$json$Json$Decode$succeed(date);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Main$decodeFlags = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'mmddDateFormat',
	$elm$json$Json$Decode$bool,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'board',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Pages$Board$boardDataDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'timezone',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'now',
				$author$project$Main$dateDecoder,
				$elm$json$Json$Decode$succeed($author$project$Main$Flags)))));
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.b8, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		g: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		cJ: month,
		dh: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).g;
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cJ;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dh;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $author$project$Status$Default = 0;
var $author$project$AddUsersModal$NewUser = F6(
	function (name, nameError, email, emailError, isAdmin, canAssign) {
		return {a0: canAssign, R: email, bA: emailError, bb: isAdmin, U: name, bF: nameError};
	});
var $author$project$AddUsersModal$blankNewUser = A6($author$project$AddUsersModal$NewUser, '', $elm$core$Maybe$Nothing, '', $elm$core$Maybe$Nothing, false, false);
var $author$project$AddUsersModal$init = {
	aL: '',
	f: _List_fromArray(
		[$author$project$AddUsersModal$blankNewUser]),
	aA: 0,
	aW: false
};
var $author$project$ContactSupportModal$init = {aR: '', aG: 0};
var $author$project$Pages$Board$All = 3;
var $justinmimbs$date$Date$Days = 3;
var $author$project$Pages$Board$EndDatePickerChanged = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Board$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Board$JumpToDayPickerChanged = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Board$StartDatePickerChanged = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Board$WeekDays = 1;
var $author$project$Pages$Board$Weeks = 0;
var $justinmimbs$date$Date$Months = 1;
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {g: d, cJ: m, dh: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		b1: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		dh: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.dh, 0, date.b1);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.dh - 1)) + ($justinmimbs$date$Date$monthToNumber(date.cJ) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.g,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$Pages$Board$defaultDaysSize = 30;
var $author$project$Pages$Board$GotBoardData = function (a) {
	return {$: 7, a: a};
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.d0));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {cX: reqs, c4: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.d7;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.cX));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.c4)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					dj: r.dj,
					dl: r.dl,
					dv: A2(_Http_mapExpect, func, r.dv),
					dz: r.dz,
					dK: r.dK,
					d4: r.d4,
					d7: r.d7,
					d9: r.d9
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dj: false, dl: r.dl, dv: r.dv, dz: r.dz, dK: r.dK, d4: r.d4, d7: r.d7, d9: r.d9}));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{dl: r.dl, dv: r.dv, dz: _List_Nil, dK: 'POST', d4: $elm$core$Maybe$Nothing, d7: $elm$core$Maybe$Nothing, d9: r.d9});
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.g;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.cJ;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.b1;
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		dc: 1 + (((rd - week1Day1) / 7) | 0),
		dd: wy,
		ec: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.dc;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.dd;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.bE(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.b$(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bE(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.bS(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.aI(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.aI(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.aI(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.ca(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.aI(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.aI(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{cj: col, c: s0.c, h: s0.h, b: offset, c$: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.c$, s.cj, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.b, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	bS: $justinmimbs$date$Date$withOrdinalSuffix,
	b$: $justinmimbs$date$Date$monthToName,
	bE: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	ca: $justinmimbs$date$Date$weekdayToName,
	aI: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Pages$Board$getInit = function (model) {
	var body = _List_fromArray(
		[
			_Utils_Tuple2(
			'startDate',
			$elm$json$Json$Encode$string(
				$justinmimbs$date$Date$toIsoString(model.t))),
			_Utils_Tuple2(
			'endDate',
			$elm$json$Json$Encode$string(
				$justinmimbs$date$Date$toIsoString(model.w)))
		]);
	return $elm$http$Http$post(
		{
			dl: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(body)),
			dv: A2($elm$http$Http$expectJson, $author$project$Pages$Board$GotBoardData, $author$project$Pages$Board$boardDataDecoder),
			d9: '/board'
		});
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $CurrySoftware$elm_datepicker$DatePicker$CurrentDate = function (a) {
	return {$: 0, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$DatePicker = $elm$core$Basics$identity;
var $CurrySoftware$elm_datepicker$DatePicker$Date$initDate = A3($justinmimbs$date$Date$fromCalendarDate, 1992, 4, 31);
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $CurrySoftware$elm_datepicker$DatePicker$init = _Utils_Tuple2(
	{
		E: $elm$core$Maybe$Just($CurrySoftware$elm_datepicker$DatePicker$Date$initDate),
		ac: false,
		J: $elm$core$Maybe$Nothing,
		V: false,
		c7: $CurrySoftware$elm_datepicker$DatePicker$Date$initDate
	},
	A2($elm$core$Task$perform, $CurrySoftware$elm_datepicker$DatePicker$CurrentDate, $justinmimbs$date$Date$today));
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$User$visitorSearchUserId = 'visitor';
var $author$project$User$visitorPlaceholderUser = A7($author$project$User$User, $author$project$User$visitorSearchUserId, 'Visitor', '', 1, '', false, '');
var $author$project$Pages$Board$init = F2(
	function (demoMode, now) {
		var _v0 = $CurrySoftware$elm_datepicker$DatePicker$init;
		var startDatePicker = _v0.a;
		var startDatePickerFx = _v0.b;
		var _v1 = $CurrySoftware$elm_datepicker$DatePicker$init;
		var jumpToDayPicker = _v1.a;
		var jumpToDayPickerFx = _v1.b;
		var _v2 = $CurrySoftware$elm_datepicker$DatePicker$init;
		var endDatePicker = _v2.a;
		var endDatePickerFx = _v2.b;
		var model = {
			v: $elm$core$Maybe$Nothing,
			d: $elm$core$Dict$empty,
			aL: '',
			an: $elm$core$Dict$empty,
			bz: _List_Nil,
			u: 3,
			bT: 0,
			A: demoMode,
			cn: $elm$core$Dict$empty,
			co: $elm$core$Dict$empty,
			cp: _List_Nil,
			cq: 0,
			cr: 0,
			w: A3($justinmimbs$date$Date$add, 3, $author$project$Pages$Board$defaultDaysSize, now),
			a9: endDatePicker,
			av: $elm$core$Maybe$Just(now),
			bc: jumpToDayPicker,
			cH: 1,
			ae: $elm$core$Maybe$Nothing,
			af: _List_Nil,
			ag: $elm$core$Maybe$Just(now),
			ah: $elm$core$Maybe$Just(now),
			f: $elm$core$Dict$empty,
			aA: 0,
			ai: now,
			bh: A3($author$project$Pages$Board$Org, '', '', ''),
			aU: $elm$core$Maybe$Just(0),
			I: 0,
			aV: $elm$core$Maybe$Just(1),
			bk: $elm$core$Maybe$Just(1),
			W: $elm$core$Dict$empty,
			al: $elm$core$Maybe$Nothing,
			aF: $elm$core$Maybe$Nothing,
			Y: 0,
			O: _List_Nil,
			Z: _List_Nil,
			H: $elm$core$Dict$empty,
			bn: $elm$core$Dict$empty,
			t: now,
			bp: startDatePicker,
			ea: $author$project$User$visitorPlaceholderUser,
			bM: $elm$time$Time$utc
		};
		var getBoardDataCmd = function () {
			if (demoMode) {
				return $elm$core$Platform$Cmd$none;
			} else {
				return $author$project$Pages$Board$getInit(model);
			}
		}();
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						getBoardDataCmd,
						A2($elm$core$Task$perform, $author$project$Pages$Board$GotTimeZone, $elm$time$Time$here),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Board$StartDatePickerChanged, startDatePickerFx),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Board$EndDatePickerChanged, endDatePickerFx),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Board$JumpToDayPickerChanged, jumpToDayPickerFx)
					])));
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Main$loadUsers = F2(
	function (userList, model) {
		var users = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (u) {
					return _Utils_Tuple2(u.dC, u);
				},
				userList));
		return _Utils_update(
			model,
			{z: users});
	});
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {au: frag, aB: params, am: unvisited, bv: value, aH: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.am;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.bv);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.bv);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.cN),
					$elm$url$Url$Parser$prepareQuery(url.cV),
					url.cx,
					$elm$core$Basics$identity)));
	});
var $author$project$Routes$Board = {$: 0};
var $author$project$Routes$Editor = {$: 3};
var $author$project$Routes$Login = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Routes$ManageUsers = {$: 4};
var $author$project$Routes$Profile = {$: 5};
var $author$project$Routes$SignUp = {$: 2};
var $author$project$Routes$editorPath = 'edit';
var $author$project$Routes$loginPath = 'login';
var $author$project$Routes$manageUsersPath = 'users';
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aH;
		var unvisited = _v0.am;
		var params = _v0.aB;
		var frag = _v0.au;
		var value = _v0.bv;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aH;
			var unvisited = _v1.am;
			var params = _v1.aB;
			var frag = _v1.au;
			var value = _v1.bv;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $author$project$Routes$profilePath = 'profile';
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aH;
		var unvisited = _v1.am;
		var params = _v1.aB;
		var frag = _v1.au;
		var value = _v1.bv;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aH;
		var unvisited = _v0.am;
		var params = _v0.aB;
		var frag = _v0.au;
		var value = _v0.bv;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $author$project$Routes$signUpPath = 'sign-up';
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routes$routes = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Routes$Board, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$Login,
			A2(
				$elm$url$Url$Parser$questionMark,
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s($author$project$Routes$loginPath),
					$elm$url$Url$Parser$Query$string('magicToken')),
				$elm$url$Url$Parser$Query$string('resetToken'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$SignUp,
			$elm$url$Url$Parser$s($author$project$Routes$signUpPath)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$Editor,
			$elm$url$Url$Parser$s($author$project$Routes$editorPath)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$ManageUsers,
			$elm$url$Url$Parser$s($author$project$Routes$manageUsersPath)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$Profile,
			$elm$url$Url$Parser$s($author$project$Routes$profilePath))
		]));
var $author$project$Routes$match = function (url) {
	return A2($elm$url$Url$Parser$parse, $author$project$Routes$routes, url);
};
var $author$project$Main$GotMe = function (a) {
	return {$: 22, a: a};
};
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{dl: $elm$http$Http$emptyBody, dv: r.dv, dz: _List_Nil, dK: 'GET', d4: $elm$core$Maybe$Nothing, d7: $elm$core$Maybe$Nothing, d9: r.d9});
};
var $author$project$Main$remoteGetMe = $elm$http$Http$get(
	{
		dv: A2($elm$http$Http$expectJson, $author$project$Main$GotMe, $author$project$User$userDecoder),
		d9: '/me'
	});
var $author$project$Main$EditorPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$EditorPageMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$LoginPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$LoginPageMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$ProfilePage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$ProfilePageMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$SignUpPage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$SignUpPageMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$UsersPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$UsersPageMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Editor$Loading = 1;
var $author$project$Pages$Editor$GotSpaceGroups = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SpaceGroup$spaceGroupsDecoder = $elm$json$Json$Decode$list($author$project$SpaceGroup$spaceGroupDecoder);
var $author$project$Pages$Editor$getSpaceGroups = $elm$http$Http$get(
	{
		dv: A2(
			$elm$http$Http$expectJson,
			$author$project$Pages$Editor$GotSpaceGroups(false),
			$author$project$SpaceGroup$spaceGroupsDecoder),
		d9: '/board/space-groups'
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging = {$: 0};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$init = $norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging;
var $author$project$Pages$Editor$init = function () {
	var model = {a3: _List_Nil, P: _List_Nil, a4: $norpan$elm_html5_drag_drop$Html5$DragDrop$init, Q: '', af: _List_Nil, bG: _List_Nil, Z: _List_Nil, aG: 1};
	return _Utils_Tuple2(model, $author$project$Pages$Editor$getSpaceGroups);
}();
var $author$project$Pages$Login$LoginSection = 0;
var $author$project$Pages$Login$MagicSection = 3;
var $author$project$Status$Pending = 1;
var $author$project$Pages$Login$ResetSection = 2;
var $author$project$Pages$Login$GotMagicLoginResponse = function (a) {
	return {$: 14, a: a};
};
var $jzxhuang$http_extras$Http$Detailed$BadBody = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $jzxhuang$http_extras$Http$Detailed$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $jzxhuang$http_extras$Http$Detailed$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $jzxhuang$http_extras$Http$Detailed$NetworkError = {$: 2};
var $jzxhuang$http_extras$Http$Detailed$Timeout = {$: 1};
var $jzxhuang$http_extras$Http$Detailed$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$jzxhuang$http_extras$Http$Detailed$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($jzxhuang$http_extras$Http$Detailed$Timeout);
			case 2:
				return $elm$core$Result$Err($jzxhuang$http_extras$Http$Detailed$NetworkError);
			case 3:
				var metadata = response.a;
				var body = response.b;
				return $elm$core$Result$Err(
					A2($jzxhuang$http_extras$Http$Detailed$BadStatus, metadata, body));
			default:
				var metadata = response.a;
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					A2($jzxhuang$http_extras$Http$Detailed$BadBody, metadata, body),
					toResult(
						_Utils_Tuple2(metadata, body)));
		}
	});
var $jzxhuang$http_extras$Http$Detailed$responseToString = function (responseString) {
	return A2(
		$jzxhuang$http_extras$Http$Detailed$resolve,
		function (_v0) {
			var metadata = _v0.a;
			var body = _v0.b;
			return $elm$core$Result$Ok(
				_Utils_Tuple2(metadata, body));
		},
		responseString);
};
var $jzxhuang$http_extras$Http$Detailed$expectString = function (toMsg) {
	return A2($elm$http$Http$expectStringResponse, toMsg, $jzxhuang$http_extras$Http$Detailed$responseToString);
};
var $author$project$Pages$Login$postMagicToken = function (token) {
	var body = $elm$http$Http$jsonBody(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'token',
					$elm$json$Json$Encode$string(token))
				])));
	return $elm$http$Http$post(
		{
			dl: body,
			dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$Login$GotMagicLoginResponse),
			d9: '/magic-login'
		});
};
var $author$project$Pages$Login$init = F3(
	function (key, maybeMagicToken, maybeResetToken) {
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(maybeMagicToken, maybeResetToken);
			if (!_v1.b.$) {
				return _Utils_Tuple2(2, $elm$core$Platform$Cmd$none);
			} else {
				if (!_v1.a.$) {
					var magicToken = _v1.a.a;
					return _Utils_Tuple2(
						3,
						$author$project$Pages$Login$postMagicToken(magicToken));
				} else {
					return _Utils_Tuple2(0, $elm$core$Platform$Cmd$none);
				}
			}
		}();
		var section = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			{R: '', K: key, aw: '', bf: $elm$core$Maybe$Nothing, ax: 0, bD: 1, cI: maybeMagicToken, ay: '', aS: '', aT: 0, aj: '', aD: '', bl: $elm$core$Maybe$Nothing, aE: 0, b5: maybeResetToken, bm: section, aG: 0},
			cmd);
	});
var $author$project$Pages$Profile$GotMe = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Profile$getMe = $elm$http$Http$get(
	{
		dv: A2($elm$http$Http$expectJson, $author$project$Pages$Profile$GotMe, $author$project$User$userDecoder),
		d9: '/me'
	});
var $author$project$Pages$Profile$init = function () {
	var model = {a0: false, ao: '', a2: $elm$core$Maybe$Nothing, R: '', bA: $elm$core$Maybe$Nothing, bb: false, U: '', bF: $elm$core$Maybe$Nothing, ay: '', az: $elm$core$Maybe$Nothing, N: 0, X: 0, ea: $elm$core$Maybe$Nothing};
	return _Utils_Tuple2(model, $author$project$Pages$Profile$getMe);
}();
var $author$project$Pages$SignUp$BasicsStage = 0;
var $author$project$Pages$SignUp$SpaceGroup = F2(
	function (label, numberOfSpaces) {
		return {bd: label, bg: numberOfSpaces};
	});
var $author$project$Pages$SignUp$init = function () {
	var model = {
		aK: $elm$core$Maybe$Nothing,
		a$: 0,
		bw: false,
		R: '',
		a8: false,
		ad: $elm$core$Maybe$Nothing,
		U: '',
		cL: '',
		aj: '',
		Z: _List_fromArray(
			[
				A2($author$project$Pages$SignUp$SpaceGroup, 'Parking 1', $elm$core$Maybe$Nothing)
			]),
		_: 0,
		bo: false,
		aY: 0
	};
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
}();
var $author$project$Pages$Users$GotRemoteUsers = function (a) {
	return {$: 0, a: a};
};
var $author$project$User$usersDecoder = $elm$json$Json$Decode$list($author$project$User$userDecoder);
var $author$project$User$remoteGetUsers = function (msg) {
	return $elm$http$Http$get(
		{
			dv: A2($elm$http$Http$expectJson, msg, $author$project$User$usersDecoder),
			d9: '/board/users'
		});
};
var $author$project$Pages$Users$init = _Utils_Tuple2(
	{ap: 0, ar: false, as: '', a5: $elm$core$Maybe$Nothing, a6: '', at: '', a7: $elm$core$Maybe$Nothing, aP: 1, af: _List_Nil, X: 0, aG: 1},
	$author$project$User$remoteGetUsers($author$project$Pages$Users$GotRemoteUsers));
var $author$project$Main$setNewPage = F2(
	function (maybeRoute, model) {
		if (!maybeRoute.$) {
			switch (maybeRoute.a.$) {
				case 0:
					var _v1 = maybeRoute.a;
					var _v2 = A2($author$project$Pages$Board$init, model.aO, model.ai);
					var boardModel = _v2.a;
					var boardCmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								i: $author$project$Main$BoardPage(boardModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$BoardPageMsg, boardCmd));
				case 1:
					var _v3 = maybeRoute.a;
					var maybeMagicToken = _v3.a;
					var maybeResetToken = _v3.b;
					var _v4 = A3($author$project$Pages$Login$init, model.K, maybeMagicToken, maybeResetToken);
					var loginModel = _v4.a;
					var loginCmd = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								i: $author$project$Main$LoginPage(loginModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$LoginPageMsg, loginCmd));
				case 2:
					var _v5 = maybeRoute.a;
					var _v6 = $author$project$Pages$SignUp$init;
					var signUpModel = _v6.a;
					var signUpCmd = _v6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								i: $author$project$Main$SignUpPage(signUpModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$SignUpPageMsg, signUpCmd));
				case 3:
					var _v7 = maybeRoute.a;
					var _v8 = $author$project$Pages$Editor$init;
					var editorModel = _v8.a;
					var editorCmd = _v8.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								i: $author$project$Main$EditorPage(editorModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$EditorPageMsg, editorCmd));
				case 4:
					var _v9 = maybeRoute.a;
					var _v10 = $author$project$Pages$Users$init;
					var usersModel = _v10.a;
					var usersCmd = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								i: $author$project$Main$UsersPage(usersModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$UsersPageMsg, usersCmd));
				default:
					var _v11 = maybeRoute.a;
					var _v12 = $author$project$Pages$Profile$init;
					var profileModel = _v12.a;
					var profileCmd = _v12.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								i: $author$project$Main$ProfilePage(profileModel)
							}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$ProfilePageMsg, profileCmd));
			}
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{i: $author$project$Main$NotFoundPage}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$SubMsg$AddPeopleClicked = {$: 1};
var $author$project$Pages$Board$Assign = function (a) {
	return {$: 0, a: a};
};
var $author$project$SubMsg$AuthError = {$: 2};
var $author$project$Pages$Board$ConfirmDelete = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Board$Conflict = 1;
var $author$project$Pages$Board$Conflicts = {$: 3};
var $author$project$Status$Error = 3;
var $author$project$Pages$Board$Future = 0;
var $author$project$SubMsg$GotUsers = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Board$History = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Board$ModalClosed = {$: 23};
var $author$project$Pages$Board$Months = 1;
var $author$project$Pages$Board$NoConflict = 0;
var $author$project$Pages$Board$None = 2;
var $author$project$Pages$Board$Past = 1;
var $author$project$Pages$Board$Repeat = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Board$Share = {$: 5};
var $author$project$Pages$Board$SpaceHistory = F2(
	function (status, history) {
		return {S: history, aG: status};
	});
var $author$project$Status$Success = 2;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Pages$Board$dayToString = function (date) {
	return $justinmimbs$date$Date$toIsoString(date);
};
var $justinmimbs$date$Date$toMonths = function (rd) {
	var date = $justinmimbs$date$Date$toCalendarDate(rd);
	var wholeMonths = (12 * (date.dh - 1)) + ($justinmimbs$date$Date$monthToNumber(date.cJ) - 1);
	return wholeMonths + (date.g / 100);
};
var $elm$core$Basics$truncate = _Basics_truncate;
var $justinmimbs$date$Date$diff = F3(
	function (unit, _v0, _v1) {
		var rd1 = _v0;
		var rd2 = _v1;
		switch (unit) {
			case 0:
				return ((($justinmimbs$date$Date$toMonths(rd2) - $justinmimbs$date$Date$toMonths(rd1)) | 0) / 12) | 0;
			case 1:
				return ($justinmimbs$date$Date$toMonths(rd2) - $justinmimbs$date$Date$toMonths(rd1)) | 0;
			case 2:
				return ((rd2 - rd1) / 7) | 0;
			default:
				return rd2 - rd1;
		}
	});
var $author$project$Pages$Board$getDays = F2(
	function (startDate, endDate) {
		var daysDelta = A3($justinmimbs$date$Date$diff, 3, startDate, endDate);
		return A2(
			$elm$core$List$map,
			function (num) {
				var dayDate = A3($justinmimbs$date$Date$add, 3, num, startDate);
				var isWeekend = function () {
					var _v0 = $justinmimbs$date$Date$weekday(dayDate);
					switch (_v0) {
						case 5:
							return true;
						case 6:
							return true;
						default:
							return false;
					}
				}();
				var day = $author$project$Pages$Board$dayToString(dayDate);
				return _Utils_Tuple2(day, isWeekend);
			},
			A2($elm$core$List$range, 0, daysDelta));
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Pages$Board$addDaysToModel = F3(
	function (startDate, endDate, model) {
		var dayData = A2($author$project$Pages$Board$getDays, startDate, endDate);
		var collapsedDays = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$filter,
				function (dd) {
					return dd.b;
				},
				dayData));
		return _Utils_update(
			model,
			{
				an: collapsedDays,
				bz: A2($elm$core$List$map, $elm$core$Tuple$first, dayData)
			});
	});
var $author$project$Pages$Board$groupDemoHistoryItems = F2(
	function (historyDict, item) {
		var id = _Utils_Tuple2(item.g, item.p);
		var newDict = function () {
			var _v0 = A2($elm$core$Dict$get, id, historyDict);
			if (!_v0.$) {
				var spaceHistory = _v0.a;
				return A3(
					$elm$core$Dict$insert,
					id,
					_Utils_update(
						spaceHistory,
						{
							S: $elm$core$List$concat(
								_List_fromArray(
									[
										spaceHistory.S,
										_List_fromArray(
										[item])
									]))
						}),
					historyDict);
			} else {
				return A3(
					$elm$core$Dict$insert,
					id,
					A2(
						$author$project$Pages$Board$SpaceHistory,
						2,
						_List_fromArray(
							[item])),
					historyDict);
			}
		}();
		return _Utils_Tuple2(newDict, item);
	});
var $elm_community$list_extra$List$Extra$mapAccuml = F3(
	function (f, acc0, list) {
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (x, _v1) {
					var acc1 = _v1.a;
					var ys = _v1.b;
					var _v2 = A2(f, acc1, x);
					var acc2 = _v2.a;
					var y = _v2.b;
					return _Utils_Tuple2(
						acc2,
						A2($elm$core$List$cons, y, ys));
				}),
			_Utils_Tuple2(acc0, _List_Nil),
			list);
		var accFinal = _v0.a;
		var generatedList = _v0.b;
		return _Utils_Tuple2(
			accFinal,
			$elm$core$List$reverse(generatedList));
	});
var $author$project$Pages$Board$addDemoHistory = F2(
	function (model, historyItems) {
		var _v0 = A3($elm_community$list_extra$List$Extra$mapAccuml, $author$project$Pages$Board$groupDemoHistoryItems, model.H, historyItems);
		var spaceHistoryDict = _v0.a;
		return _Utils_update(
			model,
			{H: spaceHistoryDict});
	});
var $author$project$Pages$Board$assignmentsDecoder = $elm$json$Json$Decode$list($author$project$Pages$Board$assignmentDecoder);
var $author$project$Pages$Board$BoardRange = F3(
	function (startDate, endDate, assignments) {
		return {d: assignments, w: endDate, t: startDate};
	});
var $author$project$Pages$Board$boardRangeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'assignments',
	$elm$json$Json$Decode$list($author$project$Pages$Board$assignmentDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'endDate',
		$author$project$Pages$Board$dateDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'startDate',
			$author$project$Pages$Board$dateDecoder,
			$elm$json$Json$Decode$succeed($author$project$Pages$Board$BoardRange))));
var $author$project$Pages$Board$assignmentEncoder = function (assignment) {
	var body = _List_fromArray(
		[
			_Utils_Tuple2(
			'spaceId',
			$elm$json$Json$Encode$string(assignment.p)),
			_Utils_Tuple2(
			'day',
			$elm$json$Json$Encode$string(assignment.g)),
			_Utils_Tuple2(
			'label',
			$elm$json$Json$Encode$string(assignment.bd))
		]);
	var _v0 = assignment.j;
	if (_v0.$ === 1) {
		return $elm$json$Json$Encode$object(body);
	} else {
		var userId = _v0.a;
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2(
					'userId',
					$elm$json$Json$Encode$string(userId)),
				body));
	}
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Pages$Board$boardRangeEncoder = function (boardRange) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'startDate',
				$elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(boardRange.t))),
				_Utils_Tuple2(
				'endDate',
				$elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(boardRange.w))),
				_Utils_Tuple2(
				'assignments',
				A2($elm$json$Json$Encode$list, $author$project$Pages$Board$assignmentEncoder, boardRange.d))
			]));
};
var $author$project$Pages$Board$BoardUpdateMsgAssignmentChange = function (payload) {
	return {dX: payload};
};
var $author$project$Pages$Board$boardUpdateMsgAssignmentChangeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'payload',
	$author$project$Pages$Board$assignmentDecoder,
	$elm$json$Json$Decode$succeed($author$project$Pages$Board$BoardUpdateMsgAssignmentChange));
var $author$project$DbMessage$BoardUpdateMsg = function (meta) {
	return {dJ: meta};
};
var $author$project$DbMessage$BoardUpdateMsgMeta = F4(
	function (msgType, change, id, orgId) {
		return {dn: change, dC: id, dO: msgType, dU: orgId};
	});
var $author$project$DbMessage$Delete = 1;
var $author$project$DbMessage$Insert = 2;
var $author$project$DbMessage$Update = 0;
var $author$project$DbMessage$boardUpdateMsgChangeTypeFromString = function (str) {
	switch (str) {
		case 'UPDATE':
			return $elm$json$Json$Decode$succeed(0);
		case 'INSERT':
			return $elm$json$Json$Decode$succeed(2);
		case 'DELETE':
			return $elm$json$Json$Decode$succeed(1);
		default:
			return $elm$json$Json$Decode$fail('Invalid board update change type: ' + str);
	}
};
var $author$project$DbMessage$boardUpdateMsgChangeTypeDecoder = A2($elm$json$Json$Decode$andThen, $author$project$DbMessage$boardUpdateMsgChangeTypeFromString, $elm$json$Json$Decode$string);
var $author$project$DbMessage$AssigmentChangeMsg = 0;
var $author$project$DbMessage$SpaceGroupChangeMsg = 2;
var $author$project$DbMessage$UnknownChangeMsg = 3;
var $author$project$DbMessage$UserChangeMsg = 1;
var $author$project$DbMessage$boardUpdateMsgTypeFromString = function (str) {
	switch (str) {
		case 'assignment change':
			return $elm$json$Json$Decode$succeed(0);
		case 'user change':
			return $elm$json$Json$Decode$succeed(1);
		case 'space group change':
			return $elm$json$Json$Decode$succeed(2);
		default:
			return $elm$json$Json$Decode$succeed(3);
	}
};
var $author$project$DbMessage$boardUpdateMsgTypeDecoder = A2($elm$json$Json$Decode$andThen, $author$project$DbMessage$boardUpdateMsgTypeFromString, $elm$json$Json$Decode$string);
var $author$project$DbMessage$boardUpdateMsgMetaDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'orgId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'change',
			$author$project$DbMessage$boardUpdateMsgChangeTypeDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'type',
				$author$project$DbMessage$boardUpdateMsgTypeDecoder,
				$elm$json$Json$Decode$succeed($author$project$DbMessage$BoardUpdateMsgMeta)))));
var $author$project$DbMessage$boardUpdateMsgDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$author$project$DbMessage$boardUpdateMsgMetaDecoder,
	$elm$json$Json$Decode$succeed($author$project$DbMessage$BoardUpdateMsg));
var $author$project$Pages$Board$BoardUpdateMsgSpaceGroupsChange = function (payload) {
	return {dX: payload};
};
var $author$project$Pages$Board$boardUpdateMsgSpaceGroupsChangeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'payload',
	$author$project$SpaceGroup$spaceGroupsDecoder,
	$elm$json$Json$Decode$succeed($author$project$Pages$Board$BoardUpdateMsgSpaceGroupsChange));
var $author$project$HttpUtil$httpErrorToString = function (err) {
	switch (err.$) {
		case 1:
			return 'Timeout exceeded';
		case 2:
			return 'Network error';
		case 3:
			var status = err.a;
			return 'Bad status : ' + $elm$core$String$fromInt(status);
		case 0:
			var url = err.a;
			return 'Malformed url: ' + url;
		default:
			var string = err.a;
			return 'Unexpected response from api: ' + string;
	}
};
var $author$project$Errors$captureHttpError = F2(
	function (title, err) {
		return A2(
			$author$project$Errors$captureErrorString,
			title,
			$author$project$HttpUtil$httpErrorToString(err));
	});
var $author$project$Pages$Board$closeMenuIfOpen = function (model) {
	var _v0 = model.ae;
	if ((!_v0.$) && _v0.a) {
		return _Utils_update(
			model,
			{
				ae: $elm$core$Maybe$Just(false)
			});
	} else {
		return model;
	}
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Components$Modal$toggleScrollLockForModal = _Platform_outgoingPort('toggleScrollLockForModal', $elm$json$Json$Encode$bool);
var $author$project$Pages$Board$closeModal = function (model) {
	return _Utils_Tuple3(
		_Utils_update(
			model,
			{af: _List_Nil}),
		$author$project$Components$Modal$toggleScrollLockForModal(false),
		$elm$core$Maybe$Nothing);
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $author$project$Pages$Board$mergeAssignments = F2(
	function (model, assignments) {
		var newAssignments = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (a) {
					return _Utils_Tuple2(
						_Utils_Tuple2(a.g, a.p),
						a);
				},
				assignments));
		return A2($elm$core$Dict$union, newAssignments, model.d);
	});
var $author$project$Pages$Board$createBoard = F2(
	function (data, model) {
		var spaces = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (s) {
					return _Utils_Tuple2(s.dC, s);
				},
				$elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (sg) {
							return sg.bn;
						},
						data.Z))));
		var modelWithSpaces = _Utils_update(
			model,
			{bn: spaces});
		var assignments = A2($author$project$Pages$Board$mergeAssignments, modelWithSpaces, data.bI);
		return _Utils_update(
			modelWithSpaces,
			{d: assignments, bh: data.bh, Z: data.Z, ea: data.ea});
	});
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $CurrySoftware$elm_datepicker$DatePicker$formatCell = function (day) {
	return $elm$html$Html$text(day);
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay = function (day) {
	switch (day) {
		case 0:
			return 'Mo';
		case 1:
			return 'Tu';
		case 2:
			return 'We';
		case 3:
			return 'Th';
		case 4:
			return 'Fr';
		case 5:
			return 'Sa';
		default:
			return 'Su';
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$Off = {$: 0};
var $CurrySoftware$elm_datepicker$DatePicker$off = $CurrySoftware$elm_datepicker$DatePicker$Date$Off;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $CurrySoftware$elm_datepicker$DatePicker$defaultSettings = {
	bN: $CurrySoftware$elm_datepicker$DatePicker$formatCell,
	a1: $CurrySoftware$elm_datepicker$DatePicker$off,
	aM: 'elm-datepicker--',
	bP: _List_Nil,
	dp: $justinmimbs$date$Date$format('yyyy-MM-dd'),
	bR: $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay,
	bB: 6,
	bU: _List_fromArray(
		[
			$elm$html$Html$Attributes$required(false)
		]),
	bV: _List_Nil,
	bW: $elm$core$Maybe$Nothing,
	bX: $elm$core$Maybe$Nothing,
	bC: $elm$core$Basics$always(false),
	b_: $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth,
	b2: $justinmimbs$date$Date$fromIsoString,
	b3: 'Please pick a date...',
	cb: $elm$core$String$fromInt
};
var $CurrySoftware$elm_datepicker$DatePicker$Disabled = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$EmptyString = {$: 1};
var $CurrySoftware$elm_datepicker$DatePicker$FailedInput = function (a) {
	return {$: 1, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$Invalid = function (a) {
	return {$: 0, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$None = {$: 0};
var $CurrySoftware$elm_datepicker$DatePicker$Picked = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$update = F3(
	function (settings, msg, _v0) {
		var model = _v0;
		var forceOpen = model.ac;
		var focused = model.E;
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							E: $elm$core$Maybe$Just(date),
							c7: date
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 1:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							E: $elm$core$Maybe$Just(date)
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 2:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{E: $elm$core$Maybe$Nothing, J: $elm$core$Maybe$Nothing, V: false}),
					$CurrySoftware$elm_datepicker$DatePicker$Picked(date));
			case 3:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							J: $elm$core$Maybe$Just(text)
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 4:
				if (forceOpen) {
					return _Utils_Tuple2(model, $CurrySoftware$elm_datepicker$DatePicker$None);
				} else {
					var dateEvent = function () {
						var _v5 = model.J;
						if (_v5.$ === 1) {
							return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
						} else {
							if (_v5.a === '') {
								return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
							} else {
								var rawInput = _v5.a;
								var _v6 = settings.b2(rawInput);
								if (!_v6.$) {
									var date = _v6.a;
									return settings.bC(date) ? $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Disabled(date)) : $CurrySoftware$elm_datepicker$DatePicker$Picked(date);
								} else {
									var e = _v6.a;
									return $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Invalid(e));
								}
							}
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								E: function () {
									if (dateEvent.$ === 2) {
										var date = dateEvent.a;
										return $elm$core$Maybe$Just(date);
									} else {
										return model.E;
									}
								}(),
								J: function () {
									if (dateEvent.$ === 2) {
										return $elm$core$Maybe$Nothing;
									} else {
										return model.J;
									}
								}()
							}),
						dateEvent);
				}
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: false, V: true}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{V: forceOpen}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: true}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: false}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
		}
	});
var $author$project$Pages$Board$datePickerChanged = F3(
	function (msg, picker, existingDate) {
		var _v0 = A3($CurrySoftware$elm_datepicker$DatePicker$update, $CurrySoftware$elm_datepicker$DatePicker$defaultSettings, msg, picker);
		var newDatePicker = _v0.a;
		var dateEvent = _v0.b;
		var date = function () {
			if (dateEvent.$ === 2) {
				var newDate = dateEvent.a;
				return $elm$core$Maybe$Just(newDate);
			} else {
				return existingDate;
			}
		}();
		return _Utils_Tuple2(date, newDatePicker);
	});
var $author$project$Pages$Board$flipBool = function (b) {
	if (b) {
		return false;
	} else {
		return true;
	}
};
var $author$project$HttpUtil$getCodeAndDecode = F2(
	function (result, decoder) {
		if (result.$ === 1) {
			var error = result.a;
			switch (error.$) {
				case 3:
					var metadata = error.a;
					var body = error.b;
					var _v2 = A2($elm$json$Json$Decode$decodeString, decoder, body);
					if (!_v2.$) {
						var data = _v2.a;
						return _Utils_Tuple2(
							metadata.d0,
							$elm$core$Maybe$Just(data));
					} else {
						return _Utils_Tuple2(metadata.d0, $elm$core$Maybe$Nothing);
					}
				case 4:
					var metadata = error.a;
					return _Utils_Tuple2(metadata.d0, $elm$core$Maybe$Nothing);
				default:
					return _Utils_Tuple2(500, $elm$core$Maybe$Nothing);
			}
		} else {
			var _v3 = result.a;
			var metaData = _v3.a;
			var data = _v3.b;
			return _Utils_Tuple2(
				metaData.d0,
				$elm$core$Maybe$Just(data));
		}
	});
var $author$project$Pages$Board$getPastDays = _Platform_outgoingPort('getPastDays', $elm$core$Basics$identity);
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$DbMessage$isUpdateOrInsert = function (change) {
	switch (change) {
		case 0:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $author$project$Pages$Board$RepeatedAssignment = F5(
	function (original, assignment, conflict, status, conflictAction) {
		return {e: assignment, bx: conflict, ab: conflictAction, dV: original, aG: status};
	});
var $author$project$Pages$Board$makeRepeatDayList = F3(
	function (startDate, endDate, repeatType) {
		var weekDays = _List_fromArray(
			[0, 1, 2, 3, 4]);
		var validDaysOfWeek = function () {
			switch (repeatType) {
				case 0:
					return _List_fromArray(
						[
							$justinmimbs$date$Date$weekday(startDate)
						]);
				case 1:
					return weekDays;
				default:
					return A2(
						$elm$core$List$cons,
						5,
						A2($elm$core$List$cons, 6, weekDays));
			}
		}();
		var daysDelta = A3($justinmimbs$date$Date$diff, 3, startDate, endDate);
		var allDays = A2(
			$elm$core$List$map,
			function (num) {
				var dayDate = A3($justinmimbs$date$Date$add, 3, num, startDate);
				var day = $author$project$Pages$Board$dayToString(dayDate);
				return _Utils_Tuple2(
					day,
					$justinmimbs$date$Date$weekday(dayDate));
			},
			A2($elm$core$List$range, 1, daysDelta));
		var days = A2(
			$elm$core$List$map,
			$elm$core$Tuple$first,
			A2(
				$elm$core$List$filter,
				function (_v0) {
					var dayOfWeek = _v0.b;
					return A2($elm$core$List$member, dayOfWeek, validDaysOfWeek);
				},
				allDays));
		return days;
	});
var $justinmimbs$date$Date$Weeks = 2;
var $author$project$Pages$Board$repeatRequestEndDate = F3(
	function (startDay, repeatPeriod, value) {
		var startDate = function () {
			var _v0 = $justinmimbs$date$Date$fromIsoString(startDay);
			if (!_v0.$) {
				var date = _v0.a;
				var datePeriod = function () {
					if (!repeatPeriod) {
						return 2;
					} else {
						return 1;
					}
				}();
				return $elm$core$Maybe$Just(
					A3($justinmimbs$date$Date$add, datePeriod, value, date));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		return startDate;
	});
var $author$project$Pages$Board$makeRepeatRequestAssignments = F4(
	function (originalAssignment, repeatType, repeatPeriod, value) {
		var maybeStartDate = function () {
			var _v1 = $justinmimbs$date$Date$fromIsoString(originalAssignment.g);
			if (_v1.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var d = _v1.a;
				return $elm$core$Maybe$Just(d);
			}
		}();
		var maybeEndDate = A3($author$project$Pages$Board$repeatRequestEndDate, originalAssignment.g, repeatPeriod, value);
		var assignments = function () {
			var _v0 = _Utils_Tuple2(maybeStartDate, maybeEndDate);
			if ((!_v0.a.$) && (!_v0.b.$)) {
				var startDate = _v0.a.a;
				var endDate = _v0.b.a;
				var days = A3($author$project$Pages$Board$makeRepeatDayList, startDate, endDate, repeatType);
				return A2(
					$elm$core$List$map,
					function (day) {
						var newAssignment = A4($author$project$Pages$Board$Assignment, day, originalAssignment.p, originalAssignment.j, originalAssignment.bd);
						return A5($author$project$Pages$Board$RepeatedAssignment, originalAssignment, newAssignment, $elm$core$Maybe$Nothing, 0, $elm$core$Maybe$Nothing);
					},
					days);
			} else {
				return _List_Nil;
			}
		}();
		return assignments;
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var tail = $elm$core$List$tail(
				A2($elm$core$List$drop, index, l));
			var head = A2($elm$core$List$take, index, l);
			if (tail.$ === 1) {
				return l;
			} else {
				var t = tail.a;
				return A2($elm$core$List$append, head, t);
			}
		}
	});
var $author$project$Components$Modal$popModalStack = function (modalStack) {
	return A2(
		$elm_community$list_extra$List$Extra$removeAt,
		$elm$core$List$length(modalStack) - 1,
		modalStack);
};
var $author$project$Pages$Board$modalBack = function (model) {
	var newModalStack = $author$project$Components$Modal$popModalStack(model.af);
	return _Utils_update(
		model,
		{af: newModalStack});
};
var $andrewMacmurray$elm_delay$Delay$Second = 1;
var $author$project$Pages$Board$SharedResponse = function (a) {
	return {$: 39, a: a};
};
var $andrewMacmurray$elm_delay$Delay$Duration = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Process$sleep = _Process_sleep;
var $andrewMacmurray$elm_delay$Delay$after_ = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(time));
	});
var $andrewMacmurray$elm_delay$Delay$Minute = 2;
var $andrewMacmurray$elm_delay$Delay$toMillis = function (_v0) {
	var t = _v0.a;
	var u = _v0.b;
	switch (u) {
		case 0:
			return t;
		case 1:
			return 1000 * t;
		case 2:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 1));
		default:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 2));
	}
};
var $andrewMacmurray$elm_delay$Delay$after = F3(
	function (time, unit, msg) {
		return A2(
			$andrewMacmurray$elm_delay$Delay$after_,
			$andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, time, unit)),
			msg);
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$Pages$Board$postShare = function (model) {
	var _v0 = model.A;
	if (_v0) {
		return A3(
			$andrewMacmurray$elm_delay$Delay$after,
			1,
			1,
			$author$project$Pages$Board$SharedResponse(
				$elm$core$Result$Ok(0)));
	} else {
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'userIds',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, model.O))
				]));
		return $elm$http$Http$post(
			{
				dl: $elm$http$Http$jsonBody(body),
				dv: $elm$http$Http$expectWhatever($author$project$Pages$Board$SharedResponse),
				d9: '/board/share'
			});
	}
};
var $author$project$Components$Modal$pushModalStack = F2(
	function (newModal, modalStack) {
		return A2(
			$elm$core$List$append,
			modalStack,
			_List_fromArray(
				[newModal]));
	});
var $author$project$Pages$Board$RemoteAssignmentDeleted = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Board$remoteDeleteAssignment = F2(
	function (model, assignment) {
		var _v0 = model.A;
		if (_v0) {
			return $elm$core$Platform$Cmd$none;
		} else {
			return $elm$http$Http$request(
				{
					dl: $elm$http$Http$jsonBody(
						$author$project$Pages$Board$assignmentEncoder(assignment)),
					dv: $elm$http$Http$expectWhatever($author$project$Pages$Board$RemoteAssignmentDeleted),
					dz: _List_Nil,
					dK: 'DELETE',
					d4: $elm$core$Maybe$Nothing,
					d7: $elm$core$Maybe$Nothing,
					d9: '/board/assignment'
				});
		}
	});
var $author$project$Pages$Board$GotDays = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Board$remoteGetDays = F3(
	function (demoMode, startDate, endDate) {
		if (demoMode) {
			var response = A3($author$project$Pages$Board$BoardRange, startDate, endDate, _List_Nil);
			return A3(
				$andrewMacmurray$elm_delay$Delay$after,
				1,
				1,
				$author$project$Pages$Board$GotDays(
					$elm$core$Result$Ok(response)));
		} else {
			var body = _List_fromArray(
				[
					_Utils_Tuple2(
					'startDate',
					$elm$json$Json$Encode$string(
						$justinmimbs$date$Date$toIsoString(startDate))),
					_Utils_Tuple2(
					'endDate',
					$elm$json$Json$Encode$string(
						$justinmimbs$date$Date$toIsoString(endDate)))
				]);
			return $elm$http$Http$post(
				{
					dl: $elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(body)),
					dv: A2($elm$http$Http$expectJson, $author$project$Pages$Board$GotDays, $author$project$Pages$Board$boardRangeDecoder),
					d9: '/board'
				});
		}
	});
var $author$project$Pages$Board$GotHistory = F2(
	function (a, b) {
		return {$: 35, a: a, b: b};
	});
var $author$project$Pages$Board$historyDecoder = $elm$json$Json$Decode$list($author$project$Pages$Board$historyItemDecoder);
var $author$project$Pages$Board$send = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $author$project$Pages$Board$remoteGetHistory = F2(
	function (model, _v0) {
		var day = _v0.a;
		var spaceId = _v0.b;
		var _v1 = model.A;
		if (_v1) {
			var _v2 = A2(
				$elm$core$Dict$get,
				_Utils_Tuple2(day, spaceId),
				model.H);
			if (!_v2.$) {
				var spaceHistory = _v2.a;
				return $author$project$Pages$Board$send(
					A2(
						$author$project$Pages$Board$GotHistory,
						_Utils_Tuple2(day, spaceId),
						$elm$core$Result$Ok(spaceHistory.S)));
			} else {
				return $author$project$Pages$Board$send(
					A2(
						$author$project$Pages$Board$GotHistory,
						_Utils_Tuple2(day, spaceId),
						$elm$core$Result$Ok(_List_Nil)));
			}
		} else {
			var params = $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'spaceId',
						$elm$json$Json$Encode$string(spaceId)),
						_Utils_Tuple2(
						'day',
						$elm$json$Json$Encode$string(day))
					]));
			return $elm$http$Http$post(
				{
					dl: $elm$http$Http$jsonBody(params),
					dv: A2(
						$elm$http$Http$expectJson,
						$author$project$Pages$Board$GotHistory(
							_Utils_Tuple2(day, spaceId)),
						$author$project$Pages$Board$historyDecoder),
					d9: '/board/history'
				});
		}
	});
var $author$project$Pages$Board$RemoteAssignmentSaved = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Board$remotePostAssignment = F2(
	function (model, assignment) {
		var _v0 = model.A;
		if (_v0) {
			return $elm$core$Platform$Cmd$none;
		} else {
			return $elm$http$Http$post(
				{
					dl: $elm$http$Http$jsonBody(
						$author$project$Pages$Board$assignmentEncoder(assignment)),
					dv: $elm$http$Http$expectWhatever($author$project$Pages$Board$RemoteAssignmentSaved),
					d9: '/board/assignment'
				});
		}
	});
var $author$project$Pages$Board$AssignmentRepeated = F2(
	function (a, b) {
		return {$: 31, a: a, b: b};
	});
var $author$project$Pages$Board$AssignmentRepeatedDemo = function (a) {
	return {$: 30, a: a};
};
var $jzxhuang$http_extras$Http$Detailed$responseToJson = F2(
	function (decoder, responseString) {
		return A2(
			$jzxhuang$http_extras$Http$Detailed$resolve,
			function (_v0) {
				var metadata = _v0.a;
				var body = _v0.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$json$Json$Decode$errorToString,
					A2(
						$elm$json$Json$Decode$decodeString,
						A2(
							$elm$json$Json$Decode$map,
							function (res) {
								return _Utils_Tuple2(metadata, res);
							},
							decoder),
						body));
			},
			responseString);
	});
var $jzxhuang$http_extras$Http$Detailed$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$jzxhuang$http_extras$Http$Detailed$responseToJson(decoder));
	});
var $author$project$Pages$Board$getAssignmentConflicts = F2(
	function (existingAssignments, newRepeated) {
		var repeatedAssignments = A2(
			$elm$core$List$map,
			function (ra) {
				var repeatedUser = ra.e.j;
				var maybeConflict = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(ra.e.g, ra.e.p),
					existingAssignments);
				var status = function () {
					if (!maybeConflict.$) {
						var conflict = maybeConflict.a;
						var _v1 = _Utils_eq(conflict.j, repeatedUser);
						if (_v1) {
							return 0;
						} else {
							return 1;
						}
					} else {
						return 0;
					}
				}();
				return _Utils_update(
					ra,
					{bx: maybeConflict, aG: status});
			},
			newRepeated);
		return repeatedAssignments;
	});
var $author$project$Pages$Board$repeatedAssignmentEncoder = function (repeatedAssignment) {
	var userId = function () {
		var _v2 = repeatedAssignment.e.j;
		if (_v2.$ === 1) {
			return '';
		} else {
			var uid = _v2.a;
			return uid;
		}
	}();
	var overwrite = function () {
		var _v0 = repeatedAssignment.ab;
		if ((!_v0.$) && (!_v0.a)) {
			var _v1 = _v0.a;
			return true;
		} else {
			return false;
		}
	}();
	var body = _List_fromArray(
		[
			_Utils_Tuple2(
			'spaceId',
			$elm$json$Json$Encode$string(repeatedAssignment.e.p)),
			_Utils_Tuple2(
			'day',
			$elm$json$Json$Encode$string(repeatedAssignment.e.g)),
			_Utils_Tuple2(
			'label',
			$elm$json$Json$Encode$string(repeatedAssignment.e.bd)),
			_Utils_Tuple2(
			'userId',
			$elm$json$Json$Encode$string(userId)),
			_Utils_Tuple2(
			'overwrite',
			$elm$json$Json$Encode$bool(overwrite))
		]);
	return $elm$json$Json$Encode$object(body);
};
var $author$project$Pages$Board$repeatedAssignmentsEncoder = function (repeatedAssignments) {
	return A2($elm$json$Json$Encode$list, $author$project$Pages$Board$repeatedAssignmentEncoder, repeatedAssignments);
};
var $author$project$Pages$Board$remoteRepeatAssignment = F2(
	function (model, repeatedAssignments) {
		var assignmentsWithConflicts = A2($author$project$Pages$Board$getAssignmentConflicts, model.d, repeatedAssignments);
		var _v0 = model.A;
		if (_v0) {
			return $author$project$Pages$Board$send(
				$author$project$Pages$Board$AssignmentRepeatedDemo(
					$elm$core$Result$Ok(assignmentsWithConflicts)));
		} else {
			return $elm$http$Http$post(
				{
					dl: $elm$http$Http$jsonBody(
						$author$project$Pages$Board$repeatedAssignmentsEncoder(repeatedAssignments)),
					dv: A2(
						$jzxhuang$http_extras$Http$Detailed$expectJson,
						$author$project$Pages$Board$AssignmentRepeated(repeatedAssignments),
						$author$project$Pages$Board$assignmentsDecoder),
					d9: '/board/assignments'
				});
		}
	});
var $author$project$Components$Modal$replaceModal = F2(
	function (newModal, modalStack) {
		return A2(
			$author$project$Components$Modal$pushModalStack,
			newModal,
			$author$project$Components$Modal$popModalStack(modalStack));
	});
var $author$project$Pages$Board$shiftDay = F2(
	function (numOfDays, currentDay) {
		var _v0 = $justinmimbs$date$Date$fromIsoString(currentDay);
		if (!_v0.$) {
			var d = _v0.a;
			return A2(
				$justinmimbs$date$Date$format,
				'YYYY-MM-dd',
				A3($justinmimbs$date$Date$add, 3, numOfDays, d));
		} else {
			return currentDay;
		}
	});
var $author$project$Pages$Board$shiftDates = F2(
	function (board, model) {
		var daysBehind = A3($justinmimbs$date$Date$diff, 3, board.c7, model.ai);
		var history = A2(
			$elm$core$List$map,
			function (historyItem) {
				var day = A2($author$project$Pages$Board$shiftDay, daysBehind, historyItem.g);
				return _Utils_update(
					historyItem,
					{g: day});
			},
			board.S);
		var assignments = A2(
			$elm$core$List$map,
			function (assignment) {
				var day = A2($author$project$Pages$Board$shiftDay, daysBehind, assignment.g);
				return _Utils_update(
					assignment,
					{g: day});
			},
			board.bI);
		return _Utils_update(
			board,
			{S: history, bI: assignments});
	});
var $author$project$Pages$Board$updateModelWithDateRange = F2(
	function (assignmentsRange, model) {
		var modelWithDays = A3($author$project$Pages$Board$addDaysToModel, assignmentsRange.t, assignmentsRange.w, model);
		var assignments = A2($author$project$Pages$Board$mergeAssignments, model, assignmentsRange.d);
		return _Utils_update(
			modelWithDays,
			{d: assignments, w: assignmentsRange.w, t: assignmentsRange.t});
	});
var $author$project$Pages$Board$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var tz = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bM: tz}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 0:
				var boardData = msg.a;
				var now = msg.b;
				var modelWithNow = _Utils_update(
					model,
					{ai: now});
				var demoBoardData = A2($author$project$Pages$Board$shiftDates, boardData, modelWithNow);
				var modelWithBoard = A2($author$project$Pages$Board$createBoard, demoBoardData, modelWithNow);
				var modelWithDays = A3(
					$author$project$Pages$Board$addDaysToModel,
					now,
					A3($justinmimbs$date$Date$add, 3, $author$project$Pages$Board$defaultDaysSize, now),
					modelWithBoard);
				var modelWithHistory = A2($author$project$Pages$Board$addDemoHistory, modelWithDays, demoBoardData.S);
				return _Utils_Tuple3(
					_Utils_update(
						modelWithHistory,
						{u: 2}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 7:
				var boardDataResponse = msg.a;
				if (boardDataResponse.$ === 1) {
					var err = boardDataResponse.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureHttpError, 'board response', err),
						$elm$core$Maybe$Just($author$project$SubMsg$AuthError));
				} else {
					var boardData = boardDataResponse.a;
					var newModel = A2($author$project$Pages$Board$createBoard, boardData, model);
					var modelWithDays = A3($author$project$Pages$Board$addDaysToModel, boardData.t, boardData.w, newModel);
					return _Utils_Tuple3(
						_Utils_update(
							modelWithDays,
							{u: 2}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$SubMsg$GotUsers(boardData.z)));
				}
			case 8:
				var assignmentsRangeResponse = msg.a;
				if (assignmentsRangeResponse.$ === 1) {
					var err = assignmentsRangeResponse.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureHttpError, 'assignment range response', err),
						$elm$core$Maybe$Nothing);
				} else {
					var data = assignmentsRangeResponse.a;
					var _v3 = model.u;
					if (_v3 === 1) {
						return _Utils_Tuple3(
							model,
							$author$project$Pages$Board$getPastDays(
								$author$project$Pages$Board$boardRangeEncoder(data)),
							$elm$core$Maybe$Nothing);
					} else {
						var modelWithDays = A2($author$project$Pages$Board$updateModelWithDateRange, data, model);
						return _Utils_Tuple3(
							_Utils_update(
								modelWithDays,
								{u: 2}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 9:
				var json = msg.a;
				var _v4 = A2($elm$json$Json$Decode$decodeString, $author$project$Pages$Board$boardRangeDecoder, json);
				if (!_v4.$) {
					var data = _v4.a;
					var modelWithDays = A2($author$project$Pages$Board$updateModelWithDateRange, data, model);
					return _Utils_Tuple3(
						_Utils_update(
							modelWithDays,
							{u: 2}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var err = _v4.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureDecodeError, 'GotPastDays board data decode', err),
						$elm$core$Maybe$Nothing);
				}
			case 5:
				var startEndDelta = A3($justinmimbs$date$Date$diff, 3, model.t, model.w);
				var endDate = A3($justinmimbs$date$Date$add, 3, startEndDelta + $author$project$Pages$Board$defaultDaysSize, model.t);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{u: 0}),
					A3($author$project$Pages$Board$remoteGetDays, model.A, model.t, endDate),
					$elm$core$Maybe$Nothing);
			case 6:
				var startDate = A3($justinmimbs$date$Date$add, 3, -$author$project$Pages$Board$defaultDaysSize, model.t);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{u: 1}),
					A3($author$project$Pages$Board$remoteGetDays, model.A, startDate, model.w),
					$elm$core$Maybe$Nothing);
			case 10:
				var subMsg = msg.a;
				var _v5 = A3($author$project$Pages$Board$datePickerChanged, subMsg, model.bp, model.ah);
				var date = _v5.a;
				var newDatePicker = _v5.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ah: date, bp: newDatePicker}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 11:
				var subMsg = msg.a;
				var _v6 = A3($author$project$Pages$Board$datePickerChanged, subMsg, model.a9, model.ag);
				var date = _v6.a;
				var newDatePicker = _v6.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{a9: newDatePicker, ag: date}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 12:
				var subMsg = msg.a;
				var _v7 = A3($author$project$Pages$Board$datePickerChanged, subMsg, model.bc, model.av);
				var date = _v7.a;
				var newDatePicker = _v7.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{av: date, bc: newDatePicker}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 13:
				var _v8 = _Utils_Tuple2(model.ah, model.ag);
				if ((!_v8.a.$) && (!_v8.b.$)) {
					var startDate = _v8.a.a;
					var endDate = _v8.b.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{v: $elm$core$Maybe$Nothing, u: 3}),
						A3($author$project$Pages$Board$remoteGetDays, model.A, startDate, endDate),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 14:
				var _v9 = model.av;
				if (!_v9.$) {
					var jumpToDate = _v9.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{v: $elm$core$Maybe$Nothing, u: 3}),
						A3($author$project$Pages$Board$remoteGetDays, model.A, jumpToDate, jumpToDate),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 15:
				var res = msg.a;
				if (!res.$) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				} else {
					var err = res.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureHttpError, 'save assignment response', err),
						$elm$core$Maybe$Nothing);
				}
			case 16:
				var res = msg.a;
				if (!res.$) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				} else {
					var err = res.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureHttpError, 'delete assignment response', err),
						$elm$core$Maybe$Nothing);
				}
			case 3:
				var day = msg.a;
				var space = msg.b;
				var user = msg.c;
				var existingAssignment = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(day, space.dC),
					model.d);
				if (existingAssignment.$ === 1) {
					var newAssignment = function () {
						var _v13 = _Utils_eq(user, $author$project$User$visitorPlaceholderUser);
						if (_v13) {
							return A4($author$project$Pages$Board$Assignment, day, space.dC, $elm$core$Maybe$Nothing, 'Visitor');
						} else {
							return A4(
								$author$project$Pages$Board$Assignment,
								day,
								space.dC,
								$elm$core$Maybe$Just(user.dC),
								'');
						}
					}();
					var assignmentId = _Utils_Tuple2(day, space.dC);
					var assignments = A3($elm$core$Dict$insert, assignmentId, newAssignment, model.d);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{d: assignments}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Pages$Board$remotePostAssignment, model, newAssignment),
									$author$project$Pages$Board$send($author$project$Pages$Board$ModalClosed)
								])),
						$elm$core$Maybe$Nothing);
				} else {
					var assignment = existingAssignment.a;
					var updatedAssignment = function () {
						var _v14 = _Utils_eq(user, $author$project$User$visitorPlaceholderUser);
						if (_v14) {
							return _Utils_update(
								assignment,
								{bd: 'Visitor', j: $elm$core$Maybe$Nothing});
						} else {
							return _Utils_update(
								assignment,
								{
									j: $elm$core$Maybe$Just(user.dC)
								});
						}
					}();
					var assignments = A3(
						$elm$core$Dict$insert,
						_Utils_Tuple2(assignment.g, assignment.p),
						updatedAssignment,
						model.d);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{d: assignments}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Pages$Board$remotePostAssignment, model, updatedAssignment),
									$author$project$Pages$Board$send($author$project$Pages$Board$ModalClosed)
								])),
						$elm$core$Maybe$Nothing);
				}
			case 17:
				var toggled = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ae: toggled}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 18:
				var panel = msg.a;
				if ((!panel.$) && (panel.a === 1)) {
					var _v16 = panel.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								v: panel,
								ag: $elm$core$Maybe$Just(model.w),
								ah: $elm$core$Maybe$Just(model.t)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{v: panel}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			case 19:
				var userId = msg.a;
				var finalUserId = function () {
					var _v17 = userId === 'blank';
					if (_v17) {
						return $elm$core$Maybe$Nothing;
					} else {
						var _v18 = _Utils_eq(userId, $author$project$User$visitorSearchUserId);
						if (_v18) {
							return $elm$core$Maybe$Just('');
						} else {
							return $elm$core$Maybe$Just(userId);
						}
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{al: finalUserId}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 20:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{v: $elm$core$Maybe$Nothing, al: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 4:
				var day = msg.a;
				var space = msg.b;
				var assignmentId = _Utils_Tuple2(day, space.dC);
				var assignments = A2($elm$core$Dict$remove, assignmentId, model.d);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{d: assignments}),
					A2(
						$author$project$Pages$Board$remoteDeleteAssignment,
						model,
						A4($author$project$Pages$Board$Assignment, day, space.dC, $elm$core$Maybe$Nothing, '')),
					$elm$core$Maybe$Nothing);
			case 21:
				var replace = msg.a;
				var modal = msg.b;
				var alreadyLocked = $elm$core$List$length(model.af) > 0;
				var lockCommand = function () {
					if (alreadyLocked) {
						return $elm$core$Platform$Cmd$none;
					} else {
						return $author$project$Components$Modal$toggleScrollLockForModal(true);
					}
				}();
				var _v19 = function () {
					switch (modal.$) {
						case 0:
							var _v21 = modal.a;
							var day = _v21.a;
							var space = _v21.b;
							return _Utils_Tuple3(
								$author$project$Pages$Board$Assign(
									_Utils_Tuple2(day, space)),
								_Utils_update(
									model,
									{aF: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$none);
						case 1:
							var _v22 = modal.a;
							var day = _v22.a;
							var space = _v22.b;
							return _Utils_Tuple3(
								$author$project$Pages$Board$History(
									_Utils_Tuple2(day, space)),
								model,
								$elm$core$Platform$Cmd$none);
						case 2:
							var _v23 = modal.a;
							var day = _v23.a;
							var space = _v23.b;
							return _Utils_Tuple3(
								$author$project$Pages$Board$Repeat(
									_Utils_Tuple2(day, space)),
								_Utils_update(
									model,
									{I: 0}),
								$elm$core$Platform$Cmd$none);
						case 3:
							return _Utils_Tuple3($author$project$Pages$Board$Conflicts, model, $elm$core$Platform$Cmd$none);
						case 4:
							var deleteInfo = modal.a;
							return _Utils_Tuple3(
								$author$project$Pages$Board$ConfirmDelete(deleteInfo),
								model,
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple3(
								$author$project$Pages$Board$Share,
								_Utils_update(
									model,
									{Y: 0, O: _List_Nil}),
								$elm$core$Platform$Cmd$none);
					}
				}();
				var newModal = _v19.a;
				var updatedModel = _v19.b;
				var cmd = _v19.c;
				var newModalStack = function () {
					if (replace) {
						return A2($author$project$Components$Modal$replaceModal, newModal, model.af);
					} else {
						return A2($author$project$Components$Modal$pushModalStack, newModal, model.af);
					}
				}();
				var newModel = $author$project$Pages$Board$closeMenuIfOpen(updatedModel);
				return _Utils_Tuple3(
					_Utils_update(
						newModel,
						{af: newModalStack}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[lockCommand, cmd])),
					$elm$core$Maybe$Nothing);
			case 24:
				return _Utils_Tuple3(
					$author$project$Pages$Board$modalBack(model),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 23:
				return $author$project$Pages$Board$closeModal(model);
			case 22:
				var user = msg.a;
				var toAssign = function () {
					var _v26 = model.aF;
					if (!_v26.$) {
						var u = _v26.a;
						var _v27 = _Utils_eq(u, user);
						if (_v27) {
							return $elm$core$Maybe$Nothing;
						} else {
							return $elm$core$Maybe$Just(user);
						}
					} else {
						return $elm$core$Maybe$Just(user);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aF: toAssign}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 25:
				var day = msg.a;
				var collapsed = function () {
					var _v28 = A2($elm$core$Dict$get, day, model.an);
					if (!_v28.$) {
						var c = _v28.a;
						return $author$project$Pages$Board$flipBool(c);
					} else {
						return true;
					}
				}();
				var collapsedDays = A3($elm$core$Dict$insert, day, collapsed, model.an);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{an: collapsedDays}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 26:
				var repeatType = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aV: $elm$core$Maybe$Just(repeatType)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 27:
				var repeatPeriodStr = msg.a;
				var repeatPeriod = function () {
					switch (repeatPeriodStr) {
						case 'weeks':
							return $elm$core$Maybe$Just(0);
						case 'months':
							return $elm$core$Maybe$Just(1);
						default:
							return $elm$core$Maybe$Nothing;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aU: repeatPeriod}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 28:
				var repeatValString = msg.a;
				var val = function () {
					var _v30 = $elm$core$String$toInt(repeatValString);
					if (!_v30.$) {
						var n = _v30.a;
						return $elm$core$Maybe$Just(n);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bk: val}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 29:
				var assignment = msg.a;
				var repeatType = msg.b;
				var repeatPeriod = msg.c;
				var repeatValue = msg.d;
				var newAssignments = A4($author$project$Pages$Board$makeRepeatRequestAssignments, assignment, repeatType, repeatPeriod, repeatValue);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{I: 1}),
					A2($author$project$Pages$Board$remoteRepeatAssignment, model, newAssignments),
					$elm$core$Maybe$Nothing);
			case 30:
				var result = msg.a;
				if (result.$ === 1) {
					var err = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureHttpError, 'assignment repeated demo', err),
						$elm$core$Maybe$Nothing);
				} else {
					var repeatedAssignments = result.a;
					var _v32 = A2(
						$elm$core$List$any,
						function (a) {
							return a.aG === 1;
						},
						repeatedAssignments);
					if (_v32) {
						var repeatedAssignmentDict = $elm$core$Dict$fromList(
							A2(
								$elm$core$List$map,
								function (ra) {
									var id = _Utils_Tuple2(ra.e.g, ra.e.p);
									return _Utils_Tuple2(id, ra);
								},
								repeatedAssignments));
						var newModalStack = A2($author$project$Components$Modal$pushModalStack, $author$project$Pages$Board$Conflicts, model.af);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{af: newModalStack, I: 0, W: repeatedAssignmentDict}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var newAssignmentsDict = $elm$core$Dict$fromList(
							A2(
								$elm$core$List$map,
								function (ra) {
									var id = _Utils_Tuple2(ra.e.g, ra.e.p);
									return _Utils_Tuple2(id, ra.e);
								},
								repeatedAssignments));
						var assignments = A2($elm$core$Dict$union, newAssignmentsDict, model.d);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{d: assignments, I: 2}),
							$author$project$Pages$Board$send($author$project$Pages$Board$ModalClosed),
							$elm$core$Maybe$Nothing);
					}
				}
			case 31:
				var repeatedAssignments = msg.a;
				var result = msg.b;
				var _v33 = A2($author$project$HttpUtil$getCodeAndDecode, result, $author$project$Pages$Board$assignmentsDecoder);
				var code = _v33.a;
				var maybeBody = _v33.b;
				switch (code) {
					case 409:
						if (!maybeBody.$) {
							var conflicts = maybeBody.a;
							var newModalStack = A2($author$project$Components$Modal$pushModalStack, $author$project$Pages$Board$Conflicts, model.af);
							var finalRepeated = $elm$core$Dict$fromList(
								A2(
									$elm$core$List$map,
									function (ra) {
										var spaceId = ra.e.p;
										var day = ra.e.g;
										var id = _Utils_Tuple2(day, spaceId);
										var conflict = $elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (c) {
													return _Utils_eq(c.g, day) && _Utils_eq(c.p, spaceId);
												},
												conflicts));
										var status = function () {
											if (!conflict.$) {
												return 1;
											} else {
												return 0;
											}
										}();
										var repeatedAssignment = _Utils_update(
											ra,
											{bx: conflict, aG: status});
										return _Utils_Tuple2(id, repeatedAssignment);
									},
									repeatedAssignments));
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{af: newModalStack, I: 0, W: finalRepeated}),
								$author$project$Components$Modal$toggleScrollLockForModal(true),
								$elm$core$Maybe$Nothing);
						} else {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						}
					case 200:
						var newAssignments = A2(
							$elm$core$List$map,
							function ($) {
								return $.e;
							},
							repeatedAssignments);
						var assignments = A2($author$project$Pages$Board$mergeAssignments, model, newAssignments);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{d: assignments, I: 0}),
							$author$project$Pages$Board$send($author$project$Pages$Board$ModalClosed),
							$elm$core$Maybe$Nothing);
					default:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 32:
				var assignmentId = msg.a;
				var conflictAction = msg.b;
				var repeatedAssignments = function () {
					var _v37 = A2($elm$core$Dict$get, assignmentId, model.W);
					if (_v37.$ === 1) {
						return model.W;
					} else {
						var ra = _v37.a;
						var updated = _Utils_update(
							ra,
							{
								ab: $elm$core$Maybe$Just(conflictAction)
							});
						return A3($elm$core$Dict$insert, assignmentId, updated, model.W);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{W: repeatedAssignments}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 33:
				var repeatedAssignments = msg.a;
				var newAssignments = A2(
					$elm$core$List$filter,
					function (ra) {
						var _v39 = ra.ab;
						if (!_v39.$) {
							if (_v39.a === 1) {
								var _v40 = _v39.a;
								return false;
							} else {
								var _v41 = _v39.a;
								return true;
							}
						} else {
							return true;
						}
					},
					repeatedAssignments);
				var _v38 = model.A;
				if (_v38) {
					var newAssignmentsDict = $elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (ra) {
								var id = _Utils_Tuple2(ra.e.g, ra.e.p);
								return _Utils_Tuple2(id, ra.e);
							},
							newAssignments));
					var assignments = A2($elm$core$Dict$union, newAssignmentsDict, model.d);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{d: assignments}),
						$author$project$Pages$Board$send($author$project$Pages$Board$ModalClosed),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{I: 1}),
						A2($author$project$Pages$Board$remoteRepeatAssignment, model, newAssignments),
						$elm$core$Maybe$Nothing);
				}
			case 2:
				var val = msg.a;
				var _v42 = A2($elm$json$Json$Decode$decodeString, $author$project$DbMessage$boardUpdateMsgDecoder, val);
				if (!_v42.$) {
					var m = _v42.a;
					var _v43 = m.dJ.dO;
					switch (_v43) {
						case 0:
							var _v44 = A2($elm$json$Json$Decode$decodeString, $author$project$Pages$Board$boardUpdateMsgAssignmentChangeDecoder, val);
							if (!_v44.$) {
								var am = _v44.a;
								var assignments = function () {
									var _v45 = $author$project$DbMessage$isUpdateOrInsert(m.dJ.dn);
									if (_v45) {
										var assignment = am.dX;
										var id = _Utils_Tuple2(assignment.g, assignment.p);
										return A3($elm$core$Dict$insert, id, assignment, model.d);
									} else {
										return A2(
											$elm$core$Dict$remove,
											_Utils_Tuple2(am.dX.g, am.dX.p),
											model.d);
									}
								}();
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{d: assignments}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							} else {
								var payloadDecodeErr = _v44.a;
								return _Utils_Tuple3(
									model,
									A2($author$project$Errors$captureDecodeError, 'board update assignment message', payloadDecodeErr),
									$elm$core$Maybe$Nothing);
							}
						case 2:
							var _v46 = A2($elm$json$Json$Decode$decodeString, $author$project$Pages$Board$boardUpdateMsgSpaceGroupsChangeDecoder, val);
							if (!_v46.$) {
								var spaceGroupChangeMsg = _v46.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{Z: spaceGroupChangeMsg.dX}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							} else {
								var payloadDecodeErr = _v46.a;
								return _Utils_Tuple3(
									model,
									A2($author$project$Errors$captureDecodeError, 'board update space groups message', payloadDecodeErr),
									$elm$core$Maybe$Nothing);
							}
						default:
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
					}
				} else {
					var decodeErr = _v42.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureDecodeError, 'board update message', decodeErr),
						$elm$core$Maybe$Nothing);
				}
			case 34:
				var day = msg.a;
				var space = msg.b;
				var spaceHistory = A3(
					$elm$core$Dict$insert,
					_Utils_Tuple2(day, space.dC),
					A2($author$project$Pages$Board$SpaceHistory, 1, _List_Nil),
					model.H);
				var modalStack = A2(
					$author$project$Components$Modal$pushModalStack,
					$author$project$Pages$Board$History(
						_Utils_Tuple2(day, space)),
					model.af);
				var cmd = A2(
					$author$project$Pages$Board$remoteGetHistory,
					model,
					_Utils_Tuple2(day, space.dC));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{af: modalStack, H: spaceHistory}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 35:
				var _v47 = msg.a;
				var day = _v47.a;
				var spaceId = _v47.b;
				var res = msg.b;
				if (!res.$) {
					var historyItems = res.a;
					var spaceHistory = A3(
						$elm$core$Dict$insert,
						_Utils_Tuple2(day, spaceId),
						A2($author$project$Pages$Board$SpaceHistory, 2, historyItems),
						model.H);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{H: spaceHistory}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var err = res.a;
					var spaceHistory = A3(
						$elm$core$Dict$insert,
						_Utils_Tuple2(day, spaceId),
						A2($author$project$Pages$Board$SpaceHistory, 3, _List_Nil),
						model.H);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{H: spaceHistory}),
						A2($author$project$Errors$captureHttpError, 'get history', err),
						$elm$core$Maybe$Nothing);
				}
			case 36:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just($author$project$SubMsg$AddPeopleClicked));
			case 37:
				var userId = msg.a;
				var shared = function () {
					var _v49 = A2($elm$core$List$member, userId, model.O);
					if (_v49) {
						return A2(
							$elm$core$List$filter,
							function (uid) {
								return !_Utils_eq(uid, userId);
							},
							model.O);
					} else {
						return A2($elm$core$List$cons, userId, model.O);
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Y: 0, O: shared}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 38:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Y: 1}),
					$author$project$Pages$Board$postShare(model),
					$elm$core$Maybe$Nothing);
			case 39:
				var res = msg.a;
				if (!res.$) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{Y: 2, O: _List_Nil}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var unexpectedError = res.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{Y: 3}),
						A2($author$project$Errors$captureHttpError, 'share', unexpectedError),
						$elm$core$Maybe$Nothing);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$Main$init = F3(
	function (rawFlags, url, key) {
		var defaultModel = {
			aJ: $author$project$AddUsersModal$init,
			aN: $author$project$ContactSupportModal$init,
			aO: false,
			aq: A3($author$project$Device$Device, false, '', false),
			K: key,
			ae: $elm$core$Maybe$Nothing,
			af: _List_Nil,
			ai: A2(
				$justinmimbs$date$Date$fromPosix,
				$elm$time$Time$utc,
				$elm$time$Time$millisToPosix(0)),
			i: $author$project$Main$NotFoundPage,
			o: $author$project$Session$Guest,
			bt: false,
			d9: url,
			z: $elm$core$Dict$empty
		};
		var _v0 = function () {
			var _v1 = A2($elm$json$Json$Decode$decodeValue, $author$project$Main$decodeFlags, rawFlags);
			if (_v1.$ === 1) {
				var errorMessage = _v1.a;
				return _Utils_Tuple2(
					_Utils_update(
						defaultModel,
						{i: $author$project$Main$ErrorPage}),
					A2($author$project$Errors$captureDecodeError, 'flags decoder', errorMessage));
			} else {
				var flags = _v1.a;
				var device = A3($author$project$Device$Device, false, flags.ce, flags.dN);
				var model = _Utils_update(
					defaultModel,
					{aq: device, ai: flags.ai});
				var _v2 = flags.cm;
				if (_v2.$ === 1) {
					return A2(
						$author$project$Main$setNewPage,
						$author$project$Routes$match(url),
						model);
				} else {
					var demo = _v2.a;
					var _v3 = A2($author$project$Pages$Board$init, true, flags.ai);
					var boardModel = _v3.a;
					var _v4 = function () {
						var _v5 = A2(
							$author$project$Pages$Board$update,
							A2($author$project$Pages$Board$DemoBoardLoaded, demo, flags.ai),
							boardModel);
						var newBoardModel = _v5.a;
						var boardCmd = _v5.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Main$loadUsers,
								demo.z,
								_Utils_update(
									model,
									{
										aO: true,
										i: $author$project$Main$BoardPage(newBoardModel),
										o: $author$project$Session$LoggedIn(demo.ea)
									})),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$BoardPageMsg, boardCmd));
					}();
					var demoModel = _v4.a;
					return _Utils_Tuple2(demoModel, $elm$core$Platform$Cmd$none);
				}
			}
		}();
		var readyModel = _v0.a;
		var cmd = _v0.b;
		var loadMeCmd = function () {
			var _v6 = readyModel.aO;
			if (_v6) {
				return $elm$core$Platform$Cmd$none;
			} else {
				return $author$project$Main$remoteGetMe;
			}
		}();
		return _Utils_Tuple2(
			readyModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, loadMeCmd])));
	});
var $author$project$Main$AuthFailed = {$: 21};
var $author$project$Main$GotDbUpdateMessage = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$Board$GotDbUpdateMessage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$GotIsTouchDevice = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Board$GotPastDays = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotUnexpectedError = {$: 11};
var $author$project$Main$authFailed = _Platform_incomingPort('authFailed', $elm$json$Json$Decode$bool);
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Main$boardUpdateMsg = _Platform_incomingPort('boardUpdateMsg', $elm$json$Json$Decode$string);
var $author$project$Pages$Board$gotPastDays = _Platform_incomingPort('gotPastDays', $elm$json$Json$Decode$string);
var $author$project$Main$isTouchDevice = _Platform_incomingPort('isTouchDevice', $elm$json$Json$Decode$bool);
var $author$project$Main$orgUpdateMsg = _Platform_incomingPort('orgUpdateMsg', $elm$json$Json$Decode$string);
var $author$project$Main$unexpectedError = _Platform_incomingPort('unexpectedError', $elm$json$Json$Decode$bool);
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Main$boardUpdateMsg(
				function (d) {
					return $author$project$Main$BoardPageMsg(
						$author$project$Pages$Board$GotDbUpdateMessage(d));
				}),
				$author$project$Pages$Board$gotPastDays(
				function (d) {
					return $author$project$Main$BoardPageMsg(
						$author$project$Pages$Board$GotPastDays(d));
				}),
				$author$project$Main$orgUpdateMsg(
				function (d) {
					return $author$project$Main$GotDbUpdateMessage(d);
				}),
				$author$project$Main$isTouchDevice(
				function (d) {
					return $author$project$Main$GotIsTouchDevice(d);
				}),
				$author$project$Main$unexpectedError(
				function (_v1) {
					return $author$project$Main$GotUnexpectedError;
				}),
				$author$project$Main$authFailed(
				function (_v2) {
					return $author$project$Main$AuthFailed;
				})
			]));
};
var $author$project$Main$AddPeopleClicked = {$: 18};
var $author$project$Main$AddUsersModal = 0;
var $author$project$Main$AddUsersModalMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$ContactSupportModal = 1;
var $author$project$Main$ContactSupportModalMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$ModalOpened = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$SessionUpdated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$boardPath = '';
var $author$project$DbMessage$BoardUpdateMsgUserChange = function (payload) {
	return {dX: payload};
};
var $author$project$DbMessage$boardUpdateMsgUserChangeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'payload',
	$author$project$User$userDecoder,
	$elm$json$Json$Decode$succeed($author$project$DbMessage$BoardUpdateMsgUserChange));
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Main$modalClosed = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{af: _List_Nil}),
		$author$project$Components$Modal$toggleScrollLockForModal(false));
};
var $author$project$Routes$path = function (p) {
	return '/' + p;
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Util$send = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.cU;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.cx,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.cV,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.cP,
					_Utils_ap(http, url.cC)),
				url.cN)));
};
var $author$project$AddUsersModal$GotUsersAndDone = function (a) {
	return {$: 15, a: a};
};
var $author$project$AddUsersModal$ModalClosed = {$: 13};
var $author$project$Errors$captureHttpDetailedError = F2(
	function (title, err) {
		var message = function () {
			switch (err.$) {
				case 3:
					var meta = err.a;
					return 'Bad Status: ' + $elm$core$String$fromInt(meta.d0);
				case 1:
					return 'Timeout';
				case 2:
					return 'Network Error';
				case 0:
					var url = err.a;
					return 'Bad Url: ' + url;
				default:
					var meta = err.a;
					var body = err.b;
					var str = err.c;
					return 'Bad Body: ' + ($elm$core$String$fromInt(meta.d0) + ('(' + (str + ')')));
			}
		}();
		return A2($author$project$Errors$captureErrorString, title, message);
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$User$getEmailUserName = function (email) {
	var _v0 = $elm$core$List$head(
		A2($elm$core$String$split, '@', email));
	if (!_v0.$) {
		var s = _v0.a;
		return s;
	} else {
		return '';
	}
};
var $author$project$Validation$CannotBeBlank = {$: 0};
var $author$project$Validation$Other = function (a) {
	return {$: 5, a: a};
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {dE: index, dI: match, dR: number, d2: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{dm: false, dP: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $author$project$Validation$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('.+@.+'));
var $author$project$Validation$emailIsValid = function (email) {
	return A2($elm$regex$Regex$contains, $author$project$Validation$emailRegex, email);
};
var $author$project$AddUsersModal$getNewUsersErrors = function (newUsers) {
	var updatedNewUsers = A2(
		$elm$core$List$map,
		function (nu) {
			var nameError = function () {
				var _v1 = nu.U;
				if (_v1 === '') {
					return $elm$core$Maybe$Just($author$project$Validation$CannotBeBlank);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			var emailError = function () {
				var _v0 = _Utils_Tuple2(
					nu.R,
					$author$project$Validation$emailIsValid(nu.R));
				if (_v0.a === '') {
					return $elm$core$Maybe$Just($author$project$Validation$CannotBeBlank);
				} else {
					if (!_v0.b) {
						return $elm$core$Maybe$Just(
							$author$project$Validation$Other('Email not valid'));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			}();
			return _Utils_update(
				nu,
				{bA: emailError, bF: nameError});
		},
		newUsers);
	return updatedNewUsers;
};
var $author$project$Validation$AlreadyInUse = {$: 1};
var $author$project$Validation$NotValid = {$: 2};
var $author$project$Validation$fieldErrorDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'cannot be blank':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just($author$project$Validation$CannotBeBlank));
			case 'all ready in use':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just($author$project$Validation$AlreadyInUse));
			case 'not valid':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just($author$project$Validation$NotValid));
			case '':
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			default:
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Validation$Other(str)));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$AddUsersModal$newUserDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'canAssign',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'isAdmin',
		$elm$json$Json$Decode$bool,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'emailError',
			$author$project$Validation$fieldErrorDecoder,
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'email',
				$elm$json$Json$Decode$string,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'nameError',
					$author$project$Validation$fieldErrorDecoder,
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$AddUsersModal$NewUser)))))));
var $author$project$AddUsersModal$newUsersDecoder = $elm$json$Json$Decode$list($author$project$AddUsersModal$newUserDecoder);
var $author$project$AddUsersModal$UsersAdded = function (a) {
	return {$: 11, a: a};
};
var $author$project$AddUsersModal$newUserEncoder = function (newUser) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(newUser.U)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(newUser.R)),
				_Utils_Tuple2(
				'isAdmin',
				$elm$json$Json$Encode$bool(newUser.bb)),
				_Utils_Tuple2(
				'canAssign',
				$elm$json$Json$Encode$bool(newUser.a0))
			]));
};
var $author$project$AddUsersModal$newUsersEncoder = function (newUsers) {
	return A2($elm$json$Json$Encode$list, $author$project$AddUsersModal$newUserEncoder, newUsers);
};
var $author$project$AddUsersModal$remoteSaveNewUsers = function (newUsersToSave) {
	return $elm$http$Http$post(
		{
			dl: $elm$http$Http$jsonBody(
				$author$project$AddUsersModal$newUsersEncoder(newUsersToSave)),
			dv: A2($jzxhuang$http_extras$Http$Detailed$expectJson, $author$project$AddUsersModal$UsersAdded, $author$project$User$usersDecoder),
			d9: '/board/users'
		});
};
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $elm_community$list_extra$List$Extra$setAt = F2(
	function (index, value) {
		return A2(
			$elm_community$list_extra$List$Extra$updateAt,
			index,
			$elm$core$Basics$always(value));
	});
var $author$project$AddUsersModal$userHasError = function (newUser) {
	var _v0 = _Utils_Tuple2(newUser.bA, newUser.bF);
	if (!_v0.a.$) {
		return true;
	} else {
		if (!_v0.b.$) {
			return true;
		} else {
			return false;
		}
	}
};
var $author$project$AddUsersModal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							f: _Utils_ap(
								model.f,
								_List_fromArray(
									[$author$project$AddUsersModal$blankNewUser]))
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 5:
				var index = msg.a;
				var email = msg.b;
				var _v1 = A2($elm_community$list_extra$List$Extra$getAt, index, model.f);
				if (!_v1.$) {
					var user = _v1.a;
					var newUser = _Utils_update(
						user,
						{R: email});
					var newUsers = A3($elm_community$list_extra$List$Extra$setAt, index, newUser, model.f);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{f: newUsers}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 6:
				var index = msg.a;
				var name = msg.b;
				var _v2 = A2($elm_community$list_extra$List$Extra$getAt, index, model.f);
				if (!_v2.$) {
					var user = _v2.a;
					var newUser = _Utils_update(
						user,
						{U: name});
					var newUsers = A3($elm_community$list_extra$List$Extra$setAt, index, newUser, model.f);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{f: newUsers}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 7:
				var index = msg.a;
				var isAdmin = msg.b;
				var _v3 = A2($elm_community$list_extra$List$Extra$getAt, index, model.f);
				if (!_v3.$) {
					var user = _v3.a;
					var newUser = _Utils_update(
						user,
						{bb: isAdmin});
					var newUsers = A3($elm_community$list_extra$List$Extra$setAt, index, newUser, model.f);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{f: newUsers}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 8:
				var index = msg.a;
				var canAssign = msg.b;
				var _v4 = A2($elm_community$list_extra$List$Extra$getAt, index, model.f);
				if (!_v4.$) {
					var user = _v4.a;
					var newUser = _Utils_update(
						user,
						{a0: canAssign});
					var newUsers = A3($elm_community$list_extra$List$Extra$setAt, index, newUser, model.f);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{f: newUsers}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 1:
				var index = msg.a;
				var newUsers = A2($elm_community$list_extra$List$Extra$removeAt, index, model.f);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f: newUsers}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				var index = msg.a;
				var _v5 = A2($elm_community$list_extra$List$Extra$getAt, index, model.f);
				if (!_v5.$) {
					var user = _v5.a;
					var newName = $author$project$User$getEmailUserName(user.R);
					var updatedUser = function () {
						var _v6 = user.U === '';
						if (!_v6) {
							return user;
						} else {
							return _Utils_update(
								user,
								{U: newName});
						}
					}();
					var newUsers = A3($elm_community$list_extra$List$Extra$setAt, index, updatedUser, model.f);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{f: newUsers}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aL: '', aA: 0, aW: true}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 12:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aW: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 13:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just($author$project$AddUsersModal$ModalClosed));
			case 9:
				var input = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aL: input}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var items = msg.a;
				var usersToAdd = A2(
					$elm$core$List$map,
					function (_v7) {
						var name = _v7.a;
						var email = _v7.b;
						return A6($author$project$AddUsersModal$NewUser, name, $elm$core$Maybe$Nothing, email, $elm$core$Maybe$Nothing, false, false);
					},
					items);
				var existingNewUsers = A2(
					$elm$core$List$filter,
					function (u) {
						return (u.U !== '') && (u.R !== '');
					},
					model.f);
				var newUsers = _Utils_ap(existingNewUsers, usersToAdd);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f: newUsers, aW: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				var newUsers = $author$project$AddUsersModal$getNewUsersErrors(model.f);
				var foundErrors = A2($elm$core$List$any, $author$project$AddUsersModal$userHasError, newUsers);
				var _v8 = function () {
					if (foundErrors) {
						return _Utils_Tuple2(3, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							1,
							$author$project$AddUsersModal$remoteSaveNewUsers(newUsers));
					}
				}();
				var status = _v8.a;
				var cmd = _v8.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{f: newUsers, aA: status}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 11:
				var res = msg.a;
				if (!res.$) {
					var _v11 = res.a;
					var userList = _v11.b;
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$AddUsersModal$GotUsersAndDone(userList)));
				} else {
					var err = res.a;
					if (err.$ === 3) {
						var metadata = err.a;
						var body = err.b;
						var _v13 = metadata.d0;
						if (_v13 === 422) {
							var _v14 = A2($elm$json$Json$Decode$decodeString, $author$project$AddUsersModal$newUsersDecoder, body);
							if (!_v14.$) {
								var newUsersWithErrors = _v14.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{f: newUsersWithErrors, aA: 3}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							} else {
								var decodeError = _v14.a;
								return _Utils_Tuple3(
									model,
									A2($author$project$Errors$captureDecodeError, 'decode new users save response', decodeError),
									$elm$core$Maybe$Nothing);
							}
						} else {
							return _Utils_Tuple3(
								model,
								A2($author$project$Errors$captureHttpDetailedError, 'new users save response', err),
								$elm$core$Maybe$Nothing);
						}
					} else {
						return _Utils_Tuple3(
							model,
							A2($author$project$Errors$captureHttpDetailedError, 'new users save response', err),
							$elm$core$Maybe$Nothing);
					}
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$ContactSupportModal$CloseClicked = {$: 2};
var $author$project$ContactSupportModal$SaveResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$ContactSupportModal$postMessage = function (model) {
	var body = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'message',
				$elm$json$Json$Encode$string(model.aR))
			]));
	return $elm$http$Http$post(
		{
			dl: $elm$http$Http$jsonBody(body),
			dv: $elm$http$Http$expectWhatever($author$project$ContactSupportModal$SaveResponse),
			d9: '/board/help'
		});
};
var $author$project$ContactSupportModal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var message = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aR: message, aG: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aG: 1}),
					$author$project$ContactSupportModal$postMessage(model),
					$elm$core$Maybe$Nothing);
			case 2:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just($author$project$ContactSupportModal$CloseClicked));
			default:
				var res = msg.a;
				if (!res.$) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aR: '', aG: 2}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var unexpectedError = res.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aG: 3}),
						A2($author$project$Errors$captureHttpError, 'help', unexpectedError),
						$elm$core$Maybe$Nothing);
				}
		}
	});
var $author$project$Pages$Editor$ConfirmDeleteSpaceGroupModal = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Editor$ConfirmDeleteSpaceModal = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Editor$EditSpaceGroupModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Editor$EditSpaceModal = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pages$Editor$ModalClosed = {$: 8};
var $author$project$Pages$Editor$PageDefault = 0;
var $author$project$Pages$Editor$PageError = 4;
var $author$project$Pages$Editor$Saving = 2;
var $author$project$Pages$Editor$Success = 3;
var $author$project$Pages$Editor$EditableSpace = F2(
	function (id, label) {
		return {dC: id, bd: label};
	});
var $author$project$Pages$Editor$addSpace = F3(
	function (numberToAdd, spaceGroupIndex, spaceGroups) {
		var _v0 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, spaceGroups);
		if (!_v0.$) {
			var sg = _v0.a;
			var spaces = sg.bn;
			var currentCount = $elm$core$List$length(spaces);
			var toAdd = A2(
				$elm$core$List$map,
				function (i) {
					return A2(
						$author$project$Pages$Editor$EditableSpace,
						$elm$core$Maybe$Nothing,
						'Space ' + $elm$core$String$fromInt(i));
				},
				A2($elm$core$List$range, currentCount + 1, currentCount + numberToAdd));
			var spaceGroup = _Utils_update(
				sg,
				{
					bn: A2($elm$core$List$append, spaces, toAdd)
				});
			return A3($elm_community$list_extra$List$Extra$setAt, spaceGroupIndex, spaceGroup, spaceGroups);
		} else {
			return spaceGroups;
		}
	});
var $author$project$Pages$Editor$addSpaceGroup = function (spaceGroups) {
	var spaces = A2(
		$elm$core$List$map,
		function (i) {
			return A2(
				$author$project$Pages$Editor$EditableSpace,
				$elm$core$Maybe$Nothing,
				'Space ' + $elm$core$String$fromInt(i));
		},
		A2($elm$core$List$range, 1, 4));
	var label = 'Parking ' + $elm$core$String$fromInt(
		$elm$core$List$length(spaceGroups) + 1);
	var newSpaceGroup = {dC: $elm$core$Maybe$Nothing, bd: label, bn: spaces};
	return A2(
		$elm$core$List$append,
		spaceGroups,
		_List_fromArray(
			[newSpaceGroup]));
};
var $author$project$Pages$Editor$getSpace = F2(
	function (_v0, spaceGroups) {
		var spaceGroupIndex = _v0.a;
		var spaceIndex = _v0.b;
		var _v1 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, spaceGroups);
		if (!_v1.$) {
			var spaceGroup = _v1.a;
			var _v2 = A2($elm_community$list_extra$List$Extra$getAt, spaceIndex, spaceGroup.bn);
			if (!_v2.$) {
				var space = _v2.a;
				return $elm$core$Maybe$Just(space);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm_community$list_extra$List$Extra$splitAt = F2(
	function (n, xs) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, n, xs),
			A2($elm$core$List$drop, n, xs));
	});
var $author$project$Pages$Editor$moveSpace = F3(
	function (_v0, _v1, spaceGroups) {
		var fromSpaceGroupIndex = _v0.a;
		var fromSpaceIndex = _v0.b;
		var toSpaceGroupIndex = _v1.a;
		var toSpaceIndex = _v1.b;
		var _v2 = _Utils_eq(fromSpaceGroupIndex, toSpaceGroupIndex);
		if (_v2) {
			var _v3 = A2($elm_community$list_extra$List$Extra$getAt, fromSpaceGroupIndex, spaceGroups);
			if (!_v3.$) {
				var spaceGroup = _v3.a;
				var splitIndex = function () {
					var _v6 = _Utils_cmp(fromSpaceIndex, toSpaceIndex) < 0;
					if (_v6) {
						return toSpaceIndex - 1;
					} else {
						return toSpaceIndex;
					}
				}();
				var spaces = A2($elm_community$list_extra$List$Extra$removeAt, fromSpaceIndex, spaceGroup.bn);
				var maybeToMove = A2($elm_community$list_extra$List$Extra$getAt, fromSpaceIndex, spaceGroup.bn);
				var _v4 = A2($elm_community$list_extra$List$Extra$splitAt, splitIndex, spaces);
				var left = _v4.a;
				var right = _v4.b;
				var newSpaces = function () {
					if (!maybeToMove.$) {
						var toMove = maybeToMove.a;
						return _Utils_ap(
							left,
							_Utils_ap(
								_List_fromArray(
									[toMove]),
								right));
					} else {
						return spaces;
					}
				}();
				var newSpaceGroup = _Utils_update(
					spaceGroup,
					{bn: newSpaces});
				return A3($elm_community$list_extra$List$Extra$setAt, fromSpaceGroupIndex, newSpaceGroup, spaceGroups);
			} else {
				return spaceGroups;
			}
		} else {
			var maybeToSpaceGroup = A2($elm_community$list_extra$List$Extra$getAt, toSpaceGroupIndex, spaceGroups);
			var maybeFromSpaceGroup = A2($elm_community$list_extra$List$Extra$getAt, fromSpaceGroupIndex, spaceGroups);
			var _v7 = _Utils_Tuple2(maybeFromSpaceGroup, maybeToSpaceGroup);
			if ((!_v7.a.$) && (!_v7.b.$)) {
				var fromSpaceGroup = _v7.a.a;
				var toSpaceGroup = _v7.b.a;
				var maybeToMove = A2($elm_community$list_extra$List$Extra$getAt, fromSpaceIndex, fromSpaceGroup.bn);
				var fromSpaces = A2($elm_community$list_extra$List$Extra$removeAt, fromSpaceIndex, fromSpaceGroup.bn);
				var newFromSpaceGroup = _Utils_update(
					fromSpaceGroup,
					{bn: fromSpaces});
				var _v8 = A2($elm_community$list_extra$List$Extra$splitAt, toSpaceIndex, toSpaceGroup.bn);
				var left = _v8.a;
				var right = _v8.b;
				var toSpaces = function () {
					if (!maybeToMove.$) {
						var toMove = maybeToMove.a;
						return _Utils_ap(
							left,
							_Utils_ap(
								_List_fromArray(
									[toMove]),
								right));
					} else {
						return toSpaceGroup.bn;
					}
				}();
				var newToSpaceGroup = _Utils_update(
					toSpaceGroup,
					{bn: toSpaces});
				return A3(
					$elm_community$list_extra$List$Extra$setAt,
					toSpaceGroupIndex,
					newToSpaceGroup,
					A3($elm_community$list_extra$List$Extra$setAt, fromSpaceGroupIndex, newFromSpaceGroup, spaceGroups));
			} else {
				return spaceGroups;
			}
		}
	});
var $author$project$Pages$Editor$moveListItem = F3(
	function (fromIndex, toIndex, list) {
		var _v0 = A2($elm_community$list_extra$List$Extra$getAt, fromIndex, list);
		if (!_v0.$) {
			var item = _v0.a;
			var splitIndex = function () {
				var _v2 = _Utils_cmp(fromIndex, toIndex) < 0;
				if (_v2) {
					return toIndex;
				} else {
					return fromIndex - 1;
				}
			}();
			var listWithoutItem = A2($elm_community$list_extra$List$Extra$removeAt, fromIndex, list);
			var _v1 = A2($elm_community$list_extra$List$Extra$splitAt, splitIndex, listWithoutItem);
			var left = _v1.a;
			var right = _v1.b;
			return _Utils_ap(
				left,
				_Utils_ap(
					_List_fromArray(
						[item]),
					right));
		} else {
			return list;
		}
	});
var $author$project$Pages$Editor$moveSpaceGroup = F3(
	function (direction, currentIndex, spaceGroups) {
		if (!direction) {
			var moveToIndex = currentIndex - 1;
			var _v1 = moveToIndex >= 0;
			if (_v1) {
				return A3($author$project$Pages$Editor$moveListItem, currentIndex, moveToIndex, spaceGroups);
			} else {
				return spaceGroups;
			}
		} else {
			var moveToIndex = currentIndex + 1;
			var _v2 = _Utils_cmp(
				moveToIndex,
				$elm$core$List$length(spaceGroups)) < 0;
			if (_v2) {
				return A3($author$project$Pages$Editor$moveListItem, currentIndex, moveToIndex, spaceGroups);
			} else {
				return spaceGroups;
			}
		}
	});
var $author$project$Pages$Editor$encodeDeletes = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'spaceIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, model.P)),
				_Utils_Tuple2(
				'spaceGroupIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, model.a3))
			]));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $author$project$Pages$Editor$encodeEditableSpace = function (es) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, es.dC)),
				_Utils_Tuple2(
				'label',
				$elm$json$Json$Encode$string(es.bd))
			]));
};
var $author$project$Pages$Editor$encodeEditableSpaceGroup = function (esg) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, esg.dC)),
				_Utils_Tuple2(
				'label',
				$elm$json$Json$Encode$string(esg.bd)),
				_Utils_Tuple2(
				'spaces',
				A2($elm$json$Json$Encode$list, $author$project$Pages$Editor$encodeEditableSpace, esg.bn))
			]));
};
var $author$project$Pages$Editor$encodeSaveRequest = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'spaceGroups',
				A2($elm$json$Json$Encode$list, $author$project$Pages$Editor$encodeEditableSpaceGroup, model.Z)),
				_Utils_Tuple2(
				'deleted',
				$author$project$Pages$Editor$encodeDeletes(model))
			]));
};
var $author$project$Pages$Editor$postSave = function (model) {
	return $elm$http$Http$post(
		{
			dl: $elm$http$Http$jsonBody(
				$author$project$Pages$Editor$encodeSaveRequest(model)),
			dv: A2(
				$elm$http$Http$expectJson,
				$author$project$Pages$Editor$GotSpaceGroups(true),
				$author$project$SpaceGroup$spaceGroupsDecoder),
			d9: '/board/space-groups'
		});
};
var $author$project$Pages$Editor$removeSpace = F3(
	function (spaceGroupIndex, spaceIndex, spaceGroups) {
		var _v0 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, spaceGroups);
		if (!_v0.$) {
			var spaceGroup = _v0.a;
			var spaces = A2($elm_community$list_extra$List$Extra$removeAt, spaceIndex, spaceGroup.bn);
			var newSpaceGroup = _Utils_update(
				spaceGroup,
				{bn: spaces});
			return A3($elm_community$list_extra$List$Extra$setAt, spaceGroupIndex, newSpaceGroup, spaceGroups);
		} else {
			return spaceGroups;
		}
	});
var $author$project$Pages$Editor$setSpace = F3(
	function (_v0, space, spaceGroups) {
		var spaceGroupIndex = _v0.a;
		var spaceIndex = _v0.b;
		var _v1 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, spaceGroups);
		if (!_v1.$) {
			var spaceGroup = _v1.a;
			var newSpaces = A3($elm_community$list_extra$List$Extra$setAt, spaceIndex, space, spaceGroup.bn);
			var newSpaceGroup = _Utils_update(
				spaceGroup,
				{bn: newSpaces});
			return A3($elm_community$list_extra$List$Extra$setAt, spaceGroupIndex, newSpaceGroup, spaceGroups);
		} else {
			return spaceGroups;
		}
	});
var $author$project$Pages$Editor$setSpaceGroupLabel = F3(
	function (spaceGroupIndex, label, spaceGroups) {
		var _v0 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, spaceGroups);
		if (!_v0.$) {
			var sg = _v0.a;
			var spaceGroup = _Utils_update(
				sg,
				{bd: label});
			return A3($elm_community$list_extra$List$Extra$setAt, spaceGroupIndex, spaceGroup, spaceGroups);
		} else {
			return spaceGroups;
		}
	});
var $author$project$Pages$Editor$spaceToEditable = function (space) {
	return {
		dC: $elm$core$Maybe$Just(space.dC),
		bd: space.bd
	};
};
var $author$project$Pages$Editor$spaceGroupToEditable = function (spaceGroup) {
	return {
		dC: $elm$core$Maybe$Just(spaceGroup.dC),
		bd: spaceGroup.bd,
		bn: A2($elm$core$List$map, $author$project$Pages$Editor$spaceToEditable, spaceGroup.bn)
	};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$Dragging = function (a) {
	return {$: 1, a: a};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$updateCommon = F3(
	function (sticky, msg, model) {
		var _v0 = _Utils_Tuple3(msg, model, sticky);
		_v0$9:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					var dragId = _v1.a;
					return _Utils_Tuple2(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$Dragging(dragId),
						$elm$core$Maybe$Nothing);
				case 1:
					var _v2 = _v0.a;
					return _Utils_Tuple2($norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging, $elm$core$Maybe$Nothing);
				case 2:
					switch (_v0.b.$) {
						case 1:
							var dropId = _v0.a.a;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								A4($norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver, dragId, dropId, 0, $elm$core$Maybe$Nothing),
								$elm$core$Maybe$Nothing);
						case 2:
							var dropId = _v0.a.a;
							var _v3 = _v0.b;
							var dragId = _v3.a;
							var pos = _v3.d;
							return _Utils_Tuple2(
								A4($norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver, dragId, dropId, 0, pos),
								$elm$core$Maybe$Nothing);
						default:
							break _v0$9;
					}
				case 3:
					if ((_v0.b.$ === 2) && (!_v0.c)) {
						var dropId_ = _v0.a.a;
						var _v4 = _v0.b;
						var dragId = _v4.a;
						var dropId = _v4.b;
						return _Utils_eq(dropId_, dropId) ? _Utils_Tuple2(
							$norpan$elm_html5_drag_drop$Html5$DragDrop$Dragging(dragId),
							$elm$core$Maybe$Nothing) : _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
					} else {
						break _v0$9;
					}
				case 4:
					switch (_v0.b.$) {
						case 1:
							var _v5 = _v0.a;
							var dropId = _v5.a;
							var timeStamp = _v5.b;
							var pos = _v5.c;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								A4(
									$norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver,
									dragId,
									dropId,
									timeStamp,
									$elm$core$Maybe$Just(pos)),
								$elm$core$Maybe$Nothing);
						case 2:
							var _v6 = _v0.a;
							var dropId = _v6.a;
							var timeStamp = _v6.b;
							var pos = _v6.c;
							var _v7 = _v0.b;
							var dragId = _v7.a;
							var currentDropId = _v7.b;
							var currentTimeStamp = _v7.c;
							var currentPos = _v7.d;
							return _Utils_eq(timeStamp, currentTimeStamp) ? _Utils_Tuple2(model, $elm$core$Maybe$Nothing) : _Utils_Tuple2(
								A4(
									$norpan$elm_html5_drag_drop$Html5$DragDrop$DraggedOver,
									dragId,
									dropId,
									timeStamp,
									$elm$core$Maybe$Just(pos)),
								$elm$core$Maybe$Nothing);
						default:
							break _v0$9;
					}
				default:
					switch (_v0.b.$) {
						case 1:
							var _v8 = _v0.a;
							var dropId = _v8.a;
							var pos = _v8.b;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								$norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging,
								$elm$core$Maybe$Just(
									_Utils_Tuple3(dragId, dropId, pos)));
						case 2:
							var _v9 = _v0.a;
							var dropId = _v9.a;
							var pos = _v9.b;
							var _v10 = _v0.b;
							var dragId = _v10.a;
							return _Utils_Tuple2(
								$norpan$elm_html5_drag_drop$Html5$DragDrop$NotDragging,
								$elm$core$Maybe$Just(
									_Utils_Tuple3(dragId, dropId, pos)));
						default:
							break _v0$9;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$update = $norpan$elm_html5_drag_drop$Html5$DragDrop$updateCommon(false);
var $author$project$Pages$Editor$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Z: $author$project$Pages$Editor$addSpaceGroup(model.Z)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var index = msg.a;
				var label = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Z: A3($author$project$Pages$Editor$setSpaceGroupLabel, index, label, model.Z)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var spaceGroupIndex = msg.a;
				var spaceGroups = A3($author$project$Pages$Editor$addSpace, 1, spaceGroupIndex, model.Z);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Z: spaceGroups}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aG: 2}),
					$author$project$Pages$Editor$postSave(model));
			case 0:
				var wasSave = msg.a;
				var res = msg.b;
				if (!res.$) {
					var spaceGroups = res.a;
					var status = function () {
						if (wasSave) {
							return 3;
						} else {
							return 0;
						}
					}();
					var editableSpaceGroups = A2($elm$core$List$map, $author$project$Pages$Editor$spaceGroupToEditable, spaceGroups);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bG: editableSpaceGroups, Z: editableSpaceGroups, aG: status}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aG: 4}),
						A2($author$project$Errors$captureHttpError, 'save space groups response', error));
				}
			case 5:
				var spaceGroupIndex = msg.a;
				var spaceIndex = msg.b;
				var maybeSpace = A2(
					$author$project$Pages$Editor$getSpace,
					_Utils_Tuple2(spaceGroupIndex, spaceIndex),
					model.Z);
				if (!maybeSpace.$) {
					var space = maybeSpace.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Q: space.bd,
								af: A2(
									$author$project$Components$Modal$pushModalStack,
									A2($author$project$Pages$Editor$EditSpaceModal, spaceGroupIndex, spaceIndex),
									model.af)
							}),
						$author$project$Components$Modal$toggleScrollLockForModal(true));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var label = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Q: label}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var spaceGroupIndex = msg.a;
				var spaceIndex = msg.b;
				var maybeSpace = A2(
					$author$project$Pages$Editor$getSpace,
					_Utils_Tuple2(spaceGroupIndex, spaceIndex),
					model.Z);
				var newModel = function () {
					if (!maybeSpace.$) {
						var space = maybeSpace.a;
						var updatedSpace = _Utils_update(
							space,
							{bd: model.Q});
						var spaceGroups = A3(
							$author$project$Pages$Editor$setSpace,
							_Utils_Tuple2(spaceGroupIndex, spaceIndex),
							updatedSpace,
							model.Z);
						return _Utils_update(
							model,
							{Z: spaceGroups});
					} else {
						return model;
					}
				}();
				return _Utils_Tuple2(
					newModel,
					$author$project$Util$send($author$project$Pages$Editor$ModalClosed));
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{af: _List_Nil}),
					$author$project$Components$Modal$toggleScrollLockForModal(false));
			case 9:
				var msg_ = msg.a;
				var _v5 = A2($norpan$elm_html5_drag_drop$Html5$DragDrop$update, msg_, model.a4);
				var model_ = _v5.a;
				var result = _v5.b;
				var newModel = _Utils_update(
					model,
					{a4: model_});
				var modelWithMove = function () {
					if (!result.$) {
						var _v7 = result.a;
						var dragId = _v7.a;
						var dropId = _v7.b;
						if (!dropId.$) {
							var _v9 = dropId.a;
							var spaceGroupIndex = _v9.a;
							var spaceIndex = _v9.b;
							var spaceGroups = A3(
								$author$project$Pages$Editor$moveSpace,
								dragId,
								_Utils_Tuple2(spaceGroupIndex, spaceIndex),
								model.Z);
							return _Utils_update(
								newModel,
								{Z: spaceGroups});
						} else {
							var _v10 = dropId.a;
							var spaceGroupIndex = _v10.a;
							var spaceIndex = _v10.b;
							var spaceGroups = A3(
								$author$project$Pages$Editor$moveSpace,
								dragId,
								_Utils_Tuple2(spaceGroupIndex, spaceIndex),
								model.Z);
							return _Utils_update(
								newModel,
								{Z: spaceGroups});
						}
					} else {
						return newModel;
					}
				}();
				return _Utils_Tuple2(modelWithMove, $elm$core$Platform$Cmd$none);
			case 10:
				var direction = msg.a;
				var currentIndex = msg.b;
				var spaceGroups = A3($author$project$Pages$Editor$moveSpaceGroup, direction, currentIndex, model.Z);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Z: spaceGroups}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var spaceGroupIndex = msg.a;
				var _v11 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, model.Z);
				if (!_v11.$) {
					var spaceGroup = _v11.a;
					var modalStack = A2(
						$author$project$Components$Modal$pushModalStack,
						$author$project$Pages$Editor$EditSpaceGroupModal(spaceGroupIndex),
						model.af);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Q: spaceGroup.bd, af: modalStack}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 12:
				var spaceGroupIndex = msg.a;
				var _v12 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, model.Z);
				if (!_v12.$) {
					var spaceGroup = _v12.a;
					var newSpaceGroup = _Utils_update(
						spaceGroup,
						{bd: model.Q});
					var spaceGroups = A3($elm_community$list_extra$List$Extra$setAt, spaceGroupIndex, newSpaceGroup, model.Z);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Z: spaceGroups}),
						$author$project$Util$send($author$project$Pages$Editor$ModalClosed));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 13:
				var spaceGroupIndex = msg.a;
				var spaceIndex = msg.b;
				var confirmed = msg.c;
				var _v13 = A2(
					$author$project$Pages$Editor$getSpace,
					_Utils_Tuple2(spaceGroupIndex, spaceIndex),
					model.Z);
				if (!_v13.$) {
					var space = _v13.a;
					var _v14 = space.dC;
					if (!_v14.$) {
						var spaceId = _v14.a;
						if (!confirmed) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										af: A2(
											$author$project$Components$Modal$pushModalStack,
											A2($author$project$Pages$Editor$ConfirmDeleteSpaceModal, spaceGroupIndex, spaceIndex),
											model.af)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										P: A2($elm$core$List$cons, spaceId, model.P),
										Z: A3($author$project$Pages$Editor$removeSpace, spaceGroupIndex, spaceIndex, model.Z)
									}),
								$author$project$Util$send($author$project$Pages$Editor$ModalClosed));
						}
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									Z: A3($author$project$Pages$Editor$removeSpace, spaceGroupIndex, spaceIndex, model.Z)
								}),
							$author$project$Util$send($author$project$Pages$Editor$ModalClosed));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 14:
				var spaceGroupIndex = msg.a;
				var confirmed = msg.b;
				var _v16 = A2($elm_community$list_extra$List$Extra$getAt, spaceGroupIndex, model.Z);
				if (!_v16.$) {
					var spaceGroup = _v16.a;
					var spaceIds = A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.dC;
							},
							spaceGroup.bn));
					var needsConfirm = (!_Utils_eq(spaceGroup.dC, $elm$core$Maybe$Nothing)) || ($elm$core$List$length(spaceIds) > 0);
					var _v17 = _Utils_Tuple2(needsConfirm, confirmed);
					if (_v17.a) {
						if (!_v17.b) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										af: A2(
											$author$project$Components$Modal$pushModalStack,
											$author$project$Pages$Editor$ConfirmDeleteSpaceGroupModal(spaceGroupIndex),
											model.af)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							var _v18 = spaceGroup.dC;
							if (!_v18.$) {
								var spaceGroupId = _v18.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											a3: A2($elm$core$List$cons, spaceGroupId, model.a3),
											P: _Utils_ap(spaceIds, model.P),
											Z: A2($elm_community$list_extra$List$Extra$removeAt, spaceGroupIndex, model.Z)
										}),
									$author$project$Util$send($author$project$Pages$Editor$ModalClosed));
							} else {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											P: _Utils_ap(spaceIds, model.P),
											Z: A2($elm_community$list_extra$List$Extra$removeAt, spaceGroupIndex, model.Z)
										}),
									$author$project$Util$send($author$project$Pages$Editor$ModalClosed));
							}
						}
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									Z: A2($elm_community$list_extra$List$Extra$removeAt, spaceGroupIndex, model.Z)
								}),
							$author$project$Util$send($author$project$Pages$Editor$ModalClosed));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 15:
				var modalStack = $author$project$Components$Modal$popModalStack(model.af);
				var cmd = function () {
					var _v19 = $elm$core$List$length(modalStack);
					if (!_v19) {
						return $author$project$Components$Modal$toggleScrollLockForModal(false);
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{af: modalStack}),
					cmd);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Login$RequestResetSection = 1;
var $author$project$Pages$Login$SessionUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$Validation$Unexpected = {$: 4};
var $author$project$HttpUtil$getCodeAndString = function (result) {
	if (result.$ === 1) {
		var error = result.a;
		switch (error.$) {
			case 3:
				var metadata = error.a;
				var body = error.b;
				return _Utils_Tuple2(metadata.d0, body);
			case 4:
				var metadata = error.a;
				return _Utils_Tuple2(metadata.d0, '');
			default:
				return _Utils_Tuple2(500, '');
		}
	} else {
		var _v2 = result.a;
		var metaData = _v2.a;
		var data = _v2.b;
		return _Utils_Tuple2(metaData.d0, data);
	}
};
var $author$project$Pages$Login$GotLoginResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Login$postLogin = function (model) {
	var body = $elm$http$Http$jsonBody(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Username',
					$elm$json$Json$Encode$string(model.R)),
					_Utils_Tuple2(
					'Password',
					$elm$json$Json$Encode$string(model.aj))
				])));
	return $elm$http$Http$post(
		{
			dl: body,
			dv: A2($elm$http$Http$expectJson, $author$project$Pages$Login$GotLoginResponse, $author$project$User$userDecoder),
			d9: '/login'
		});
};
var $author$project$Pages$Login$GotNewPasswordResponse = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Login$postNewPassword = F2(
	function (password, token) {
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'token',
						$elm$json$Json$Encode$string(token)),
						_Utils_Tuple2(
						'password',
						$elm$json$Json$Encode$string(password))
					])));
		return $elm$http$Http$post(
			{
				dl: body,
				dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$Login$GotNewPasswordResponse),
				d9: '/set-new-password'
			});
	});
var $author$project$Pages$Login$GotRequestMagicLinkResponse = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Login$postRequestMagicLink = function (model) {
	var body = $elm$http$Http$jsonBody(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(model.aw))
				])));
	return $elm$http$Http$post(
		{
			dl: body,
			dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$Login$GotRequestMagicLinkResponse),
			d9: '/request-login-link'
		});
};
var $author$project$Pages$Login$GotRequestPasswordResetResponse = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Login$postResetPassword = function (model) {
	var body = $elm$http$Http$jsonBody(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(model.aD))
				])));
	return $elm$http$Http$post(
		{
			dl: body,
			dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$Login$GotRequestPasswordResetResponse),
			d9: '/request-password-reset'
		});
};
var $author$project$Pages$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aG: 1}),
					$author$project$Pages$Login$postLogin(model),
					$elm$core$Maybe$Nothing);
			case 0:
				var result = msg.a;
				if (!result.$) {
					var loggedIn = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{R: '', aj: '', aG: 0}),
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.K,
							$author$project$Routes$path($author$project$Routes$boardPath)),
						$elm$core$Maybe$Just(
							$author$project$Pages$Login$SessionUpdated(loggedIn)));
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{R: '', aj: '', aG: 3}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			case 2:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{R: email}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				var password = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aj: password}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 6:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: email, ax: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bf: $elm$core$Maybe$Nothing, ax: 1}),
					$author$project$Pages$Login$postRequestMagicLink(model),
					$elm$core$Maybe$Nothing);
			case 8:
				var res = msg.a;
				var _v2 = $author$project$HttpUtil$getCodeAndString(res);
				var code = _v2.a;
				var _v3 = function () {
					switch (code) {
						case 200:
							return _Utils_Tuple2(2, $elm$core$Maybe$Nothing);
						case 404:
							return _Utils_Tuple2(
								3,
								$elm$core$Maybe$Just(
									$author$project$Validation$Other('You entered an incorrect email, please try again')));
						default:
							return _Utils_Tuple2(
								3,
								$elm$core$Maybe$Just($author$project$Validation$Unexpected));
					}
				}();
				var status = _v3.a;
				var error = _v3.b;
				var cmd = function () {
					if ((!error.$) && (error.a.$ === 4)) {
						var _v6 = error.a;
						return A2($author$project$Errors$captureErrorString, 'request magic link', 'unexpected error');
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: '', bf: error, ax: status}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 9:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aD: '', bm: 1}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aD: email, aE: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 11:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bl: $elm$core$Maybe$Nothing, aE: 1}),
					$author$project$Pages$Login$postResetPassword(model),
					$elm$core$Maybe$Nothing);
			case 12:
				var res = msg.a;
				var _v7 = $author$project$HttpUtil$getCodeAndString(res);
				var code = _v7.a;
				var _v8 = function () {
					switch (code) {
						case 200:
							return _Utils_Tuple2(2, $elm$core$Maybe$Nothing);
						case 404:
							return _Utils_Tuple2(
								3,
								$elm$core$Maybe$Just(
									$author$project$Validation$Other('You entered an incorrect email, please try again')));
						default:
							return _Utils_Tuple2(
								3,
								$elm$core$Maybe$Just($author$project$Validation$Unexpected));
					}
				}();
				var status = _v8.a;
				var error = _v8.b;
				var cmd = function () {
					if ((!error.$) && (error.a.$ === 4)) {
						var _v11 = error.a;
						return A2($author$project$Errors$captureErrorString, 'request password', 'unexpected error');
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bl: error, aE: status}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 13:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bm: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 14:
				var res = msg.a;
				var _v12 = $author$project$HttpUtil$getCodeAndString(res);
				switch (_v12.a) {
					case 200:
						var body = _v12.b;
						var _v13 = A2($elm$json$Json$Decode$decodeString, $author$project$User$userDecoder, body);
						if (!_v13.$) {
							var user = _v13.a;
							return _Utils_Tuple3(
								model,
								A2(
									$elm$browser$Browser$Navigation$pushUrl,
									model.K,
									$author$project$Routes$path($author$project$Routes$boardPath)),
								$elm$core$Maybe$Just(
									$author$project$Pages$Login$SessionUpdated(user)));
						} else {
							var unexpectedError = _v13.a;
							return _Utils_Tuple3(
								model,
								A2($author$project$Errors$captureDecodeError, 'magic login could not decode user', unexpectedError),
								$elm$core$Maybe$Nothing);
						}
					case 410:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{bD: 3}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					default:
						return _Utils_Tuple3(
							model,
							A2($author$project$Errors$captureErrorString, 'use magic login token', 'unexpected error'),
							$elm$core$Maybe$Nothing);
				}
			case 15:
				var password = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ay: password}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 16:
				var password = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aS: password}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 17:
				var token = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aT: 1}),
					A2($author$project$Pages$Login$postNewPassword, model.ay, token),
					$elm$core$Maybe$Nothing);
			case 18:
				var res = msg.a;
				var _v14 = $author$project$HttpUtil$getCodeAndString(res);
				switch (_v14.a) {
					case 200:
						return _Utils_Tuple3(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.K,
								$author$project$Routes$path($author$project$Routes$boardPath)),
							$elm$core$Maybe$Nothing);
					case 410:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{aT: 3}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					default:
						return _Utils_Tuple3(
							model,
							A2($author$project$Errors$captureErrorString, 'set new password with token', 'unexpected error'),
							$elm$core$Maybe$Nothing);
				}
			case 4:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$Validation$checkCannotBeBlank = function (value) {
	var _v0 = value === '';
	if (_v0) {
		return $elm$core$Maybe$Just($author$project$Validation$CannotBeBlank);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Validation$checkValidEmail = function (value) {
	var _v0 = $author$project$Validation$emailIsValid(value);
	if (_v0) {
		return $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Just(
			$author$project$Validation$Other('Email address not valid'));
	}
};
var $author$project$Validation$hasAnyError = function (list) {
	return A2(
		$elm$core$List$any,
		function (i) {
			return !_Utils_eq(i, $elm$core$Maybe$Nothing);
		},
		list);
};
var $author$project$Pages$Profile$PasswordErrors = F2(
	function (passwordError, newPasswordError) {
		return {az: newPasswordError, cM: passwordError};
	});
var $author$project$Pages$Profile$passwordErrorsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'newPasswordError',
	$author$project$Validation$fieldErrorDecoder,
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'passwordError',
		$author$project$Validation$fieldErrorDecoder,
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Pages$Profile$PasswordErrors)));
var $author$project$Pages$Profile$SaveResponse = function (a) {
	return {$: 6, a: a};
};
var $author$project$User$userTypeToString = function (userType) {
	if (!userType) {
		return 'admin';
	} else {
		return 'read_only';
	}
};
var $author$project$User$userEncoder = function (user) {
	var permList = function () {
		var _v0 = user.a0;
		if (_v0) {
			return _List_fromArray(
				['can_assign']);
		} else {
			return _List_Nil;
		}
	}();
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(user.dC)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(user.U)),
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(user.R)),
				_Utils_Tuple2(
				'userType',
				$elm$json$Json$Encode$string(
					$author$project$User$userTypeToString(user.c9))),
				_Utils_Tuple2(
				'permissions',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, permList))
			]));
};
var $author$project$Pages$Profile$postMe = F2(
	function (user, model) {
		var userType = function () {
			var _v0 = model.bb;
			if (_v0) {
				return 0;
			} else {
				return 1;
			}
		}();
		var updatedUser = _Utils_update(
			user,
			{a0: model.a0, R: model.R, U: model.U, c9: userType});
		return $elm$http$Http$post(
			{
				dl: $elm$http$Http$jsonBody(
					$author$project$User$userEncoder(updatedUser)),
				dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$Profile$SaveResponse),
				d9: '/board/me'
			});
	});
var $author$project$Pages$Profile$UpdatePasswordResponse = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Profile$postPassword = function (model) {
	var body = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'currentPassword',
				$elm$json$Json$Encode$string(model.ao)),
				_Utils_Tuple2(
				'newPassword',
				$elm$json$Json$Encode$string(model.ay))
			]));
	return $elm$http$Http$post(
		{
			dl: $elm$http$Http$jsonBody(body),
			dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$Profile$UpdatePasswordResponse),
			d9: '/board/me/password'
		});
};
var $author$project$Pages$Profile$resetSave = function (model) {
	return _Utils_update(
		model,
		{X: 0});
};
var $author$project$User$UserErrors = F2(
	function (emailError, nameError) {
		return {bA: emailError, bF: nameError};
	});
var $author$project$User$userErrorsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'nameError',
	$author$project$Validation$fieldErrorDecoder,
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'emailError',
		$author$project$Validation$fieldErrorDecoder,
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$User$UserErrors)));
var $author$project$Pages$Profile$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var res = msg.a;
				if (!res.$) {
					var user = res.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								a0: user.a0,
								R: user.R,
								bb: !user.c9,
								U: user.U,
								ea: $elm$core$Maybe$Just(user)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var error = res.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureHttpError, 'profile get me', error),
						$elm$core$Maybe$Nothing);
				}
			case 1:
				var name = msg.a;
				return _Utils_Tuple3(
					$author$project$Pages$Profile$resetSave(
						_Utils_update(
							model,
							{U: name})),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				var email = msg.a;
				return _Utils_Tuple3(
					$author$project$Pages$Profile$resetSave(
						_Utils_update(
							model,
							{R: email})),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				var isAdmin = msg.a;
				return _Utils_Tuple3(
					$author$project$Pages$Profile$resetSave(
						_Utils_update(
							model,
							{bb: isAdmin})),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 4:
				var canAssign = msg.a;
				return _Utils_Tuple3(
					$author$project$Pages$Profile$resetSave(
						_Utils_update(
							model,
							{a0: canAssign})),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 5:
				var _v2 = model.ea;
				if (!_v2.$) {
					var user = _v2.a;
					var nameError = $author$project$Validation$checkCannotBeBlank(model.U);
					var emailError = $author$project$Validation$checkValidEmail(model.R);
					var modelWithErrors = _Utils_update(
						model,
						{bA: emailError, bF: nameError});
					var _v3 = $author$project$Validation$hasAnyError(
						_List_fromArray(
							[nameError, emailError]));
					if (_v3) {
						return _Utils_Tuple3(
							_Utils_update(
								modelWithErrors,
								{X: 3}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								modelWithErrors,
								{X: 1}),
							A2($author$project$Pages$Profile$postMe, user, modelWithErrors),
							$elm$core$Maybe$Nothing);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 6:
				var res = msg.a;
				var _v4 = $author$project$HttpUtil$getCodeAndString(res);
				var code = _v4.a;
				var body = _v4.b;
				switch (code) {
					case 422:
						var _v6 = A2($elm$json$Json$Decode$decodeString, $author$project$User$userErrorsDecoder, body);
						if (!_v6.$) {
							var errors = _v6.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{bA: errors.bA, bF: errors.bF, X: 3}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							var error = _v6.a;
							return _Utils_Tuple3(
								model,
								A2($author$project$Errors$captureDecodeError, 'decode post me', error),
								$elm$core$Maybe$Nothing);
						}
					case 200:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{X: 2}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					default:
						return _Utils_Tuple3(
							model,
							A2(
								$author$project$Errors$captureErrorString,
								'decode post me',
								$elm$core$String$fromInt(code)),
							$elm$core$Maybe$Nothing);
				}
			case 7:
				var val = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ao: val, N: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 8:
				var val = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ay: val, N: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 9:
				var newPasswordError = $author$project$Validation$checkCannotBeBlank(model.ay);
				var currentPasswordError = $author$project$Validation$checkCannotBeBlank(model.ao);
				var updatedModel = _Utils_update(
					model,
					{a2: currentPasswordError, az: newPasswordError});
				var _v7 = $author$project$Validation$hasAnyError(
					_List_fromArray(
						[newPasswordError]));
				if (_v7) {
					return _Utils_Tuple3(
						_Utils_update(
							updatedModel,
							{N: 3}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							updatedModel,
							{N: 1}),
						$author$project$Pages$Profile$postPassword(model),
						$elm$core$Maybe$Nothing);
				}
			case 10:
				var res = msg.a;
				var _v8 = $author$project$HttpUtil$getCodeAndString(res);
				if (_v8.a === 422) {
					var body = _v8.b;
					var _v9 = A2($elm$json$Json$Decode$decodeString, $author$project$Pages$Profile$passwordErrorsDecoder, body);
					if (!_v9.$) {
						var pe = _v9.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{a2: pe.cM, az: pe.az, N: 3}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var error = _v9.a;
						return _Utils_Tuple3(
							model,
							A2($author$project$Errors$captureDecodeError, 'password errors decode', error),
							$elm$core$Maybe$Nothing);
					}
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{ao: '', ay: '', N: 2}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$Pages$SignUp$Complete = {$: 18};
var $author$project$Pages$SignUp$GotUser = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$SignUp$HowToOrganiseStage = 1;
var $author$project$Pages$SignUp$SpaceGroupsStage = 2;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Components$Form$anyHasErrors = function (errors) {
	var justErrors = A2(
		$elm$core$List$filter,
		function (e) {
			return !_Utils_eq(e, $elm$core$Maybe$Nothing);
		},
		errors);
	return $elm$core$List$length(justErrors) > 0;
};
var $author$project$Pages$SignUp$getFieldErrors = function (model) {
	return {
		R: $author$project$Validation$checkValidEmail(model.R),
		U: $author$project$Validation$checkCannotBeBlank(model.U),
		cL: $author$project$Validation$checkCannotBeBlank(model.cL),
		aj: $author$project$Validation$checkCannotBeBlank(model.cL)
	};
};
var $author$project$Pages$SignUp$BasicsStageResponse = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$SignUp$encodeSignUp = F2(
	function (timezone, model) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(model.U)),
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(model.R)),
					_Utils_Tuple2(
					'orgName',
					$elm$json$Json$Encode$string(model.cL)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(model.aj)),
					_Utils_Tuple2(
					'timezone',
					$elm$json$Json$Encode$string(timezone))
				]));
	});
var $author$project$Pages$SignUp$postSignUp = F2(
	function (timezone, model) {
		return $elm$http$Http$post(
			{
				dl: $elm$http$Http$jsonBody(
					A2($author$project$Pages$SignUp$encodeSignUp, timezone, model)),
				dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$SignUp$BasicsStageResponse),
				d9: '/org'
			});
	});
var $author$project$Pages$SignUp$SpacesResponse = function (a) {
	return {$: 16, a: a};
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Pages$SignUp$encodeSpaceGroup = function (spaceGroup) {
	var numberOfSpaces = function () {
		var _v0 = spaceGroup.bg;
		if (!_v0.$) {
			var n = _v0.a;
			return n;
		} else {
			return 0;
		}
	}();
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'label',
				$elm$json$Json$Encode$string(spaceGroup.bd)),
				_Utils_Tuple2(
				'numberOfSpaces',
				$elm$json$Json$Encode$int(numberOfSpaces))
			]));
};
var $author$project$Pages$SignUp$encodeSpaceGroups = function (spaceGroups) {
	return A2($elm$json$Json$Encode$list, $author$project$Pages$SignUp$encodeSpaceGroup, spaceGroups);
};
var $author$project$Pages$SignUp$postSpaceGroups = function (model) {
	var spaceGroups = function () {
		var _v0 = model.ad;
		if (!_v0.$) {
			if (!_v0.a) {
				var _v1 = _v0.a;
				return $author$project$Pages$SignUp$encodeSpaceGroups(
					_List_fromArray(
						[
							A2($author$project$Pages$SignUp$SpaceGroup, 'Parking 1', model.aK)
						]));
			} else {
				var _v2 = _v0.a;
				return $author$project$Pages$SignUp$encodeSpaceGroups(model.Z);
			}
		} else {
			return $author$project$Pages$SignUp$encodeSpaceGroups(_List_Nil);
		}
	}();
	return $elm$http$Http$post(
		{
			dl: $elm$http$Http$jsonBody(spaceGroups),
			dv: $elm$http$Http$expectWhatever($author$project$Pages$SignUp$SpacesResponse),
			d9: '/board/init-space-groups'
		});
};
var $author$project$Pages$SignUp$spaceGroupOk = function (spaceGroup) {
	return (!_Utils_eq(spaceGroup.bg, $elm$core$Maybe$Nothing)) && (spaceGroup.bd !== '');
};
var $author$project$Pages$SignUp$InputErrors = F4(
	function (name, email, orgName, password) {
		return {R: email, U: name, cL: orgName, aj: password};
	});
var $author$project$Pages$SignUp$validationErrorsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'password',
	$author$project$Validation$fieldErrorDecoder,
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'orgName',
		$author$project$Validation$fieldErrorDecoder,
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'email',
			$author$project$Validation$fieldErrorDecoder,
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'name',
				$author$project$Validation$fieldErrorDecoder,
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Pages$SignUp$InputErrors)))));
var $author$project$Pages$SignUp$update = F3(
	function (timezone, msg, model) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{U: name}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 1:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{R: email, a8: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				var password = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aj: password}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				var orgName = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cL: orgName}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 11:
				var howToOrganise = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ad: $elm$core$Maybe$Just(howToOrganise)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 17:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{_: 0, bo: false, aY: 1}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 4:
				var errors = $author$project$Pages$SignUp$getFieldErrors(model);
				var hasErrors = $author$project$Components$Form$anyHasErrors(
					_List_fromArray(
						[errors.U, errors.R, errors.cL]));
				var _v1 = function () {
					if (hasErrors) {
						return _Utils_Tuple2(3, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							1,
							A2($author$project$Pages$SignUp$postSignUp, timezone, model));
					}
				}();
				var status = _v1.a;
				var cmd = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{a$: status, bw: true}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 9:
				var res = msg.a;
				var _v3 = $author$project$HttpUtil$getCodeAndString(res);
				switch (_v3.a) {
					case 422:
						var body = _v3.b;
						var newModel = _Utils_update(
							model,
							{a$: 3});
						var _v4 = A2($elm$json$Json$Decode$decodeString, $author$project$Pages$SignUp$validationErrorsDecoder, body);
						if (!_v4.$) {
							var validationErrors = _v4.a;
							var _v5 = validationErrors.R;
							if (!_v5.$) {
								return _Utils_Tuple3(
									_Utils_update(
										newModel,
										{a8: true}),
									$elm$core$Platform$Cmd$none,
									$elm$core$Maybe$Nothing);
							} else {
								return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
							}
						} else {
							var error = _v4.a;
							return _Utils_Tuple3(
								model,
								A2($author$project$Errors$captureDecodeError, 'decode 222 sign up basics', error),
								$elm$core$Maybe$Nothing);
						}
					case 200:
						var body = _v3.b;
						var _v6 = A2($elm$json$Json$Decode$decodeString, $author$project$User$userDecoder, body);
						if (!_v6.$) {
							var user = _v6.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{aY: 1}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Just(
									$author$project$Pages$SignUp$GotUser(user)));
						} else {
							var error = _v6.a;
							return _Utils_Tuple3(
								model,
								A2($author$project$Errors$captureDecodeError, 'decode 200 sign up basics', error),
								$elm$core$Maybe$Nothing);
						}
					default:
						var code = _v3.a;
						return _Utils_Tuple3(
							model,
							A2(
								$author$project$Errors$captureErrorString,
								'sign up basics',
								$elm$core$String$fromInt(code)),
							$elm$core$Maybe$Nothing);
				}
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aY: 2}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 8:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aK: $elm$core$String$toInt(str)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 14:
				var nextLabel = 'Parking ' + $elm$core$String$fromInt(
					$elm$core$List$length(model.Z) + 1);
				var spaceGroups = A2(
					$elm$core$List$append,
					model.Z,
					_List_fromArray(
						[
							A2($author$project$Pages$SignUp$SpaceGroup, nextLabel, $elm$core$Maybe$Nothing)
						]));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Z: spaceGroups}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 15:
				var index = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							Z: A2($elm_community$list_extra$List$Extra$removeAt, index, model.Z)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 12:
				var index = msg.a;
				var label = msg.b;
				var _v7 = A2($elm_community$list_extra$List$Extra$getAt, index, model.Z);
				if (!_v7.$) {
					var spaceGroup = _v7.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								Z: A3(
									$elm_community$list_extra$List$Extra$setAt,
									index,
									_Utils_update(
										spaceGroup,
										{bd: label}),
									model.Z)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 13:
				var index = msg.a;
				var count = msg.b;
				var _v8 = A2($elm_community$list_extra$List$Extra$getAt, index, model.Z);
				if (!_v8.$) {
					var spaceGroup = _v8.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								Z: A3(
									$elm_community$list_extra$List$Extra$setAt,
									index,
									_Utils_update(
										spaceGroup,
										{
											bg: $elm$core$String$toInt(count)
										}),
									model.Z)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 6:
				var _v9 = function () {
					var _v10 = A2($elm$core$List$all, $author$project$Pages$SignUp$spaceGroupOk, model.Z);
					if (_v10) {
						return _Utils_Tuple2(
							$author$project$Pages$SignUp$postSpaceGroups(model),
							1);
					} else {
						return _Utils_Tuple2($elm$core$Platform$Cmd$none, 3);
					}
				}();
				var cmd = _v9.a;
				var status = _v9.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{_: status, bo: true}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{_: 1}),
					$author$project$Pages$SignUp$postSpaceGroups(model),
					$elm$core$Maybe$Nothing);
			case 16:
				var spacesResponse = msg.a;
				if (spacesResponse.$ === 1) {
					var err = spacesResponse.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$Errors$captureHttpError, 'init spaces response', err),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just($author$project$Pages$SignUp$Complete));
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$Pages$Users$AddPeopleClicked = {$: 13};
var $author$project$Pages$Users$ConfirmDelete = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$EditUser = {$: 0};
var $author$project$Pages$Users$GotError = {$: 12};
var $author$project$Pages$Users$GotUsers = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$ModalClosed = {$: 10};
var $author$project$Pages$Users$pushModal = F2(
	function (modal, model) {
		var cmd = function () {
			var _v0 = $elm$core$List$length(model.af) > 0;
			if (_v0) {
				return $author$project$Components$Modal$toggleScrollLockForModal(true);
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					af: A2($elm$core$List$cons, modal, model.af)
				}),
			cmd);
	});
var $author$project$Pages$Users$GotDeleteResponse = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$remoteDeleteUser = function (userId) {
	return $elm$http$Http$request(
		{
			dl: $elm$http$Http$emptyBody,
			dv: $elm$http$Http$expectWhatever($author$project$Pages$Users$GotDeleteResponse),
			dz: _List_Nil,
			dK: 'DELETE',
			d4: $elm$core$Maybe$Nothing,
			d7: $elm$core$Maybe$Nothing,
			d9: '/board/user/' + userId
		});
};
var $author$project$Pages$Users$GotSaveResponse = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$remotePutUser = function (model) {
	var user = {a0: model.ar, R: model.as, dC: model.a6, U: model.at, dU: '', cL: '', c9: model.aP};
	return $elm$http$Http$request(
		{
			dl: $elm$http$Http$jsonBody(
				$author$project$User$userEncoder(user)),
			dv: $jzxhuang$http_extras$Http$Detailed$expectString($author$project$Pages$Users$GotSaveResponse),
			dz: _List_Nil,
			dK: 'PUT',
			d4: $elm$core$Maybe$Nothing,
			d7: $elm$core$Maybe$Nothing,
			d9: '/board/user/' + user.dC
		});
};
var $author$project$Pages$Users$update = F3(
	function (allUsers, msg, model) {
		switch (msg.$) {
			case 0:
				var res = msg.a;
				if (!res.$) {
					var users = res.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aG: 2}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$Pages$Users$GotUsers(users)));
				} else {
					var error = res.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aG: 3}),
						A2($author$project$Errors$captureHttpError, 'get users on user page', error),
						$elm$core$Maybe$Just($author$project$Pages$Users$GotError));
				}
			case 1:
				var userId = msg.a;
				var _v2 = A2($elm$core$Dict$get, userId, allUsers);
				if (!_v2.$) {
					var user = _v2.a;
					var _v3 = A2(
						$author$project$Pages$Users$pushModal,
						$author$project$Pages$Users$EditUser,
						_Utils_update(
							model,
							{ar: user.a0, as: user.R, a6: user.dC, at: user.U, aP: user.c9, X: 0}));
					var model_ = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple3(model_, cmd, $elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 10:
				var modalStack = function () {
					var _v5 = $elm$core$List$tail(model.af);
					if (!_v5.$) {
						var l = _v5.a;
						return l;
					} else {
						return _List_Nil;
					}
				}();
				var cmd = function () {
					var _v4 = $elm$core$List$length(modalStack);
					if (!_v4) {
						return $author$project$Components$Modal$toggleScrollLockForModal(false);
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{af: modalStack}),
					cmd,
					$elm$core$Maybe$Nothing);
			case 13:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just($author$project$Pages$Users$AddPeopleClicked));
			case 2:
				var name = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{at: name}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{as: email}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 4:
				var isAdmin = msg.a;
				var userType = function () {
					if (isAdmin) {
						return 0;
					} else {
						return 1;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aP: userType}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 5:
				var canAssign = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ar: canAssign}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 9:
				return _Utils_Tuple3(
					model,
					$author$project$Util$send($author$project$Pages$Users$ModalClosed),
					$elm$core$Maybe$Nothing);
			case 6:
				var userId = msg.a;
				var _v7 = A2(
					$author$project$Pages$Users$pushModal,
					$author$project$Pages$Users$ConfirmDelete(userId),
					_Utils_update(
						model,
						{ap: 0}));
				var model_ = _v7.a;
				var cmd = _v7.b;
				return _Utils_Tuple3(model_, cmd, $elm$core$Maybe$Nothing);
			case 8:
				var nameError = $author$project$Validation$checkCannotBeBlank(model.at);
				var emailError = $author$project$Validation$checkValidEmail(model.as);
				var _v8 = $author$project$Components$Form$anyHasErrors(
					_List_fromArray(
						[nameError, emailError]));
				if (_v8) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{a5: emailError, a7: nameError, X: 3}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{X: 1}),
						$author$project$Pages$Users$remotePutUser(model),
						$elm$core$Maybe$Nothing);
				}
			case 14:
				var res = msg.a;
				var _v9 = $author$project$HttpUtil$getCodeAndString(res);
				switch (_v9.a) {
					case 200:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{X: 2}),
							$author$project$Util$send($author$project$Pages$Users$ModalClosed),
							$elm$core$Maybe$Nothing);
					case 422:
						var body = _v9.b;
						var _v10 = A2($elm$json$Json$Decode$decodeString, $author$project$User$userErrorsDecoder, body);
						if (!_v10.$) {
							var errors = _v10.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{a5: errors.bA, a7: errors.bF, X: 3}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							var unexpectedError = _v10.a;
							return _Utils_Tuple3(
								model,
								A2($author$project$Errors$captureDecodeError, 'save user 422', unexpectedError),
								$elm$core$Maybe$Nothing);
						}
					default:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{X: 3}),
							A2($author$project$Errors$captureErrorString, 'save user', 'unexpected http error'),
							$elm$core$Maybe$Nothing);
				}
			case 7:
				var userId = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ap: 1}),
					$author$project$Pages$Users$remoteDeleteUser(userId),
					$elm$core$Maybe$Nothing);
			case 15:
				var res = msg.a;
				if (!res.$) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{ap: 2, af: _List_Nil}),
						$author$project$Components$Modal$toggleScrollLockForModal(false),
						$elm$core$Maybe$Nothing);
				} else {
					var unexpectedError = res.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{ap: 3}),
						A2($author$project$Errors$captureHttpError, 'delete user', unexpectedError),
						$elm$core$Maybe$Nothing);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$Main$updateSession = F3(
	function (smsg, model, cmd) {
		var _v0 = function () {
			if (!smsg.$) {
				if (!smsg.a.$) {
					var user = smsg.a.a;
					return _Utils_Tuple2(
						$author$project$Session$LoggedIn(user),
						$elm$core$Platform$Cmd$none);
				} else {
					var _v2 = smsg.a;
					return _Utils_Tuple2(
						$author$project$Session$Guest,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.K,
							$author$project$Routes$path($author$project$Routes$loginPath)));
				}
			} else {
				return _Utils_Tuple2(model.o, $elm$core$Platform$Cmd$none);
			}
		}();
		var session = _v0.a;
		var extraCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{o: session}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, extraCmd])));
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.i);
		_v0$24:
		while (true) {
			switch (_v0.a.$) {
				case 9:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.K,
								$elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 8:
					var maybeRoute = _v0.a.a;
					return A2($author$project$Main$setNewPage, maybeRoute, model);
				case 17:
					var modal = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								af: A2($author$project$Components$Modal$pushModalStack, modal, model.af)
							}),
						$author$project$Components$Modal$toggleScrollLockForModal(true));
				case 16:
					var _v2 = _v0.a;
					return $author$project$Main$modalClosed(model);
				case 18:
					var _v3 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aJ: $author$project$AddUsersModal$init}),
						$author$project$Util$send(
							$author$project$Main$ModalOpened(0)));
				case 21:
					var _v4 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: $author$project$Session$Guest, z: $elm$core$Dict$empty}),
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.K,
							$author$project$Routes$path($author$project$Routes$loginPath)));
				case 22:
					var res = _v0.a.a;
					if (!res.$) {
						var user = res.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									o: $author$project$Session$LoggedIn(user)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 0:
					if (_v0.b.$ === 1) {
						var loginMsg = _v0.a.a;
						var loginModel = _v0.b.a;
						var _v6 = A2($author$project$Pages$Login$update, loginMsg, loginModel);
						var updatedLoginModel = _v6.a;
						var loginCmd = _v6.b;
						var subMsg = _v6.c;
						var sessionMsg = function () {
							if ((!subMsg.$) && (subMsg.a.$ === 5)) {
								var user = subMsg.a.a;
								return $elm$core$Maybe$Just(
									$author$project$Main$SessionUpdated(user));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}();
						var updatedModel = _Utils_update(
							model,
							{
								i: $author$project$Main$LoginPage(updatedLoginModel)
							});
						return A3(
							$author$project$Main$updateSession,
							sessionMsg,
							updatedModel,
							A2($elm$core$Platform$Cmd$map, $author$project$Main$LoginPageMsg, loginCmd));
					} else {
						break _v0$24;
					}
				case 1:
					if (!_v0.b.$) {
						var boardMsg = _v0.a.a;
						var boardModel = _v0.b.a;
						var _v8 = A2($author$project$Pages$Board$update, boardMsg, boardModel);
						var updatedBoardModel = _v8.a;
						var boardCmd = _v8.b;
						var subMsg = _v8.c;
						var _v9 = function () {
							_v10$2:
							while (true) {
								if (!subMsg.$) {
									switch (subMsg.a.$) {
										case 0:
											var users = subMsg.a.a;
											return _Utils_Tuple2(
												A2($author$project$Main$loadUsers, users, model),
												$elm$core$Platform$Cmd$none);
										case 1:
											var _v11 = subMsg.a;
											return _Utils_Tuple2(
												model,
												$author$project$Util$send($author$project$Main$AddPeopleClicked));
										default:
											break _v10$2;
									}
								} else {
									break _v10$2;
								}
							}
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}();
						var modelWithSubMsg = _v9.a;
						var cmd = _v9.b;
						var updatedModel = _Utils_update(
							modelWithSubMsg,
							{
								i: $author$project$Main$BoardPage(updatedBoardModel)
							});
						return _Utils_Tuple2(
							updatedModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$Main$BoardPageMsg, boardCmd),
										cmd
									])));
					} else {
						break _v0$24;
					}
				case 2:
					if (_v0.b.$ === 2) {
						var signUpMsg = _v0.a.a;
						var signUpModel = _v0.b.a;
						var _v12 = A3($author$project$Pages$SignUp$update, model.aq.d5, signUpMsg, signUpModel);
						var updatedSignUpModel = _v12.a;
						var signUpCmd = _v12.b;
						var outMsg = _v12.c;
						var _v13 = function () {
							_v14$2:
							while (true) {
								if (!outMsg.$) {
									switch (outMsg.a.$) {
										case 10:
											var user = outMsg.a.a;
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														o: $author$project$Session$LoggedIn(user)
													}),
												$elm$core$Platform$Cmd$none);
										case 18:
											var _v15 = outMsg.a;
											return _Utils_Tuple2(
												model,
												A2(
													$elm$browser$Browser$Navigation$pushUrl,
													model.K,
													$author$project$Routes$path($author$project$Routes$boardPath)));
										default:
											break _v14$2;
									}
								} else {
									break _v14$2;
								}
							}
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}();
						var modelWithOutMsg = _v13.a;
						var cmd = _v13.b;
						var updatedModel = _Utils_update(
							modelWithOutMsg,
							{
								i: $author$project$Main$SignUpPage(updatedSignUpModel)
							});
						return _Utils_Tuple2(
							updatedModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$Main$SignUpPageMsg, signUpCmd),
										cmd
									])));
					} else {
						break _v0$24;
					}
				case 3:
					if (_v0.b.$ === 3) {
						var editorMsg = _v0.a.a;
						var editorModel = _v0.b.a;
						var _v16 = A2($author$project$Pages$Editor$update, editorMsg, editorModel);
						var updatedEditorModel = _v16.a;
						var editorCmd = _v16.b;
						var updatedModel = _Utils_update(
							model,
							{
								i: $author$project$Main$EditorPage(updatedEditorModel)
							});
						return _Utils_Tuple2(
							updatedModel,
							A2($elm$core$Platform$Cmd$map, $author$project$Main$EditorPageMsg, editorCmd));
					} else {
						break _v0$24;
					}
				case 4:
					if (_v0.b.$ === 4) {
						var usersMsg = _v0.a.a;
						var usersModel = _v0.b.a;
						var _v17 = A3($author$project$Pages$Users$update, model.z, usersMsg, usersModel);
						var updatedUserModel = _v17.a;
						var userCmd = _v17.b;
						var subMsg = _v17.c;
						var _v18 = function () {
							_v19$2:
							while (true) {
								if (!subMsg.$) {
									switch (subMsg.a.$) {
										case 11:
											var users = subMsg.a.a;
											return _Utils_Tuple2(
												A2($author$project$Main$loadUsers, users, model),
												$elm$core$Platform$Cmd$none);
										case 13:
											var _v20 = subMsg.a;
											return _Utils_Tuple2(
												model,
												$author$project$Util$send($author$project$Main$AddPeopleClicked));
										default:
											break _v19$2;
									}
								} else {
									break _v19$2;
								}
							}
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}();
						var modelWithSubMsg = _v18.a;
						var cmd = _v18.b;
						var updatedModel = _Utils_update(
							modelWithSubMsg,
							{
								i: $author$project$Main$UsersPage(updatedUserModel)
							});
						return _Utils_Tuple2(
							updatedModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$Main$UsersPageMsg, userCmd),
										cmd
									])));
					} else {
						break _v0$24;
					}
				case 5:
					if (_v0.b.$ === 5) {
						var profileMsg = _v0.a.a;
						var profileModel = _v0.b.a;
						var _v21 = A2($author$project$Pages$Profile$update, profileMsg, profileModel);
						var updatedProfileModel = _v21.a;
						var cmd = _v21.b;
						var subMsg = _v21.c;
						var updatedModel = _Utils_update(
							model,
							{
								i: $author$project$Main$ProfilePage(updatedProfileModel)
							});
						return _Utils_Tuple2(
							updatedModel,
							A2($elm$core$Platform$Cmd$map, $author$project$Main$ProfilePageMsg, cmd));
					} else {
						break _v0$24;
					}
				case 6:
					var addUsersModalMsg = _v0.a.a;
					var _v22 = A2($author$project$AddUsersModal$update, addUsersModalMsg, model.aJ);
					var updatedAddUsersModel = _v22.a;
					var addUsersCmd = _v22.b;
					var subMsg = _v22.c;
					var _v23 = function () {
						_v24$3:
						while (true) {
							if (!subMsg.$) {
								switch (subMsg.a.$) {
									case 14:
										var users = subMsg.a.a;
										return _Utils_Tuple2(
											A2($author$project$Main$loadUsers, users, model),
											$elm$core$Platform$Cmd$none);
									case 15:
										var users = subMsg.a.a;
										return $author$project$Main$modalClosed(
											A2($author$project$Main$loadUsers, users, model));
									case 13:
										var _v25 = subMsg.a;
										return $author$project$Main$modalClosed(model);
									default:
										break _v24$3;
								}
							} else {
								break _v24$3;
							}
						}
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}();
					var modelWithSubMsg = _v23.a;
					var cmd = _v23.b;
					var updatedModel = _Utils_update(
						modelWithSubMsg,
						{aJ: updatedAddUsersModel});
					return _Utils_Tuple2(
						updatedModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Main$AddUsersModalMsg, addUsersCmd),
									cmd
								])));
				case 7:
					var contactSupportMsg = _v0.a.a;
					var _v26 = A2($author$project$ContactSupportModal$update, contactSupportMsg, model.aN);
					var updatedContactSupport = _v26.a;
					var contactSupportCmd = _v26.b;
					var subMsg = _v26.c;
					var _v27 = function () {
						if ((!subMsg.$) && (subMsg.a.$ === 2)) {
							var _v29 = subMsg.a;
							return $author$project$Main$modalClosed(model);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var modelWithSubMsg = _v27.a;
					var cmd = _v27.b;
					return _Utils_Tuple2(
						_Utils_update(
							modelWithSubMsg,
							{aN: updatedContactSupport}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									A2($elm$core$Platform$Cmd$map, $author$project$Main$ContactSupportModalMsg, contactSupportCmd)
								])));
				case 10:
					var isTouchDevice_ = _v0.a.a;
					var device = model.aq;
					var newDevice = _Utils_update(
						device,
						{dH: isTouchDevice_});
					var updatedModel = _Utils_update(
						model,
						{aq: newDevice});
					return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
				case 13:
					var toggled = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ae: toggled}),
						$elm$core$Platform$Cmd$none);
				case 15:
					var _v30 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ae: $elm$core$Maybe$Nothing, o: $author$project$Session$Guest}),
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.K,
							$author$project$Routes$path($author$project$Routes$loginPath)));
				case 14:
					var urlPath = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ae: $elm$core$Maybe$Just(false)
							}),
						A2($elm$browser$Browser$Navigation$pushUrl, model.K, urlPath));
				case 19:
					var _v31 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aN: $author$project$ContactSupportModal$init,
								ae: $elm$core$Maybe$Just(false),
								af: A2($author$project$Components$Modal$pushModalStack, 1, model.af)
							}),
						$elm$core$Platform$Cmd$none);
				case 20:
					var str = _v0.a.a;
					var _v32 = A2($elm$json$Json$Decode$decodeString, $author$project$DbMessage$boardUpdateMsgDecoder, str);
					if (!_v32.$) {
						var m = _v32.a;
						var _v33 = m.dJ.dO;
						if (_v33 === 1) {
							var _v34 = $author$project$DbMessage$isUpdateOrInsert(m.dJ.dn);
							if (_v34) {
								var _v35 = A2($elm$json$Json$Decode$decodeString, $author$project$DbMessage$boardUpdateMsgUserChangeDecoder, str);
								if (!_v35.$) {
									var um = _v35.a;
									var users = A3($elm$core$Dict$insert, um.dX.dC, um.dX, model.z);
									var updatedSession = function () {
										var _v36 = model.o;
										if (_v36.$ === 1) {
											var user = _v36.a;
											var _v37 = _Utils_eq(user.dC, um.dX.dC);
											if (_v37) {
												return $author$project$Session$LoggedIn(um.dX);
											} else {
												return model.o;
											}
										} else {
											return model.o;
										}
									}();
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{o: updatedSession, z: users}),
										$elm$core$Platform$Cmd$none);
								} else {
									var payloadDecodeErr = _v35.a;
									return _Utils_Tuple2(
										model,
										A2($author$project$Errors$captureDecodeError, 'board update user message', payloadDecodeErr));
								}
							} else {
								var users = A2($elm$core$Dict$remove, m.dJ.dC, model.z);
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{z: users}),
									$elm$core$Platform$Cmd$none);
							}
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 11:
					var _v38 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bt: true}),
						$elm$core$Platform$Cmd$none);
				case 12:
					var _v39 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bt: false}),
						$elm$core$Platform$Cmd$none);
				case 23:
					var _v40 = _v0.a;
					return _Utils_Tuple2(
						model,
						A2($elm$browser$Browser$Navigation$pushUrl, model.K, '/'));
				default:
					break _v0$24;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Pages$Board$FutureDaysAdded = {$: 5};
var $author$project$Pages$Board$PastDaysAdded = {$: 6};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Pages$Board$DayCollapsedToggled = function (a) {
	return {$: 25, a: a};
};
var $author$project$Pages$Board$emptyDiv = A2($elm$html$Html$div, _List_Nil, _List_Nil);
var $author$project$Pages$Board$AssignableSpace = F4(
	function (space, assignment, users, status) {
		return {e: assignment, bK: space, aG: status, z: users};
	});
var $author$project$Pages$Board$Assigned = 0;
var $author$project$Pages$Board$Available = 1;
var $author$project$Pages$Board$Highlight = 2;
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Pages$Board$getAssignableSpace = F4(
	function (userDict, day, space, model) {
		var users = $elm$core$Dict$values(userDict);
		var searchedUserId = function () {
			var _v4 = model.al;
			if (_v4.$ === 1) {
				return 'blank';
			} else {
				var u = _v4.a;
				return u;
			}
		}();
		var assignment = A2(
			$elm$core$Dict$get,
			_Utils_Tuple2(day, space.dC),
			model.d);
		var highlight = function () {
			if (!assignment.$) {
				var a = assignment.a;
				var _v3 = a.j;
				if (!_v3.$) {
					var userId = _v3.a;
					return _Utils_eq(userId, searchedUserId);
				} else {
					return searchedUserId === '';
				}
			} else {
				return false;
			}
		}();
		var status = function () {
			var _v0 = _Utils_Tuple2(assignment, highlight);
			_v0$0:
			while (true) {
				if (_v0.a.$ === 1) {
					if (_v0.b) {
						break _v0$0;
					} else {
						var _v1 = _v0.a;
						return 1;
					}
				} else {
					if (_v0.b) {
						break _v0$0;
					} else {
						return 0;
					}
				}
			}
			return 2;
		}();
		return A4($author$project$Pages$Board$AssignableSpace, space, assignment, users, status);
	});
var $author$project$Pages$Board$dayOfWeekToString = function (weekDay) {
	switch (weekDay) {
		case 6:
			return 'Sun';
		case 0:
			return 'Mon';
		case 1:
			return 'Tue';
		case 2:
			return 'Wed';
		case 3:
			return 'Thu';
		case 4:
			return 'Fri';
		default:
			return 'Sat';
	}
};
var $author$project$Pages$Board$monthToString = function (month) {
	switch (month) {
		case 0:
			return 'Jan';
		case 1:
			return 'Feb';
		case 2:
			return 'Mar';
		case 3:
			return 'Apr';
		case 4:
			return 'May';
		case 5:
			return 'Jun';
		case 6:
			return 'Jul';
		case 7:
			return 'Aug';
		case 8:
			return 'Sep';
		case 9:
			return 'Oct';
		case 10:
			return 'Nov';
		default:
			return 'Dec';
	}
};
var $author$project$Pages$Board$prettyDayFromDate = F2(
	function (date, _short) {
		var year = function () {
			if (_short) {
				return '';
			} else {
				return $elm$core$String$fromInt(
					$justinmimbs$date$Date$year(date));
			}
		}();
		var month = $author$project$Pages$Board$monthToString(
			$justinmimbs$date$Date$month(date));
		var dayOfWeek = $author$project$Pages$Board$dayOfWeekToString(
			$justinmimbs$date$Date$weekday(date));
		return dayOfWeek + (' ' + ($elm$core$String$fromInt(
			$justinmimbs$date$Date$day(date)) + (' ' + (month + (' ' + year)))));
	});
var $author$project$Pages$Board$prettyDay = function (day) {
	var d = $justinmimbs$date$Date$fromIsoString(day);
	var dateString = function () {
		if (!d.$) {
			var date = d.a;
			return A2($author$project$Pages$Board$prettyDayFromDate, date, false);
		} else {
			return day;
		}
	}();
	return dateString;
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Components$Icon$viewCollapsedIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-chevron-up')
		]),
	_List_Nil);
var $author$project$Components$Icon$viewExpandedIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-chevron-down')
		]),
	_List_Nil);
var $author$project$Pages$Board$ModalOpened = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$User$canAssignSpace = function (user) {
	var _v0 = _Utils_Tuple2(user.a0, !user.c9);
	if (_v0.a) {
		return true;
	} else {
		if (_v0.b) {
			return true;
		} else {
			return false;
		}
	}
};
var $author$project$Pages$Board$getAssignmentUsersName = F2(
	function (users, assignment) {
		var _v0 = assignment.j;
		if (!_v0.$) {
			var userId = _v0.a;
			var _v1 = A2($elm$core$Dict$get, userId, users);
			if (!_v1.$) {
				var u = _v1.a;
				return u.U;
			} else {
				return 'Unknown';
			}
		} else {
			return 'Visitor';
		}
	});
var $author$project$Pages$Board$getAssignmentName = F2(
	function (users, assignment) {
		if (assignment.$ === 1) {
			return '';
		} else {
			var a = assignment.a;
			return A2($author$project$Pages$Board$getAssignmentUsersName, users, a);
		}
	});
var $author$project$Pages$Board$getAssignmentUser = F2(
	function (users, maybeAssignment) {
		if (!maybeAssignment.$) {
			var assignment = maybeAssignment.a;
			var _v1 = assignment.j;
			if (!_v1.$) {
				var userId = _v1.a;
				var _v2 = A2($elm$core$Dict$get, userId, users);
				if (!_v2.$) {
					var u = _v2.a;
					return $elm$core$Maybe$Just(u);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Components$Icon$viewAssignIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-plus')
		]),
	_List_Nil);
var $author$project$Pages$Board$viewSpace = F4(
	function (user, users, assignableSpace, day) {
		var spaceAttrs = function () {
			var _v6 = $author$project$User$canAssignSpace(user);
			if (_v6) {
				return _List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						A2(
							$author$project$Pages$Board$ModalOpened,
							false,
							$author$project$Pages$Board$Assign(
								_Utils_Tuple2(day, assignableSpace.bK)))),
						$elm$html$Html$Attributes$class('clickable')
					]);
			} else {
				return _List_Nil;
			}
		}();
		var maybeAssignedUser = A2($author$project$Pages$Board$getAssignmentUser, users, assignableSpace.e);
		var viewingUserIsAssigned = function () {
			if (!maybeAssignedUser.$) {
				var au = maybeAssignedUser.a;
				return _Utils_eq(au.dC, user.dC);
			} else {
				return false;
			}
		}();
		var spaceClass = function () {
			var _v1 = _Utils_Tuple2(assignableSpace.aG, viewingUserIsAssigned);
			_v1$0:
			while (true) {
				switch (_v1.a) {
					case 1:
						if (_v1.b) {
							break _v1$0;
						} else {
							var _v2 = _v1.a;
							return 'available';
						}
					case 0:
						if (_v1.b) {
							break _v1$0;
						} else {
							var _v3 = _v1.a;
							return 'bookedOther booked';
						}
					default:
						if (_v1.b) {
							break _v1$0;
						} else {
							var _v4 = _v1.a;
							return 'highlight';
						}
				}
			}
			return 'highlightMe';
		}();
		var assignedName = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('assignedName')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Pages$Board$getAssignmentName, users, assignableSpace.e))
				]));
		var bookableContent = function () {
			var _v0 = assignableSpace.aG;
			if (_v0 === 1) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('assignIcon')
						]),
					_List_fromArray(
						[$author$project$Components$Icon$viewAssignIcon]));
			} else {
				return assignedName;
			}
		}();
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('space'),
						$elm$html$Html$Attributes$class(spaceClass)
					]),
				spaceAttrs),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(assignableSpace.bK.bd)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_List_fromArray(
						[bookableContent]))
				]));
	});
var $author$project$Pages$Board$viewSpaceGroup = F5(
	function (user, users, day, spaceGroup, bookableSpaces) {
		var spaces = A2(
			$elm$core$List$map,
			function (bs) {
				return A4($author$project$Pages$Board$viewSpace, user, users, bs, day);
			},
			bookableSpaces);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('spaceGroup')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('spaceGroupLabel')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(spaceGroup.bd)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('spaces')
						]),
					spaces)
				]));
	});
var $author$project$Pages$Board$viewDay = F4(
	function (user, users, day, model) {
		var toggleCollapsed = $elm$html$Html$Events$onClick(
			$author$project$Pages$Board$DayCollapsedToggled(day));
		var isCollapsed = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Dict$get, day, model.an));
		var groupsWithBookableSpaces = A2(
			$elm$core$List$map,
			function (sg) {
				var bookable = A2(
					$elm$core$List$map,
					function (space) {
						return A4($author$project$Pages$Board$getAssignableSpace, users, day, space, model);
					},
					sg.bn);
				return _Utils_Tuple2(sg, bookable);
			},
			model.Z);
		var spaceGroupViews = A2(
			$elm$core$List$map,
			function (gwbs) {
				return A5($author$project$Pages$Board$viewSpaceGroup, user, users, day, gwbs.a, gwbs.b);
			},
			groupsWithBookableSpaces);
		var expandedStateIcon = function () {
			if (isCollapsed) {
				return $author$project$Components$Icon$viewCollapsedIcon;
			} else {
				return $author$project$Components$Icon$viewExpandedIcon;
			}
		}();
		var currentUserAssignments = A2(
			$elm$core$List$filter,
			function (bs) {
				var _v1 = model.al;
				if (_v1.$ === 1) {
					return false;
				} else {
					var searchedUserId = _v1.a;
					var _v2 = bs.e;
					if (!_v2.$) {
						var assignment = _v2.a;
						var _v3 = assignment.j;
						if (!_v3.$) {
							var userId = _v3.a;
							return _Utils_eq(userId, searchedUserId);
						} else {
							return false;
						}
					} else {
						return false;
					}
				}
			},
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					function (gwbs) {
						return gwbs.b;
					},
					groupsWithBookableSpaces)));
		var currentUserHasAssignment = $elm$core$List$length(currentUserAssignments) > 0;
		var content = function () {
			if (!isCollapsed) {
				return spaceGroupViews;
			} else {
				return _List_fromArray(
					[$author$project$Pages$Board$emptyDiv]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('day'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('hasAssignment', currentUserHasAssignment)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('day-' + day),
							$elm$html$Html$Attributes$class('dayHeader'),
							toggleCollapsed
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Pages$Board$prettyDay(day)),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('expandedStateIcon')
								]),
							_List_fromArray(
								[expandedStateIcon]))
						])),
					A2($elm$html$Html$div, _List_Nil, content)
				]));
	});
var $author$project$Pages$Board$viewDays = F3(
	function (user, users, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (day) {
					return A4($author$project$Pages$Board$viewDay, user, users, day, model);
				},
				model.bz));
	});
var $author$project$Components$Spinner$viewSpinner = function (size) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('progress'),
				$elm$html$Html$Attributes$class(size)
			]),
		_List_fromArray(
			[
				A2($elm$html$Html$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Components$Spinner$viewLargeSpinner = $author$project$Components$Spinner$viewSpinner('large');
var $author$project$Components$Spinner$viewSmallSpinner = $author$project$Components$Spinner$viewSpinner('small');
var $author$project$Pages$Board$viewBoard = F3(
	function (user, users, model) {
		var loadMoreDaysSpinner = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('loadMoreDays')
				]),
			_List_fromArray(
				[$author$project$Components$Spinner$viewSmallSpinner]));
		var pastDaysCTA = function () {
			var _v3 = model.u;
			if (_v3 === 1) {
				return loadMoreDaysSpinner;
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Board$PastDaysAdded),
							$elm$html$Html$Attributes$class('a loadMoreDaysCTA')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Load past days')
						]));
			}
		}();
		var futureDaysCTA = function () {
			var _v2 = model.u;
			if (!_v2) {
				return loadMoreDaysSpinner;
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Board$FutureDaysAdded),
							$elm$html$Html$Attributes$class('a loadMoreDaysCTA')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Load future days')
						]));
			}
		}();
		var _v0 = model.u;
		if (_v0 === 3) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pagePending')
					]),
				_List_fromArray(
					[$author$project$Components$Spinner$viewLargeSpinner]));
		} else {
			var _v1 = !$elm$core$List$length(model.Z);
			if (_v1) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('createSpaceGroupsCTA')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href(
									$author$project$Routes$path($author$project$Routes$editorPath))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Create Space Groups')
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('board'),
							$elm$html$Html$Attributes$class('boardView')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									pastDaysCTA,
									A3($author$project$Pages$Board$viewDays, user, users, model)
								])),
							A2($elm$html$Html$hr, _List_Nil, _List_Nil),
							futureDaysCTA
						]));
			}
		}
	});
var $author$project$Pages$Board$AddPeopleClicked = {$: 36};
var $author$project$Pages$Board$HistoryClicked = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$Pages$Board$ModalParts = F5(
	function (_class, title, header, content, footer) {
		return {ci: _class, by: content, cw: footer, cz: header, d6: title};
	});
var $author$project$Pages$Board$NoOp = {$: 40};
var $author$project$Pages$Board$SpaceAssigned = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Pages$Board$SpaceUnassigned = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Components$Button$Regular = 1;
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Components$Icon$viewTickIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-checkmark')
		]),
	_List_Nil);
var $author$project$Components$Button$viewRawButton = F5(
	function (size, status, extraClasses, buttonContent, attributes) {
		var statusIcon = function () {
			switch (status) {
				case 3:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('errorIcon')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('!')
							]));
				case 2:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('successIcon')
							]),
						_List_fromArray(
							[$author$project$Components$Icon$viewTickIcon]));
				default:
					return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var spinnerClasses = $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('buttonSpinner', true),
					_Utils_Tuple2('show', status === 1)
				]));
		var sizeClass = function () {
			if (!size) {
				return 'small';
			} else {
				return '';
			}
		}();
		var buttonClasses = $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('button', true),
					_Utils_Tuple2('pending', status === 1),
					_Utils_Tuple2(sizeClass, true),
					_Utils_Tuple2(extraClasses, true)
				]));
		return A2(
			$elm$html$Html$button,
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[buttonClasses]),
						attributes
					])),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('buttonContent')
						]),
					buttonContent),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[spinnerClasses]),
					_List_fromArray(
						[$author$project$Components$Spinner$viewSmallSpinner])),
					statusIcon
				]));
	});
var $author$project$Components$Button$viewCancelButton = F2(
	function (status, attributes) {
		return A5(
			$author$project$Components$Button$viewRawButton,
			1,
			status,
			'cancel',
			_List_fromArray(
				[
					$elm$html$Html$text('Cancel')
				]),
			attributes);
	});
var $author$project$Pages$Board$modalCancelButton = A2(
	$author$project$Components$Button$viewCancelButton,
	0,
	_List_fromArray(
		[
			$elm$html$Html$Events$onClick($author$project$Pages$Board$ModalClosed)
		]));
var $author$project$Components$Button$viewCloseButton = F2(
	function (status, attributes) {
		return A5(
			$author$project$Components$Button$viewRawButton,
			1,
			status,
			'cancel',
			_List_fromArray(
				[
					$elm$html$Html$text('Close')
				]),
			attributes);
	});
var $author$project$Pages$Board$modalCloseButton = A2(
	$author$project$Components$Button$viewCloseButton,
	0,
	_List_fromArray(
		[
			$elm$html$Html$Events$onClick($author$project$Pages$Board$ModalClosed)
		]));
var $author$project$Pages$Board$cancelOrCloseButton = function (cancel) {
	if (cancel) {
		return $author$project$Pages$Board$modalCancelButton;
	} else {
		return $author$project$Pages$Board$modalCloseButton;
	}
};
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Components$Icon$viewAddPeopleIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon addPeopleIcon icon-enter')
		]),
	_List_Nil);
var $author$project$Components$Icon$viewAssignedIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-checkmark')
		]),
	_List_Nil);
var $author$project$Components$Button$viewButton = F3(
	function (status, attributes, buttonContent) {
		return A5($author$project$Components$Button$viewRawButton, 1, status, '', buttonContent, attributes);
	});
var $author$project$Components$Icon$viewHistoryIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-article')
		]),
	_List_Nil);
var $author$project$Components$Icon$viewReassignIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-document-edit')
		]),
	_List_Nil);
var $author$project$Components$Icon$viewRepeatIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-clockwise')
		]),
	_List_Nil);
var $author$project$Components$Button$Small = 0;
var $author$project$Components$Button$viewSmallButton = F3(
	function (status, attributes, buttonContent) {
		return A5($author$project$Components$Button$viewRawButton, 0, status, '', buttonContent, attributes);
	});
var $author$project$Components$Icon$viewUnAssignIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-wrong')
		]),
	_List_Nil);
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Pages$Board$AssignedUserSelected = function (a) {
	return {$: 22, a: a};
};
var $author$project$Pages$Board$viewUserItem = F2(
	function (user, model) {
		var selected = function () {
			var _v1 = model.aF;
			if (_v1.$ === 1) {
				return false;
			} else {
				var u = _v1.a;
				return _Utils_eq(u, user);
			}
		}();
		var name = function () {
			var _v0 = _Utils_eq(user, $author$project$User$visitorPlaceholderUser);
			if (_v0) {
				return 'Visitor';
			} else {
				return user.U;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('userItem'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('selected', selected)
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Pages$Board$AssignedUserSelected(user))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(name)
				]));
	});
var $author$project$Pages$Board$viewUserItemGroup = F4(
	function (label, users, extraClass, model) {
		var _v0 = $elm$core$List$length(users);
		if (!_v0) {
			return $author$project$Pages$Board$emptyDiv;
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('userSection'),
						$elm$html$Html$Attributes$class(extraClass)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('subTitle')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(label)
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (u) {
								return A2($author$project$Pages$Board$viewUserItem, u, model);
							},
							users))
					]));
		}
	});
var $author$project$Pages$Board$viewUserList = F5(
	function (userDict, day, space, assignment, model) {
		var daysAssignments = A2(
			$elm$core$List$filter,
			function (b) {
				return _Utils_eq(b.g, day);
			},
			$elm$core$Dict$values(model.d));
		var assignedUserIds = A2(
			$elm$core$List$filterMap,
			function ($) {
				return $.j;
			},
			daysAssignments);
		var allUsers = A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.U;
			},
			$elm$core$Dict$values(userDict));
		var _v0 = function () {
			if (assignment.$ === 1) {
				return _Utils_Tuple2($elm$core$Maybe$Nothing, false);
			} else {
				var a = assignment.a;
				var _v2 = a.j;
				if (_v2.$ === 1) {
					return _Utils_Tuple2(a.j, true);
				} else {
					return _Utils_Tuple2(a.j, false);
				}
			}
		}();
		var assigned = _v0.a;
		var isVisitor = _v0.b;
		var users = function () {
			if (assigned.$ === 1) {
				return allUsers;
			} else {
				var a = assigned.a;
				return A2(
					$elm$core$List$filter,
					function (u) {
						return !_Utils_eq(u.dC, a);
					},
					allUsers);
			}
		}();
		var _v3 = A2(
			$elm$core$List$partition,
			function (u) {
				return A2($elm$core$List$member, u, assignedUserIds);
			},
			A2(
				$elm$core$List$map,
				function ($) {
					return $.dC;
				},
				users));
		var assignedToOtherSpaceUserIds = _v3.a;
		var unassignedUserIds = _v3.b;
		var assignedToOtherSpace = A2(
			$elm$core$List$filter,
			function (u) {
				return A2($elm$core$List$member, u.dC, assignedToOtherSpaceUserIds);
			},
			allUsers);
		var assignedToOtherSpaceList = A4($author$project$Pages$Board$viewUserItemGroup, 'Already has a space:', assignedToOtherSpace, '', model);
		var unassigned = A2(
			$elm$core$List$filter,
			function (u) {
				return A2($elm$core$List$member, u.dC, unassignedUserIds);
			},
			allUsers);
		var unassignedList = A4($author$project$Pages$Board$viewUserItemGroup, 'Does not have space:', unassigned, '', model);
		var visitor = function () {
			if (isVisitor) {
				return $author$project$Pages$Board$emptyDiv;
			} else {
				return A4(
					$author$project$Pages$Board$viewUserItemGroup,
					'Visitor:',
					_List_fromArray(
						[$author$project$User$visitorPlaceholderUser]),
					'currentAssigned',
					model);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('userList')
				]),
			_List_fromArray(
				[visitor, unassignedList, assignedToOtherSpaceList]));
	});
var $author$project$Pages$Board$getAssignModalParts = F4(
	function (users, model, day, space) {
		var maybeAssignment = A2(
			$elm$core$Dict$get,
			_Utils_Tuple2(day, space.dC),
			model.d);
		var historyButton = A3(
			$author$project$Components$Button$viewSmallButton,
			0,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					A2($author$project$Pages$Board$HistoryClicked, day, space))
				]),
			_List_fromArray(
				[
					$author$project$Components$Icon$viewHistoryIcon,
					$elm$html$Html$text('history')
				]));
		var footer = function () {
			var _v1 = function () {
				var _v2 = model.aF;
				if (!_v2.$) {
					var selectedUser = _v2.a;
					var action = $elm$html$Html$Events$onClick(
						A3($author$project$Pages$Board$SpaceAssigned, day, space, selectedUser));
					var reassign = _Utils_Tuple3(
						true,
						_List_fromArray(
							[
								$author$project$Components$Icon$viewReassignIcon,
								$elm$html$Html$text('Reassign to ' + selectedUser.U)
							]),
						action);
					if (!maybeAssignment.$) {
						var a = maybeAssignment.a;
						var _v4 = a.j;
						if (!_v4.$) {
							var assignedUserId = _v4.a;
							var _v5 = _Utils_eq(assignedUserId, selectedUser.dC);
							if (!_v5) {
								return reassign;
							} else {
								return _Utils_Tuple3(
									false,
									_List_fromArray(
										[$author$project$Pages$Board$emptyDiv]),
									$elm$html$Html$Events$onClick($author$project$Pages$Board$NoOp));
							}
						} else {
							var _v6 = _Utils_eq(selectedUser, $author$project$User$visitorPlaceholderUser);
							if (!_v6) {
								return reassign;
							} else {
								return _Utils_Tuple3(
									false,
									_List_fromArray(
										[$author$project$Pages$Board$emptyDiv]),
									$elm$html$Html$Events$onClick($author$project$Pages$Board$NoOp));
							}
						}
					} else {
						return _Utils_Tuple3(
							true,
							_List_fromArray(
								[
									$author$project$Components$Icon$viewAssignIcon,
									$elm$html$Html$text('Assign ' + selectedUser.U)
								]),
							action);
					}
				} else {
					return _Utils_Tuple3(
						false,
						_List_fromArray(
							[$author$project$Pages$Board$emptyDiv]),
						$elm$html$Html$Events$onClick($author$project$Pages$Board$NoOp));
				}
			}();
			var canSubmit = _v1.a;
			var buttonContent = _v1.b;
			var buttonAction = _v1.c;
			var submitButton = function () {
				if (canSubmit) {
					return A3(
						$author$project$Components$Button$viewButton,
						0,
						_List_fromArray(
							[
								buttonAction,
								$elm$html$Html$Attributes$disabled(!canSubmit)
							]),
						buttonContent);
				} else {
					return $author$project$Pages$Board$emptyDiv;
				}
			}();
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('buttons')
					]),
				_List_fromArray(
					[
						submitButton,
						$author$project$Pages$Board$cancelOrCloseButton(canSubmit)
					]));
		}();
		var content = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A5($author$project$Pages$Board$viewUserList, users, day, space, maybeAssignment, model)
				]));
		var addPeople = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('rhs')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Components$Button$viewSmallButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Board$AddPeopleClicked)
						]),
					_List_fromArray(
						[
							$author$project$Components$Icon$viewAddPeopleIcon,
							$elm$html$Html$text('Add People')
						]))
				]));
		var headerContent = function () {
			if (maybeAssignment.$ === 1) {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('actions')
							]),
						_List_fromArray(
							[historyButton, addPeople]))
					]);
			} else {
				var a = maybeAssignment.a;
				var currentAssignmentName = A2($author$project$Pages$Board$getAssignmentUsersName, users, a);
				var currentAssignment = A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('currentAssignment')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(currentAssignmentName),
							$author$project$Components$Icon$viewAssignedIcon
						]));
				var buttons = A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('assignActions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('left')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											A2(
												$author$project$Pages$Board$ModalOpened,
												false,
												$author$project$Pages$Board$Repeat(
													_Utils_Tuple2(day, space))))
										]),
									_List_fromArray(
										[
											$author$project$Components$Icon$viewRepeatIcon,
											$elm$html$Html$text('Repeat')
										])),
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											A2($author$project$Pages$Board$SpaceUnassigned, day, space))
										]),
									_List_fromArray(
										[
											$author$project$Components$Icon$viewUnAssignIcon,
											$elm$html$Html$text('Unassign')
										])),
									historyButton
								])),
							addPeople
						]));
				return _List_fromArray(
					[currentAssignment, buttons]);
			}
		}();
		var header = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('header')
				]),
			headerContent);
		return A5(
			$author$project$Pages$Board$ModalParts,
			'assignSpaceModal',
			$elm$html$Html$text('Assign ' + space.bd),
			header,
			content,
			footer);
	});
var $author$project$Pages$Board$ModalBack = {$: 24};
var $author$project$Components$Icon$viewBackIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon-backspace')
		]),
	_List_Nil);
var $author$project$Pages$Board$modalHeaderWithBackButton = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('header')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('backButton'),
					$elm$html$Html$Events$onClick($author$project$Pages$Board$ModalBack)
				]),
			_List_fromArray(
				[$author$project$Components$Icon$viewBackIcon]))
		]));
var $author$project$Pages$Board$getConfirmDeleteModalParts = F2(
	function (model, deleteData) {
		var content = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('basicModalContent')
				]),
			deleteData.by);
		var buttons = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('buttons')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Components$Button$viewCancelButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Board$ModalBack)
						])),
					A3(
					$author$project$Components$Button$viewButton,
					model.bT,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('delete'),
							$elm$html$Html$Events$onClick(deleteData.cl)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Confirm Delete')
						]))
				]));
		return A5(
			$author$project$Pages$Board$ModalParts,
			'conformDeleteModal',
			$elm$html$Html$text('Confirm Delete'),
			$author$project$Pages$Board$modalHeaderWithBackButton,
			content,
			buttons);
	});
var $author$project$Pages$Board$ConflictsResolved = function (a) {
	return {$: 33, a: a};
};
var $author$project$Pages$Board$GotConflictAction = F2(
	function (a, b) {
		return {$: 32, a: a, b: b};
	});
var $author$project$Pages$Board$Ignore = 1;
var $author$project$Pages$Board$Overwrite = 0;
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Pages$Board$shortPrettyDay = function (day) {
	var d = $justinmimbs$date$Date$fromIsoString(day);
	var dateString = function () {
		if (!d.$) {
			var date = d.a;
			return A2($author$project$Pages$Board$prettyDayFromDate, date, true);
		} else {
			return day;
		}
	}();
	return dateString;
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Pages$Board$getConflictModalParts = F3(
	function (users, model, repeatedAssignments) {
		var conflicts = A2(
			$elm$core$List$filter,
			function (ra) {
				return ra.aG === 1;
			},
			$elm$core$Dict$values(repeatedAssignments));
		var contentItems = A2(
			$elm$core$List$map,
			function (ra) {
				var resolved = !_Utils_eq(ra.ab, $elm$core$Maybe$Nothing);
				var repeatName = A2($author$project$Pages$Board$getAssignmentUsersName, users, ra.e);
				var id = _Utils_Tuple2(ra.e.g, ra.e.p);
				var conflictName = function () {
					var _v0 = ra.bx;
					if (!_v0.$) {
						var c = _v0.a;
						return A2($author$project$Pages$Board$getAssignmentUsersName, users, c);
					} else {
						return '';
					}
				}();
				var conflictDay = $author$project$Pages$Board$shortPrettyDay(ra.e.g);
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('conflictItem'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('resolved', resolved)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('conflictDate')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Conflict: ' + conflictDay)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('conflictOptions')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('conflictOption')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('radio'),
													$elm$html$Html$Attributes$checked(
													_Utils_eq(
														ra.ab,
														$elm$core$Maybe$Just(1))),
													$elm$html$Html$Events$onClick(
													A2($author$project$Pages$Board$GotConflictAction, id, 1))
												]),
											_List_Nil),
											$elm$html$Html$text(conflictName)
										])),
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('conflictOption')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('radio'),
													$elm$html$Html$Attributes$checked(
													_Utils_eq(
														ra.ab,
														$elm$core$Maybe$Just(0))),
													$elm$html$Html$Events$onClick(
													A2($author$project$Pages$Board$GotConflictAction, id, 0))
												]),
											_List_Nil),
											$elm$html$Html$text(repeatName)
										]))
								]))
						]));
			},
			conflicts);
		var content = A2($elm$html$Html$div, _List_Nil, contentItems);
		var allConflictsResolved = A2(
			$elm$core$List$all,
			function (ra) {
				return !_Utils_eq(ra.ab, $elm$core$Maybe$Nothing);
			},
			conflicts);
		var footer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('buttons')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Components$Button$viewButton,
					model.I,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Board$ConflictsResolved(
								$elm$core$Dict$values(repeatedAssignments))),
							$elm$html$Html$Attributes$disabled(!allConflictsResolved)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Resolve')
						])),
					$author$project$Pages$Board$modalCancelButton
				]));
		return A5(
			$author$project$Pages$Board$ModalParts,
			'conflictsModal',
			$elm$html$Html$text('Resolve Conflicts'),
			$author$project$Pages$Board$emptyDiv,
			content,
			footer);
	});
var $author$project$Pages$Board$HistoryItemExpanded = F4(
	function (usersName, changedByName, changedAt, wasUnassigned) {
		return {cg: changedAt, bO: changedByName, da: usersName, bL: wasUnassigned};
	});
var $author$project$Pages$Board$padDigits = function (number) {
	var _v0 = number < 10;
	if (_v0) {
		return '0' + $elm$core$String$fromInt(number);
	} else {
		return $elm$core$String$fromInt(number);
	}
};
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $justinmimbs$time_extra$Time$Extra$posixToParts = F2(
	function (zone, posix) {
		return {
			g: A2($elm$time$Time$toDay, zone, posix),
			dB: A2($elm$time$Time$toHour, zone, posix),
			bZ: A2($elm$time$Time$toMillis, zone, posix),
			dL: A2($elm$time$Time$toMinute, zone, posix),
			cJ: A2($elm$time$Time$toMonth, zone, posix),
			b6: A2($elm$time$Time$toSecond, zone, posix),
			dh: A2($elm$time$Time$toYear, zone, posix)
		};
	});
var $author$project$Pages$Board$timestampToString = F2(
	function (zone, timestamp) {
		var parts = A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, timestamp);
		var month = $author$project$Pages$Board$monthToString(
			A2($elm$time$Time$toMonth, zone, timestamp));
		var minutes = $author$project$Pages$Board$padDigits(parts.dL);
		var hour = $author$project$Pages$Board$padDigits(parts.dB);
		var day = $elm$core$String$fromInt(parts.g);
		var dateString = day + (' ' + month);
		return dateString + (' ' + (hour + (':' + minutes)));
	});
var $author$project$Pages$Board$usersNameOrUnknown = F2(
	function (users, userId) {
		var _v0 = A2($elm$core$Dict$get, userId, users);
		if (_v0.$ === 1) {
			return 'unknown';
		} else {
			var u = _v0.a;
			return u.U;
		}
	});
var $author$project$Pages$Board$expandHistoryItem = F3(
	function (zone, users, history) {
		var changedByName = A2($author$project$Pages$Board$usersNameOrUnknown, users, history.ch);
		var changedAt = A2($author$project$Pages$Board$timestampToString, zone, history.c6);
		var assignedToName = function () {
			var _v0 = history.j;
			if (!_v0.$) {
				var userId = _v0.a;
				return A2($author$project$Pages$Board$usersNameOrUnknown, users, userId);
			} else {
				return '';
			}
		}();
		return A4($author$project$Pages$Board$HistoryItemExpanded, assignedToName, changedByName, changedAt, history.bL);
	});
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$Components$Icon$viewDownArrowIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-arrow-thin-down')
		]),
	_List_Nil);
var $author$project$Pages$Board$viewHistoryLine = function (info) {
	var time = ' (' + (info.cg + ')');
	var content = function () {
		var _v0 = info.bL;
		if (_v0) {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Unassigned by ' + (info.bO + time))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Assigned to ' + (info.da + (' by ' + (info.bO + time))))
					]));
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('historyItem')
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Pages$Board$viewHistory = F3(
	function (zone, users, history) {
		var _v0 = $elm$core$List$length(history);
		if (!_v0) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('noHistory')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('No history')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('why')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('(space has never been assigned)')
							]))
					]));
		} else {
			var itemBreak = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('itemBreak')
					]),
				_List_fromArray(
					[$author$project$Components$Icon$viewDownArrowIcon]));
			var historyLines = A2(
				$elm$core$List$map,
				$author$project$Pages$Board$viewHistoryLine,
				A2(
					$elm$core$List$map,
					A2($author$project$Pages$Board$expandHistoryItem, zone, users),
					history));
			var finalItems = A2($elm$core$List$intersperse, itemBreak, historyLines);
			return A2($elm$html$Html$div, _List_Nil, finalItems);
		}
	});
var $author$project$Pages$Board$viewModalPending = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('modalPending')
		]),
	_List_fromArray(
		[$author$project$Components$Spinner$viewLargeSpinner]));
var $author$project$Pages$Board$somethingWentWrong = 'Something went wrong, please refresh your page and try again. If the issue persists contact support';
var $author$project$Pages$Board$viewErrorMessage = function (message) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('message error')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(message)
			]));
};
var $author$project$Pages$Board$viewSomethingWentWrong = $author$project$Pages$Board$viewErrorMessage($author$project$Pages$Board$somethingWentWrong);
var $author$project$Pages$Board$getHistoryModalParts = F4(
	function (users, model, day, space) {
		var maybeSpaceHistory = A2(
			$elm$core$Dict$get,
			_Utils_Tuple2(day, space.dC),
			model.H);
		var header = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('backButton'),
							$elm$html$Html$Events$onClick(
							A2(
								$author$project$Pages$Board$ModalOpened,
								true,
								$author$project$Pages$Board$Assign(
									_Utils_Tuple2(day, space))))
						]),
					_List_fromArray(
						[$author$project$Components$Icon$viewBackIcon]))
				]));
		var contentPending = $author$project$Pages$Board$viewModalPending;
		var content = function () {
			if (maybeSpaceHistory.$ === 1) {
				return contentPending;
			} else {
				var spaceHistory = maybeSpaceHistory.a;
				var _v1 = spaceHistory.aG;
				switch (_v1) {
					case 3:
						return $author$project$Pages$Board$viewSomethingWentWrong;
					case 2:
						return A3($author$project$Pages$Board$viewHistory, model.bM, users, spaceHistory.S);
					default:
						return contentPending;
				}
			}
		}();
		return A5(
			$author$project$Pages$Board$ModalParts,
			'historyModal',
			$elm$html$Html$text('History: ' + space.bd),
			header,
			content,
			$author$project$Pages$Board$emptyDiv);
	});
var $author$project$Pages$Board$AllDays = 2;
var $author$project$Pages$Board$GotRepeatPeriod = function (a) {
	return {$: 27, a: a};
};
var $author$project$Pages$Board$GotRepeatType = function (a) {
	return {$: 26, a: a};
};
var $author$project$Pages$Board$GotRepeatValue = function (a) {
	return {$: 28, a: a};
};
var $author$project$Pages$Board$RepeatAssignmentRequested = F4(
	function (a, b, c, d) {
		return {$: 29, a: a, b: b, c: c, d: d};
	});
var $author$project$Pages$Board$SameDay = 0;
var $author$project$Pages$Board$dayToWeekDayName = function (day) {
	var dateString = function () {
		var _v0 = $justinmimbs$date$Date$fromIsoString(day);
		if (!_v0.$) {
			var date = _v0.a;
			return $author$project$Pages$Board$dayOfWeekToString(
				$justinmimbs$date$Date$weekday(date));
		} else {
			return '';
		}
	}();
	return dateString;
};
var $elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Pages$Board$getRepeatModalParts = F3(
	function (model, day, space) {
		var maybeAssignment = A2(
			$elm$core$Dict$get,
			_Utils_Tuple2(day, space.dC),
			model.d);
		var header = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('backButton'),
							$elm$html$Html$Events$onClick(
							A2(
								$author$project$Pages$Board$ModalOpened,
								true,
								$author$project$Pages$Board$Assign(
									_Utils_Tuple2(day, space))))
						]),
					_List_fromArray(
						[$author$project$Components$Icon$viewBackIcon]))
				]));
		var _v0 = function () {
			var _v1 = _Utils_Tuple3(model.aU, model.aV, model.bk);
			if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
				var repeatPeriod = _v1.a.a;
				var repeatType = _v1.b.a;
				var repeatValue = _v1.c.a;
				if (!maybeAssignment.$) {
					var assignment = maybeAssignment.a;
					return _Utils_Tuple3(
						true,
						A3($author$project$Pages$Board$repeatRequestEndDate, day, repeatPeriod, repeatValue),
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								A4($author$project$Pages$Board$RepeatAssignmentRequested, assignment, repeatType, repeatPeriod, repeatValue))
							]));
				} else {
					return _Utils_Tuple3(false, $elm$core$Maybe$Nothing, _List_Nil);
				}
			} else {
				return _Utils_Tuple3(false, $elm$core$Maybe$Nothing, _List_Nil);
			}
		}();
		var canSubmit = _v0.a;
		var endDate = _v0.b;
		var buttonAction = _v0.c;
		var footer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('buttons')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Components$Button$viewButton,
					model.I,
					$elm$core$List$concat(
						_List_fromArray(
							[
								buttonAction,
								_List_fromArray(
								[
									$elm$html$Html$Attributes$disabled(!canSubmit)
								])
							])),
					_List_fromArray(
						[
							$author$project$Components$Icon$viewRepeatIcon,
							$elm$html$Html$text('Repeat')
						])),
					$author$project$Pages$Board$cancelOrCloseButton(canSubmit)
				]));
		var content = function () {
			var typeChecked = function (repeatType) {
				return _Utils_eq(
					model.aV,
					$elm$core$Maybe$Just(repeatType));
			};
			var repeatValue = function () {
				var _v4 = model.bk;
				if (_v4.$ === 1) {
					return '';
				} else {
					var num = _v4.a;
					return $elm$core$String$fromInt(num);
				}
			}();
			var repeatOptions = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('repeatOptions options')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('radio'),
										$elm$html$Html$Attributes$checked(
										typeChecked(0)),
										$elm$html$Html$Events$onClick(
										$author$project$Pages$Board$GotRepeatType(0))
									]),
								_List_Nil),
								$elm$html$Html$text(
								'Same Day ' + ('(' + ($author$project$Pages$Board$dayToWeekDayName(day) + ')')))
							])),
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('radio'),
										$elm$html$Html$Attributes$checked(
										typeChecked(1)),
										$elm$html$Html$Events$onClick(
										$author$project$Pages$Board$GotRepeatType(1))
									]),
								_List_Nil),
								$elm$html$Html$text('Week Days (Mon-Fri)')
							])),
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('radio'),
										$elm$html$Html$Attributes$checked(
										typeChecked(2)),
										$elm$html$Html$Events$onClick(
										$author$project$Pages$Board$GotRepeatType(2))
									]),
								_List_Nil),
								$elm$html$Html$text('All Days (inc. Weekends)')
							]))
					]));
			var endDateInfo = function () {
				if (!endDate.$) {
					var d = endDate.a;
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('endDate')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'(' + (A2($author$project$Pages$Board$prettyDayFromDate, d, false) + ')'))
							]));
				} else {
					return $author$project$Pages$Board$emptyDiv;
				}
			}();
			var untilOptions = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('options')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('For'),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('text'),
										$elm$html$Html$Attributes$class('input repeatInput'),
										$elm$html$Html$Attributes$maxlength(3),
										$elm$html$Html$Events$onInput($author$project$Pages$Board$GotRepeatValue),
										$elm$html$Html$Attributes$value(repeatValue)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$select,
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput($author$project$Pages$Board$GotRepeatPeriod),
										$elm$html$Html$Attributes$class('select')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$option,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$value('weeks'),
												$elm$html$Html$Attributes$selected(
												_Utils_eq(
													model.aU,
													$elm$core$Maybe$Just(0)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Weeks')
											])),
										A2(
										$elm$html$Html$option,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$value('months'),
												$elm$html$Html$Attributes$selected(
												_Utils_eq(
													model.aU,
													$elm$core$Maybe$Just(1)))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Months')
											]))
									])),
								endDateInfo
							]))
					]));
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('repeatContent')
					]),
				_List_fromArray(
					[repeatOptions, untilOptions]));
		}();
		return A5(
			$author$project$Pages$Board$ModalParts,
			'repeatModal',
			$elm$html$Html$text('Repeat: ' + space.bd),
			header,
			content,
			footer);
	});
var $author$project$Pages$Board$SendSharedClicked = {$: 38};
var $author$project$Pages$Board$ToggleSharedItem = function (a) {
	return {$: 37, a: a};
};
var $author$project$Pages$Board$viewShareUserList = F2(
	function (users, model) {
		var allUsers = A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.U;
			},
			$elm$core$Dict$values(users));
		var items = A2(
			$elm$core$List$map,
			function (u) {
				var selected = A2($elm$core$List$member, u.dC, model.O);
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('userItem'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('selected', selected)
								])),
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Board$ToggleSharedItem(u.dC))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(u.U)
						]));
			},
			allUsers);
		return A2($elm$html$Html$div, _List_Nil, items);
	});
var $author$project$Pages$Board$getShareModalParts = F2(
	function (users, model) {
		var header = $author$project$Pages$Board$emptyDiv;
		var content = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('intro')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Select who will be emailed a link to view parking')
						])),
					A2($author$project$Pages$Board$viewShareUserList, users, model)
				]));
		var buttonText = function () {
			var _v0 = model.Y;
			if (_v0 === 2) {
				return 'Sent!';
			} else {
				return 'Send';
			}
		}();
		var footer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('actions')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Components$Button$viewCloseButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Board$ModalClosed)
						])),
					A3(
					$author$project$Components$Button$viewButton,
					model.Y,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Board$SendSharedClicked)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(buttonText)
						]))
				]));
		return A5(
			$author$project$Pages$Board$ModalParts,
			'shareModal',
			$elm$html$Html$text('Share'),
			header,
			content,
			footer);
	});
var $author$project$Components$Modal$viewBasicModal = F6(
	function (modalClosed, extraClass, title, header, content, footer) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modalOverlay'),
							modalClosed
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('wrap1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('wrap2')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal'),
											$elm$html$Html$Attributes$class(extraClass)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modalHeader')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modalTitle')
														]),
													_List_fromArray(
														[title])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('closeCTA'),
															modalClosed
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('icon-cross')
																]),
															_List_Nil)
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('noScrollContent')
												]),
											_List_fromArray(
												[header])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('scrollableContent'),
													$elm$html$Html$Attributes$id('modalScrollableContent')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('content')
														]),
													_List_fromArray(
														[content]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('footer')
												]),
											_List_fromArray(
												[footer]))
										]))
								]))
						]))
				]));
	});
var $author$project$Pages$Board$viewModal = F3(
	function (session, users, model) {
		var modal = $elm_community$list_extra$List$Extra$last(model.af);
		var modalParts = function () {
			if (!modal.$) {
				switch (modal.a.$) {
					case 0:
						var _v2 = modal.a.a;
						var day = _v2.a;
						var space = _v2.b;
						return $elm$core$Maybe$Just(
							A4($author$project$Pages$Board$getAssignModalParts, users, model, day, space));
					case 1:
						var _v3 = modal.a.a;
						var day = _v3.a;
						var space = _v3.b;
						return $elm$core$Maybe$Just(
							A4($author$project$Pages$Board$getHistoryModalParts, users, model, day, space));
					case 2:
						var _v4 = modal.a.a;
						var day = _v4.a;
						var space = _v4.b;
						return $elm$core$Maybe$Just(
							A3($author$project$Pages$Board$getRepeatModalParts, model, day, space));
					case 3:
						var _v5 = modal.a;
						return $elm$core$Maybe$Just(
							A3($author$project$Pages$Board$getConflictModalParts, users, model, model.W));
					case 4:
						var deleteData = modal.a.a;
						return $elm$core$Maybe$Just(
							A2($author$project$Pages$Board$getConfirmDeleteModalParts, model, deleteData));
					default:
						var _v6 = modal.a;
						return $elm$core$Maybe$Just(
							A2($author$project$Pages$Board$getShareModalParts, users, model));
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		if (modalParts.$ === 1) {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		} else {
			var parts = modalParts.a;
			return A6(
				$author$project$Components$Modal$viewBasicModal,
				$elm$html$Html$Events$onClick($author$project$Pages$Board$ModalClosed),
				parts.ci,
				parts.d6,
				parts.cz,
				parts.by,
				parts.cw);
		}
	});
var $author$project$Pages$Board$view = F3(
	function (session, users, model) {
		var content = function () {
			if (session.$ === 1) {
				var user = session.a;
				return _List_fromArray(
					[
						A3($author$project$Pages$Board$viewBoard, user, users, model),
						A3($author$project$Pages$Board$viewModal, session, users, model)
					]);
			} else {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pagePending')
							]),
						_List_fromArray(
							[$author$project$Components$Spinner$viewLargeSpinner]))
					]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('boardViewWrapper')
				]),
			content);
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Pages$Editor$AddedSpaceGroup = {$: 1};
var $author$project$Pages$Editor$SaveClicked = {$: 4};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$getDropId = function (model) {
	switch (model.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var dragId = model.a;
			return $elm$core$Maybe$Nothing;
		default:
			var dragId = model.a;
			var dropId = model.b;
			return $elm$core$Maybe$Just(dropId);
	}
};
var $author$project$Pages$Editor$Down = 1;
var $author$project$Pages$Editor$EditSpaceGroup = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Editor$MoveSpaceGroup = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Pages$Editor$SpaceAdded = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Editor$Up = 0;
var $author$project$Components$Icon$viewDownIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-arrow-down')
		]),
	_List_Nil);
var $author$project$Pages$Editor$DragDropMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Editor$DroppableGap = function (a) {
	return {$: 1, a: a};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnter = function (a) {
	return {$: 2, a: a};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragLeave = function (a) {
	return {$: 3, a: a};
};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragOver = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$Drop = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions = F3(
	function (name, _v0, decoder) {
		var stopPropagation = _v0.b9;
		var preventDefault = _v0.b4;
		return A2(
			$elm$html$Html$Events$custom,
			name,
			A2(
				$elm$json$Json$Decode$map,
				function (msg) {
					return {aR: msg, b4: preventDefault, b9: stopPropagation};
				},
				decoder));
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$Position = F4(
	function (width, height, x, y) {
		return {cA: height, de: width, df: x, dg: y};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$map4 = _Json_map4;
var $elm$core$Basics$round = _Basics_round;
var $norpan$elm_html5_drag_drop$Html5$DragDrop$positionDecoder = A5(
	$elm$json$Json$Decode$map4,
	$norpan$elm_html5_drag_drop$Html5$DragDrop$Position,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['currentTarget', 'clientWidth']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['currentTarget', 'clientHeight']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$round,
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['offsetX']),
			$elm$json$Json$Decode$float)),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$round,
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['offsetY']),
			$elm$json$Json$Decode$float)));
var $norpan$elm_html5_drag_drop$Html5$DragDrop$timeStampDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$round,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['timeStamp']),
		$elm$json$Json$Decode$float));
var $norpan$elm_html5_drag_drop$Html5$DragDrop$droppable = F2(
	function (wrap, dropId) {
		return _List_fromArray(
			[
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragenter',
				{b4: true, b9: true},
				$elm$json$Json$Decode$succeed(
					wrap(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnter(dropId)))),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragleave',
				{b4: true, b9: true},
				$elm$json$Json$Decode$succeed(
					wrap(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragLeave(dropId)))),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragover',
				{b4: true, b9: false},
				A2(
					$elm$json$Json$Decode$map,
					wrap,
					A3(
						$elm$json$Json$Decode$map2,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragOver(dropId),
						$norpan$elm_html5_drag_drop$Html5$DragDrop$timeStampDecoder,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$positionDecoder))),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'drop',
				{b4: true, b9: true},
				A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						wrap,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$Drop(dropId)),
					$norpan$elm_html5_drag_drop$Html5$DragDrop$positionDecoder))
			]);
	});
var $author$project$Pages$Editor$viewDropGap = F3(
	function (currentDropTarget, spaceGroupIndex, index) {
		var highlight = function () {
			if (!currentDropTarget.$) {
				if (currentDropTarget.a.$ === 1) {
					var _v1 = currentDropTarget.a.a;
					var x = _v1.a;
					var y = _v1.b;
					return _Utils_eq(x, spaceGroupIndex) && _Utils_eq(y, index);
				} else {
					var _v2 = currentDropTarget.a.a;
					var x = _v2.a;
					var y = _v2.b;
					return _Utils_eq(x, spaceGroupIndex) && _Utils_eq(y, index);
				}
			} else {
				return false;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('dropGap'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('highlight', highlight)
							]))
					]),
				A2(
					$norpan$elm_html5_drag_drop$Html5$DragDrop$droppable,
					$author$project$Pages$Editor$DragDropMsg,
					$author$project$Pages$Editor$DroppableGap(
						_Utils_Tuple2(spaceGroupIndex, index)))),
			_List_Nil);
	});
var $author$project$Pages$Editor$DroppableSpace = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Editor$SpaceClicked = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnd = {$: 1};
var $norpan$elm_html5_drag_drop$Html5$DragDrop$DragStart = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $norpan$elm_html5_drag_drop$Html5$DragDrop$draggable = F2(
	function (wrap, drag) {
		return _List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'draggable', 'true'),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragstart',
				{b4: false, b9: true},
				A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						wrap,
						$norpan$elm_html5_drag_drop$Html5$DragDrop$DragStart(drag)),
					$elm$json$Json$Decode$value)),
				A3(
				$norpan$elm_html5_drag_drop$Html5$DragDrop$onWithOptions,
				'dragend',
				{b4: false, b9: true},
				$elm$json$Json$Decode$succeed(
					wrap($norpan$elm_html5_drag_drop$Html5$DragDrop$DragEnd)))
			]);
	});
var $author$project$Pages$Editor$viewSpace = F4(
	function (currentDropTarget, spaceGroupIndex, index, space) {
		var highlight = function () {
			if ((!currentDropTarget.$) && (!currentDropTarget.a.$)) {
				var _v1 = currentDropTarget.a.a;
				var x = _v1.a;
				var y = _v1.b;
				return _Utils_eq(x, spaceGroupIndex) && _Utils_eq(y, index);
			} else {
				return false;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('space available'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('highlight', highlight)
							])),
						$elm$html$Html$Events$onClick(
						A2($author$project$Pages$Editor$SpaceClicked, spaceGroupIndex, index))
					]),
				_Utils_ap(
					A2(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$draggable,
						$author$project$Pages$Editor$DragDropMsg,
						_Utils_Tuple2(spaceGroupIndex, index)),
					A2(
						$norpan$elm_html5_drag_drop$Html5$DragDrop$droppable,
						$author$project$Pages$Editor$DragDropMsg,
						$author$project$Pages$Editor$DroppableSpace(
							_Utils_Tuple2(spaceGroupIndex, index))))),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(space.bd)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_List_Nil)
				]));
	});
var $author$project$Components$Icon$viewUpIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-arrow-up')
		]),
	_List_Nil);
var $author$project$Pages$Editor$viewSpaceGroup = F3(
	function (currentDropTarget, spaceGroupIndex, spaceGroup) {
		var spaces = A2(
			$elm$core$List$indexedMap,
			A2($author$project$Pages$Editor$viewSpace, currentDropTarget, spaceGroupIndex),
			spaceGroup.bn);
		var spaceCount = $elm$core$List$length(spaces);
		var lastGap = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('lastDropGap')
				]),
			_List_fromArray(
				[
					A3($author$project$Pages$Editor$viewDropGap, currentDropTarget, spaceGroupIndex, spaceCount + 1)
				]));
		var gaps = A2(
			$elm$core$List$map,
			A2($author$project$Pages$Editor$viewDropGap, currentDropTarget, spaceGroupIndex),
			A2($elm$core$List$range, 0, spaceCount));
		var gapsAndSpaces = A3(
			$elm$core$List$map2,
			F2(
				function (g, s) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('gapAndSpace')
							]),
						_List_fromArray(
							[g, s]));
				}),
			gaps,
			spaces);
		var lastSpaceWithGaps = function () {
			var _v0 = _Utils_Tuple2(
				$elm_community$list_extra$List$Extra$last(gaps),
				$elm_community$list_extra$List$Extra$last(spaces));
			if ((!_v0.a.$) && (!_v0.b.$)) {
				var g = _v0.a.a;
				var s = _v0.b.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('gapAndSpace')
						]),
					_List_fromArray(
						[g, s, lastGap]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var finalGapsAndSpaces = A3(
			$elm_community$list_extra$List$Extra$setAt,
			$elm$core$List$length(gapsAndSpaces) - 1,
			lastSpaceWithGaps,
			gapsAndSpaces);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('spaceGroupWrap')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('spaceGroupHeader')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(spaceGroup.bd)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('spaceGroupActions')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Pages$Editor$EditSpaceGroup(spaceGroupIndex))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Edit')
										])),
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											A2($author$project$Pages$Editor$MoveSpaceGroup, 0, spaceGroupIndex))
										]),
									_List_fromArray(
										[$author$project$Components$Icon$viewUpIcon])),
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											A2($author$project$Pages$Editor$MoveSpaceGroup, 1, spaceGroupIndex))
										]),
									_List_fromArray(
										[$author$project$Components$Icon$viewDownIcon])),
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Pages$Editor$SpaceAdded(spaceGroupIndex))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Add Space')
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('spaceGroup')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('spaces')
								]),
							finalGapsAndSpaces)
						]))
				]));
	});
var $author$project$Components$Misc$viewSuccessMessage = function (message) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('messageWrap')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message success')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]))
			]));
};
var $author$project$Pages$Editor$viewEditor = function (model) {
	var saveStatus = function () {
		var _v2 = model.aG;
		switch (_v2) {
			case 2:
				return 1;
			case 4:
				return 3;
			default:
				return 0;
		}
	}();
	var dirty = !_Utils_eq(model.Z, model.bG);
	var success = function () {
		var _v0 = _Utils_Tuple2(model.aG, dirty);
		if ((_v0.a === 3) && (!_v0.b)) {
			var _v1 = _v0.a;
			return $author$project$Components$Misc$viewSuccessMessage('Spaces saved');
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	var currentDropTarget = $norpan$elm_html5_drag_drop$Html5$DragDrop$getDropId(model.a4);
	var spaceGroups = A2(
		$elm$core$List$indexedMap,
		$author$project$Pages$Editor$viewSpaceGroup(currentDropTarget),
		model.Z);
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2($elm$html$Html$div, _List_Nil, spaceGroups),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$Components$Button$viewButton,
						0,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Pages$Editor$AddedSpaceGroup)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Add Space Group')
							])),
						A3(
						$author$project$Components$Button$viewButton,
						saveStatus,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Pages$Editor$SaveClicked)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Save')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[success]))
			]));
};
var $author$project$Pages$Editor$SpaceDeleted = F3(
	function (a, b, c) {
		return {$: 13, a: a, b: b, c: c};
	});
var $author$project$Pages$Editor$SpaceGroupDeleted = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Pages$Editor$ModalBack = {$: 15};
var $author$project$Components$Button$viewDeleteButton = F2(
	function (status, attributes) {
		return A5(
			$author$project$Components$Button$viewRawButton,
			1,
			status,
			'delete',
			_List_fromArray(
				[
					$elm$html$Html$text('Delete')
				]),
			attributes);
	});
var $author$project$Pages$Editor$viewConfirmDeleteModal = F3(
	function (titleCopy, content, confirmMsg) {
		var title = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(titleCopy)
				]));
		var header = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var footer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('buttons')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Components$Button$viewDeleteButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(confirmMsg)
						])),
					A2(
					$author$project$Components$Button$viewCancelButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Editor$ModalBack)
						]))
				]));
		return A6(
			$author$project$Components$Modal$viewBasicModal,
			$elm$html$Html$Events$onClick($author$project$Pages$Editor$ModalClosed),
			'editSpaceModal',
			title,
			header,
			content,
			footer);
	});
var $author$project$Pages$Editor$GotLabel = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Editor$SpaceGroupChanged = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Form$TextField = F3(
	function (label, value, error) {
		return {ba: error, bd: label, bv: value};
	});
var $author$project$Validation$fieldErrorToString = function (fieldError) {
	switch (fieldError.$) {
		case 0:
			return 'Cannot be blank';
		case 1:
			return 'Already in use';
		case 2:
			return 'Not valid';
		case 3:
			return 'Not found';
		case 4:
			return 'Something went wrong';
		default:
			var other = fieldError.a;
			return other;
	}
};
var $author$project$Components$Form$viewError = function (maybeError) {
	if (!maybeError.$) {
		var error = maybeError.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('errorMessage')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Validation$fieldErrorToString(error))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Components$Form$viewTextField = F2(
	function (tf, inputAttrs) {
		var labelEl = function () {
			var _v1 = tf.bd;
			if (_v1 === '') {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			} else {
				return A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(tf.bd)
						]));
			}
		}();
		var errored = function () {
			var _v0 = tf.ba;
			if (!_v0.$) {
				return true;
			} else {
				return false;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('control')
				]),
			_List_fromArray(
				[
					labelEl,
					A2(
					$elm$html$Html$input,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Attributes$value(tf.bv),
								$elm$html$Html$Attributes$class('input'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('errored', errored)
									]))
							]),
						inputAttrs),
					_List_Nil),
					$author$project$Components$Form$viewError(tf.ba)
				]));
	});
var $author$project$Pages$Editor$viewEditSpaceGroupModal = F2(
	function (spaceGroupIndex, model) {
		var title = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Edit Space Group')
				]));
		var header = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var footer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('buttons')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Components$Button$viewCloseButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Editor$ModalClosed)
						])),
					A2(
					$author$project$Components$Button$viewDeleteButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$Pages$Editor$SpaceGroupDeleted, spaceGroupIndex, false))
						])),
					A3(
					$author$project$Components$Button$viewButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Editor$SpaceGroupChanged(spaceGroupIndex))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Ok')
						]))
				]));
		var content = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Components$Form$viewTextField,
					A3($author$project$Components$Form$TextField, 'Label', model.Q, $elm$core$Maybe$Nothing),
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput($author$project$Pages$Editor$GotLabel)
						]))
				]));
		return A6(
			$author$project$Components$Modal$viewBasicModal,
			$elm$html$Html$Events$onClick($author$project$Pages$Editor$ModalClosed),
			'editSpaceModal',
			title,
			header,
			content,
			footer);
	});
var $author$project$Pages$Editor$SpaceChanged = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Editor$viewEditSpaceModal = F3(
	function (spaceGroupIndex, spaceIndex, model) {
		var title = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Edit Space')
				]));
		var header = A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var footer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('buttons')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Components$Button$viewCloseButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Editor$ModalClosed)
						])),
					A2(
					$author$project$Components$Button$viewDeleteButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A3($author$project$Pages$Editor$SpaceDeleted, spaceGroupIndex, spaceIndex, false))
						])),
					A3(
					$author$project$Components$Button$viewButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							A2($author$project$Pages$Editor$SpaceChanged, spaceGroupIndex, spaceIndex))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Ok')
						]))
				]));
		var content = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Components$Form$viewTextField,
					A3($author$project$Components$Form$TextField, 'Label', model.Q, $elm$core$Maybe$Nothing),
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput($author$project$Pages$Editor$GotLabel)
						]))
				]));
		return A6(
			$author$project$Components$Modal$viewBasicModal,
			$elm$html$Html$Events$onClick($author$project$Pages$Editor$ModalClosed),
			'editSpaceModal',
			title,
			header,
			content,
			footer);
	});
var $author$project$Pages$Editor$viewModal = function (model) {
	var _v0 = $elm_community$list_extra$List$Extra$last(model.af);
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 0:
				var _v1 = _v0.a;
				var spaceGroupIndex = _v1.a;
				var spaceIndex = _v1.b;
				return A3($author$project$Pages$Editor$viewEditSpaceModal, spaceGroupIndex, spaceIndex, model);
			case 1:
				var spaceGroupIndex = _v0.a.a;
				return A2($author$project$Pages$Editor$viewEditSpaceGroupModal, spaceGroupIndex, model);
			case 2:
				var _v2 = _v0.a;
				var spaceGroupIndex = _v2.a;
				var spaceIndex = _v2.b;
				var content = A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Deleting this space will also delete any assignments it has.')
						]));
				return A3(
					$author$project$Pages$Editor$viewConfirmDeleteModal,
					'Confirm Delete Space',
					content,
					A3($author$project$Pages$Editor$SpaceDeleted, spaceGroupIndex, spaceIndex, true));
			default:
				var spaceGroupIndex = _v0.a.a;
				var content = A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Deleting this space group will delete all it\'s spaces and their assignments')
						]));
				return A3(
					$author$project$Pages$Editor$viewConfirmDeleteModal,
					'Confirm Delete Space Group',
					content,
					A2($author$project$Pages$Editor$SpaceGroupDeleted, spaceGroupIndex, true));
		}
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Pages$Editor$view = function (model) {
	var modal = $author$project$Pages$Editor$viewModal(model);
	var content = function () {
		var _v0 = model.aG;
		if (_v0 === 1) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pagePending')
					]),
				_List_fromArray(
					[$author$project$Components$Spinner$viewLargeSpinner]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Editor$viewEditor(model)
					]));
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('page editorPage')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Space Editor')
					])),
				content,
				modal
			]));
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Pages$Login$viewExpired = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('expired')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Link has expired')
				])),
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('link'),
					$elm$html$Html$Attributes$href(
					$author$project$Routes$path($author$project$Routes$loginPath))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Back to login')
				]))
		]));
var $author$project$Pages$Login$ForgotPasswordClicked = {$: 9};
var $author$project$Pages$Login$GotEmail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Login$GotMagicEmail = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Login$GotPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Login$Login = {$: 1};
var $author$project$Pages$Login$MagicLinkRequested = {$: 7};
var $author$project$Components$Form$enterKey = 13;
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Components$Form$onEnterGuarded = F2(
	function (canSucceed, msg) {
		var isEnter = function (code) {
			return (_Utils_eq(code, $author$project$Components$Form$enterKey) && canSucceed) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
		};
		return A2(
			$elm$html$Html$Events$on,
			'keydown',
			A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
	});
var $author$project$Pages$Login$viewLogin = function (model) {
	var magicLinkSuccess = function () {
		var _v1 = model.ax;
		if (_v1 === 2) {
			return $author$project$Components$Misc$viewSuccessMessage('Magic link sent');
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	var magicLinkDisabled = !$author$project$Validation$emailIsValid(model.aw);
	var loginDisabled = (!$author$project$Validation$emailIsValid(model.R)) || (model.aj === '');
	var error = function () {
		var _v0 = model.aG;
		if (_v0 === 3) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Wrong username or password')
					]));
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Components$Form$viewTextField,
						A3($author$project$Components$Form$TextField, 'Email', model.R, $elm$core$Maybe$Nothing),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput($author$project$Pages$Login$GotEmail)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('passwordInput')
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Components$Form$viewTextField,
								A3($author$project$Components$Form$TextField, 'Password', model.aj, $elm$core$Maybe$Nothing),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('password'),
										$elm$html$Html$Events$onInput($author$project$Pages$Login$GotPassword),
										A2($author$project$Components$Form$onEnterGuarded, !loginDisabled, $author$project$Pages$Login$Login)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('forgotPassword'),
								$elm$html$Html$Events$onClick($author$project$Pages$Login$ForgotPasswordClicked)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Forgot your password?')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('actions')
							]),
						_List_fromArray(
							[
								A3(
								$author$project$Components$Button$viewButton,
								model.aG,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Pages$Login$Login),
										$elm$html$Html$Attributes$disabled(loginDisabled)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Login')
									]))
							])),
						error
					])),
				A2($elm$html$Html$hr, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Get a magic login link')
							])),
						A2(
						$author$project$Components$Form$viewTextField,
						A3($author$project$Components$Form$TextField, 'Email', model.aw, model.bf),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput($author$project$Pages$Login$GotMagicEmail),
								A2($author$project$Components$Form$onEnterGuarded, !magicLinkDisabled, $author$project$Pages$Login$MagicLinkRequested)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('actions')
							]),
						_List_fromArray(
							[
								A3(
								$author$project$Components$Button$viewButton,
								model.ax,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Pages$Login$MagicLinkRequested),
										$elm$html$Html$Attributes$disabled(magicLinkDisabled)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Send Link')
									]))
							])),
						magicLinkSuccess
					]))
			]));
};
var $author$project$Pages$Login$viewPending = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('pending')
		]),
	_List_fromArray(
		[$author$project$Components$Spinner$viewLargeSpinner]));
var $author$project$Pages$Login$viewMagic = function (model) {
	var _v0 = model.bD;
	switch (_v0) {
		case 1:
			return $author$project$Pages$Login$viewPending;
		case 3:
			return $author$project$Pages$Login$viewExpired;
		default:
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Pages$Login$BackToLoginClicked = {$: 13};
var $author$project$Pages$Login$GotResetEmail = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Login$ResetSubmitted = {$: 11};
var $author$project$Pages$Login$viewRequestReset = function (model) {
	var resetSuccess = function () {
		var _v0 = model.aE;
		if (_v0 === 2) {
			return $author$project$Components$Misc$viewSuccessMessage('Password reset email sent');
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	var resetDisabled = !$author$project$Validation$emailIsValid(model.aD);
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Tell us your email and we will send you a password reset link')
					])),
				A2(
				$author$project$Components$Form$viewTextField,
				A3($author$project$Components$Form$TextField, 'Email', model.aD, model.bl),
				_List_fromArray(
					[
						$elm$html$Html$Events$onInput($author$project$Pages$Login$GotResetEmail),
						A2($author$project$Components$Form$onEnterGuarded, !resetDisabled, $author$project$Pages$Login$ResetSubmitted)
					])),
				A3(
				$author$project$Components$Button$viewButton,
				model.aE,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$disabled(resetDisabled),
						$elm$html$Html$Events$onClick($author$project$Pages$Login$ResetSubmitted)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Send Reset Email')
					])),
				resetSuccess,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('backToLogin'),
						$elm$html$Html$Events$onClick($author$project$Pages$Login$BackToLoginClicked)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Back to Login')
					]))
			]));
};
var $author$project$Pages$Login$GotNewPassword = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Login$GotNewPasswordAgain = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Login$NewPasswordSubmitted = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Login$viewSetNewPassword = F2(
	function (token, model) {
		var passwordsMatch = _Utils_eq(model.ay, model.aS);
		var anyBlank = (model.ay === '') || (model.aS === '');
		var canSubmit = (!anyBlank) && passwordsMatch;
		var error = function () {
			var _v0 = _Utils_Tuple2(anyBlank, passwordsMatch);
			if ((!_v0.a) && (!_v0.b)) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('error')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Passwords do not match')
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('reset')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Choose new password')
						])),
					A2(
					$author$project$Components$Form$viewTextField,
					A3($author$project$Components$Form$TextField, 'Password', model.ay, $elm$core$Maybe$Nothing),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('password'),
							$elm$html$Html$Events$onInput($author$project$Pages$Login$GotNewPassword)
						])),
					A2(
					$author$project$Components$Form$viewTextField,
					A3($author$project$Components$Form$TextField, 'Repeat Password', model.aS, $elm$core$Maybe$Nothing),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('password'),
							$elm$html$Html$Events$onInput($author$project$Pages$Login$GotNewPasswordAgain),
							A2(
							$author$project$Components$Form$onEnterGuarded,
							canSubmit,
							$author$project$Pages$Login$NewPasswordSubmitted(token))
						])),
					error,
					A3(
					$author$project$Components$Button$viewButton,
					model.aT,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$disabled(!canSubmit),
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Login$NewPasswordSubmitted(token))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Save Password')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('link'),
									$elm$html$Html$Attributes$href(
									$author$project$Routes$path($author$project$Routes$loginPath))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Back to login')
								]))
						]))
				]));
	});
var $author$project$Pages$Login$viewSetNewPasswordOrExpired = function (model) {
	var _v0 = _Utils_Tuple2(model.b5, model.aT);
	if (_v0.a.$ === 1) {
		var _v1 = _v0.a;
		return $author$project$Pages$Login$viewExpired;
	} else {
		if (_v0.b === 3) {
			var _v2 = _v0.b;
			return $author$project$Pages$Login$viewExpired;
		} else {
			var token = _v0.a.a;
			return A2($author$project$Pages$Login$viewSetNewPassword, token, model);
		}
	}
};
var $author$project$Pages$Login$view = function (model) {
	var content = function () {
		var _v0 = model.bm;
		switch (_v0) {
			case 0:
				return $author$project$Pages$Login$viewLogin(model);
			case 1:
				return $author$project$Pages$Login$viewRequestReset(model);
			case 2:
				return $author$project$Pages$Login$viewSetNewPasswordOrExpired(model);
			case 3:
				return $author$project$Pages$Login$viewMagic(model);
			default:
				return $author$project$Pages$Login$viewExpired;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('loginPage')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Parzay')
					])),
				content
			]));
};
var $author$project$Components$Form$CheckboxField = F3(
	function (label, value, error) {
		return {ba: error, bd: label, bv: value};
	});
var $author$project$Pages$Profile$GotCanAssign = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Profile$GotCurrentPassword = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Profile$GotEmail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Profile$GotIsAdmin = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Profile$GotName = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Profile$GotNewPassword = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Profile$SaveClicked = {$: 5};
var $author$project$Pages$Profile$UpdatePasswordClicked = {$: 9};
var $author$project$Util$flipBool = function (b) {
	if (b) {
		return false;
	} else {
		return true;
	}
};
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Components$Form$onEnter = function (msg) {
	var isEnter = function (code) {
		return _Utils_eq(code, $author$project$Components$Form$enterKey) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
	};
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
};
var $author$project$Components$Form$viewCheckbox = F2(
	function (cf, inputAttrs) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('control checkbox'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('checked', cf.bv)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							A2(
								$elm$core$List$append,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('checkbox'),
										$elm$html$Html$Attributes$checked(cf.bv)
									]),
								inputAttrs),
							_List_Nil),
							$elm$html$Html$text(cf.bd)
						])),
					$author$project$Components$Form$viewError(cf.ba)
				]));
	});
var $author$project$Components$Misc$viewSuccessMessageGuarded = F2(
	function (show, message) {
		if (show) {
			return $author$project$Components$Misc$viewSuccessMessage(message);
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	});
var $author$project$Pages$Profile$viewContent = F2(
	function (user, model) {
		var permissionsDisabled = !(!user.c9);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('profileContent')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('block')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Details')
								])),
							A2(
							$author$project$Components$Form$viewTextField,
							A3($author$project$Components$Form$TextField, 'Name', model.U, model.bF),
							_List_fromArray(
								[
									$elm$html$Html$Events$onInput($author$project$Pages$Profile$GotName)
								])),
							A2(
							$author$project$Components$Form$viewTextField,
							A3($author$project$Components$Form$TextField, 'Email', model.R, model.bA),
							_List_fromArray(
								[
									$elm$html$Html$Events$onInput($author$project$Pages$Profile$GotEmail)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('options')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Components$Form$viewCheckbox,
									A3($author$project$Components$Form$CheckboxField, 'Admin', model.bb, $elm$core$Maybe$Nothing),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(permissionsDisabled),
											$elm$html$Html$Events$onClick(
											$author$project$Pages$Profile$GotIsAdmin(
												$author$project$Util$flipBool(model.bb)))
										])),
									A2(
									$author$project$Components$Form$viewCheckbox,
									A3($author$project$Components$Form$CheckboxField, 'Can Assign', model.a0, $elm$core$Maybe$Nothing),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$disabled(permissionsDisabled),
											$elm$html$Html$Events$onClick(
											$author$project$Pages$Profile$GotCanAssign(
												$author$project$Util$flipBool(model.a0)))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Components$Button$viewButton,
									model.X,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Pages$Profile$SaveClicked)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Save Details')
										]))
								])),
							A2($author$project$Components$Misc$viewSuccessMessageGuarded, model.X === 2, 'Details Saved')
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('block')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Change Password')
								])),
							A2(
							$author$project$Components$Form$viewTextField,
							A3($author$project$Components$Form$TextField, 'Current Password', model.ao, model.a2),
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Events$onInput($author$project$Pages$Profile$GotCurrentPassword)
								])),
							A2(
							$author$project$Components$Form$viewTextField,
							A3($author$project$Components$Form$TextField, 'New Password', model.ay, model.az),
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Events$onInput($author$project$Pages$Profile$GotNewPassword),
									$author$project$Components$Form$onEnter($author$project$Pages$Profile$UpdatePasswordClicked)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Components$Button$viewButton,
									model.N,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Pages$Profile$UpdatePasswordClicked)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Update Password')
										]))
								])),
							A2($author$project$Components$Misc$viewSuccessMessageGuarded, model.N === 2, 'Password Changed')
						]))
				]));
	});
var $author$project$Pages$Profile$view = function (model) {
	var content = function () {
		var _v0 = model.ea;
		if (_v0.$ === 1) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pagePending')
					]),
				_List_fromArray(
					[$author$project$Components$Spinner$viewLargeSpinner]));
		} else {
			var user = _v0.a;
			return A2($author$project$Pages$Profile$viewContent, user, model);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('page profilePage')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Edit Profile')
					])),
				content
			]));
};
var $author$project$Pages$SignUp$BasicsStageSubmitted = {$: 4};
var $author$project$Pages$SignUp$GotEmail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$SignUp$GotName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$SignUp$GotOrgName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$SignUp$GotPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$SignUp$viewBasicsStage = function (model) {
	var showError = function (error) {
		var _v1 = model.bw;
		if (_v1) {
			return error;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var errors = $author$project$Pages$SignUp$getFieldErrors(model);
	var emailError = function () {
		var _v0 = model.a8;
		if (_v0) {
			return $elm$core$Maybe$Just(
				$author$project$Validation$Other('Email address already in use'));
		} else {
			return errors.R;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pageTitle')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Sign Up')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('basicsStage')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Components$Form$viewTextField,
						A3(
							$author$project$Components$Form$TextField,
							'Name',
							model.U,
							showError(errors.U)),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput($author$project$Pages$SignUp$GotName)
							])),
						A2(
						$author$project$Components$Form$viewTextField,
						A3(
							$author$project$Components$Form$TextField,
							'Email',
							model.R,
							showError(emailError)),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput($author$project$Pages$SignUp$GotEmail)
							])),
						A2(
						$author$project$Components$Form$viewTextField,
						A3(
							$author$project$Components$Form$TextField,
							'Password',
							model.aj,
							showError(errors.aj)),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput($author$project$Pages$SignUp$GotPassword),
								$elm$html$Html$Attributes$type_('password')
							])),
						A2(
						$author$project$Components$Form$viewTextField,
						A3(
							$author$project$Components$Form$TextField,
							'Company/Organisation name',
							model.cL,
							showError(errors.cL)),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput($author$project$Pages$SignUp$GotOrgName),
								$author$project$Components$Form$onEnter($author$project$Pages$SignUp$BasicsStageSubmitted)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$Components$Button$viewButton,
						model.a$,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Pages$SignUp$BasicsStageSubmitted)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Sign Up')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Already have account? '),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(
								$author$project$Routes$path($author$project$Routes$loginPath))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Login Now')
							]))
					]))
			]));
};
var $author$project$Pages$SignUp$AllTogether = 0;
var $author$project$Pages$SignUp$GotHowToOrganise = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$SignUp$HowToOrganiseStageSubmitted = {$: 5};
var $author$project$Pages$SignUp$InGroups = 1;
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Pages$SignUp$previewSpace = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('previewSpace')
		]),
	_List_Nil);
var $author$project$Pages$SignUp$viewChangeLaterMessage = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('whisper')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('(Don\'t worry this can be changed later)')
		]));
var $author$project$Pages$SignUp$viewHowToOrganiseStage = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('How do you want to organise your parking spaces?'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$author$project$Pages$SignUp$viewChangeLaterMessage
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bigOptionWrap')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('bigOption')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('topRow')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('radio'),
												$elm$html$Html$Attributes$checked(
												_Utils_eq(
													model.ad,
													$elm$core$Maybe$Just(0))),
												$elm$html$Html$Events$onClick(
												$author$project$Pages$SignUp$GotHowToOrganise(0))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('option')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('All Spaces Together')
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('bottomRow')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('preview')
											]),
										_List_fromArray(
											[$author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('preview')
											]),
										_List_fromArray(
											[$author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace, $author$project$Pages$SignUp$previewSpace]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bigOptionWrap')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('bigOption')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('topRow')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('radio'),
												$elm$html$Html$Attributes$checked(
												_Utils_eq(
													model.ad,
													$elm$core$Maybe$Just(1))),
												$elm$html$Html$Events$onClick(
												$author$project$Pages$SignUp$GotHowToOrganise(1))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('option')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Split Into Groups')
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('bottomRow')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('preview')
											]),
										_List_fromArray(
											[
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('blankSpace')
													]),
												_List_Nil),
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('preview')
											]),
										_List_fromArray(
											[
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace,
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('blankSpace')
													]),
												_List_Nil),
												$author$project$Pages$SignUp$previewSpace,
												$author$project$Pages$SignUp$previewSpace
											]))
									]))
							]))
					])),
				A2($elm$html$Html$hr, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$Components$Button$viewButton,
						0,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Pages$SignUp$HowToOrganiseStageSubmitted),
								$elm$html$Html$Attributes$disabled(
								_Utils_eq(model.ad, $elm$core$Maybe$Nothing))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Next')
							]))
					]))
			]));
};
var $author$project$Pages$SignUp$GotAllSpacesCount = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$SignUp$SpaceCountSubmitted = {$: 7};
var $author$project$Pages$SignUp$maybeIntToString = function (maybeInt) {
	if (!maybeInt.$) {
		var _int = maybeInt.a;
		return $elm$core$String$fromInt(_int);
	} else {
		return '';
	}
};
var $author$project$Pages$SignUp$Back = {$: 17};
var $author$project$Pages$SignUp$viewBackButton = function (enabled) {
	return A3(
		$author$project$Components$Button$viewButton,
		0,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('unimportant'),
				$elm$html$Html$Events$onClick($author$project$Pages$SignUp$Back),
				$elm$html$Html$Attributes$disabled(!enabled)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Back')
			]));
};
var $author$project$Pages$SignUp$viewNumberOfSpaces = F2(
	function (value, attrs) {
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('numberOfSpaces')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Number of Spaces')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('spaceInput')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Components$Form$viewTextField,
							A3($author$project$Components$Form$TextField, '', value, $elm$core$Maybe$Nothing),
							attrs)
						]))
				]));
	});
var $author$project$Pages$SignUp$viewAllTogetherSpaces = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('How many spaces do you have?'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$author$project$Pages$SignUp$viewChangeLaterMessage
					])),
				A2(
				$author$project$Pages$SignUp$viewNumberOfSpaces,
				$author$project$Pages$SignUp$maybeIntToString(model.aK),
				_List_fromArray(
					[
						$elm$html$Html$Events$onInput($author$project$Pages$SignUp$GotAllSpacesCount)
					])),
				A2($elm$html$Html$hr, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions')
					]),
				_List_fromArray(
					[
						$author$project$Pages$SignUp$viewBackButton(!model._),
						A3(
						$author$project$Components$Button$viewButton,
						model._,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Pages$SignUp$SpaceCountSubmitted),
								$elm$html$Html$Attributes$disabled(
								_Utils_eq(model.aK, $elm$core$Maybe$Nothing))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Submit')
							]))
					]))
			]));
};
var $author$project$Pages$SignUp$SpaceGroupAdded = {$: 14};
var $author$project$Pages$SignUp$SpaceGroupsSubmitted = {$: 6};
var $author$project$Pages$SignUp$GotSpaceGroupCount = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Pages$SignUp$GotSpaceGroupLabel = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Pages$SignUp$SpaceGroupDeleted = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$SignUp$viewSpaceGroup = F2(
	function (index, sg) {
		var ok = $author$project$Pages$SignUp$spaceGroupOk(sg);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('editableSpaceGroup'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('ok', ok),
							_Utils_Tuple2('notOk', !ok)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('spaceGroupLabel')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('label')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Label')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('labelInput')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$Form$viewTextField,
											A3($author$project$Components$Form$TextField, '', sg.bd, $elm$core$Maybe$Nothing),
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput(
													$author$project$Pages$SignUp$GotSpaceGroupLabel(index))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bottomRow')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Pages$SignUp$viewNumberOfSpaces,
							$author$project$Pages$SignUp$maybeIntToString(sg.bg),
							_List_fromArray(
								[
									$elm$html$Html$Events$onInput(
									$author$project$Pages$SignUp$GotSpaceGroupCount(index))
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('delete'),
											$elm$html$Html$Events$onClick(
											$author$project$Pages$SignUp$SpaceGroupDeleted(index))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Delete')
										]))
								]))
						]))
				]));
	});
var $author$project$Pages$SignUp$viewGroupedSpaces = function (model) {
	var spaceGroups = A2($elm$core$List$indexedMap, $author$project$Pages$SignUp$viewSpaceGroup, model.Z);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('showErrors', model.bo)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Create your groups of spaces'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$author$project$Pages$SignUp$viewChangeLaterMessage
					])),
				A2($elm$html$Html$div, _List_Nil, spaceGroups),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('add')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$Components$Button$viewButton,
						0,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('good'),
								$elm$html$Html$Events$onClick($author$project$Pages$SignUp$SpaceGroupAdded)
							]),
						_List_fromArray(
							[
								$author$project$Components$Icon$viewAssignIcon,
								$elm$html$Html$text('Add Space Group')
							]))
					])),
				A2($elm$html$Html$hr, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions')
					]),
				_List_fromArray(
					[
						$author$project$Pages$SignUp$viewBackButton(!model._),
						A3(
						$author$project$Components$Button$viewButton,
						model._,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Pages$SignUp$SpaceGroupsSubmitted)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Submit')
							]))
					]))
			]));
};
var $author$project$Pages$SignUp$viewSpaceGroupsStage = function (model) {
	var _v0 = model.ad;
	if (!_v0.$) {
		if (!_v0.a) {
			var _v1 = _v0.a;
			return $author$project$Pages$SignUp$viewAllTogetherSpaces(model);
		} else {
			var _v2 = _v0.a;
			return $author$project$Pages$SignUp$viewGroupedSpaces(model);
		}
	} else {
		return $author$project$Pages$SignUp$viewHowToOrganiseStage(model);
	}
};
var $author$project$Pages$SignUp$view = function (model) {
	var content = function () {
		var _v0 = model.aY;
		switch (_v0) {
			case 0:
				return $author$project$Pages$SignUp$viewBasicsStage(model);
			case 1:
				return $author$project$Pages$SignUp$viewHowToOrganiseStage(model);
			default:
				return $author$project$Pages$SignUp$viewSpaceGroupsStage(model);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('signUpPage')
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Pages$Users$DeleteConfirmed = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$viewConfirmDeleteModal = F2(
	function (user, model) {
		var footer = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('actions')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Components$Button$viewCancelButton,
					0,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Pages$Users$ModalClosed)
						])),
					A2(
					$author$project$Components$Button$viewDeleteButton,
					model.ap,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Users$DeleteConfirmed(user.dC))
						]))
				]));
		var content = A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('You sure you want to delete ' + (user.U + '?'))
				]));
		return A6(
			$author$project$Components$Modal$viewBasicModal,
			$elm$html$Html$Events$onClick($author$project$Pages$Users$ModalClosed),
			'editUserModal',
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Delete User')
					])),
			A2($elm$html$Html$div, _List_Nil, _List_Nil),
			content,
			footer);
	});
var $author$project$Pages$Users$CancelClicked = {$: 9};
var $author$project$Pages$Users$DeleteClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$GotCanAssign = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$GotEmail = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$GotIsAdmin = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$GotName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$SaveClicked = {$: 8};
var $author$project$Pages$Users$viewEditUserModal = function (model) {
	var isAdmin = !model.aP;
	var footer = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('buttons')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Components$Button$viewDeleteButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$Users$DeleteClicked(model.a6))
					])),
				A3(
				$author$project$Components$Button$viewButton,
				model.X,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Pages$Users$SaveClicked)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					])),
				A2(
				$author$project$Components$Button$viewCancelButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Pages$Users$CancelClicked)
					]))
			]));
	var content = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('editUserForm')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Components$Form$viewTextField,
				A3($author$project$Components$Form$TextField, 'Name', model.at, model.a7),
				_List_fromArray(
					[
						$elm$html$Html$Events$onInput($author$project$Pages$Users$GotName)
					])),
				A2(
				$author$project$Components$Form$viewTextField,
				A3($author$project$Components$Form$TextField, 'Email', model.as, model.a5),
				_List_fromArray(
					[
						$elm$html$Html$Events$onInput($author$project$Pages$Users$GotEmail)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('options')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Components$Form$viewCheckbox,
						A3($author$project$Components$Form$CheckboxField, 'Is Admin', isAdmin, $elm$core$Maybe$Nothing),
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Pages$Users$GotIsAdmin(
									$author$project$Util$flipBool(isAdmin)))
							])),
						A2(
						$author$project$Components$Form$viewCheckbox,
						A3($author$project$Components$Form$CheckboxField, 'Can Assign Spaces', model.ar, $elm$core$Maybe$Nothing),
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Pages$Users$GotCanAssign(
									$author$project$Util$flipBool(model.ar)))
							]))
					]))
			]));
	return A6(
		$author$project$Components$Modal$viewBasicModal,
		$elm$html$Html$Events$onClick($author$project$Pages$Users$ModalClosed),
		'editUserModal',
		A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Edit User')
				])),
		A2($elm$html$Html$div, _List_Nil, _List_Nil),
		content,
		footer);
};
var $elm$core$String$toLower = _String_toLower;
var $author$project$User$sortByName = function (users) {
	return A2(
		$elm$core$List$sortBy,
		function (u) {
			return $elm$core$String$toLower(u.U);
		},
		users);
};
var $author$project$User$getSortedUsers = function (users) {
	return $author$project$User$sortByName(
		$elm$core$Dict$values(users));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Pages$Users$UserEditStart = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$Pages$Users$viewBool = F2(
	function (label, bool) {
		if (bool) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bool')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('boolLabel')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(label)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tick')
							]),
						_List_fromArray(
							[$author$project$Components$Icon$viewTickIcon]))
					]));
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	});
var $author$project$Pages$Users$viewUser = function (user) {
	return A2(
		$elm$html$Html$tr,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$Pages$Users$UserEditStart(user.dC))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('name')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(user.U)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('email')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(user.R)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Pages$Users$viewBool, 'Admin', !user.c9)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Pages$Users$viewBool, 'Can Assign', user.a0)
					]))
			]));
};
var $author$project$Pages$Users$viewUsers = function (users) {
	var rows = A2(
		$elm$core$List$map,
		$author$project$Pages$Users$viewUser,
		$author$project$User$getSortedUsers(users));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('userTable')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Email')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Admin')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Can Assign')
											]))
									]))
							])),
						A2($elm$html$Html$tbody, _List_Nil, rows)
					]))
			]));
};
var $author$project$Pages$Users$view = F2(
	function (users, model) {
		var modal = function () {
			var _v1 = $elm$core$List$head(model.af);
			if (!_v1.$) {
				if (!_v1.a.$) {
					var _v2 = _v1.a;
					return $author$project$Pages$Users$viewEditUserModal(model);
				} else {
					var userId = _v1.a.a;
					var _v3 = A2($elm$core$Dict$get, userId, users);
					if (!_v3.$) {
						var user = _v3.a;
						return A2($author$project$Pages$Users$viewConfirmDeleteModal, user, model);
					} else {
						return A2($elm$html$Html$div, _List_Nil, _List_Nil);
					}
				}
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var content = function () {
			var _v0 = model.aG;
			switch (_v0) {
				case 1:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pagePending')
							]),
						_List_fromArray(
							[$author$project$Components$Spinner$viewLargeSpinner]));
				case 2:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('manageUsersContent')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Manage Users')
									])),
								$author$project$Pages$Users$viewUsers(users)
							]));
				default:
					return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('manageUsersPage page')
				]),
			_List_fromArray(
				[content, modal]));
	});
var $author$project$Main$ContactSupportClicked = {$: 19};
var $author$project$Main$LogoutClicked = {$: 15};
var $author$project$Main$MenuLinkClicked = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$MenuToggled = function (a) {
	return {$: 13, a: a};
};
var $author$project$Components$Icon$viewCloseIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon closeIcon icon-cross')
		]),
	_List_Nil);
var $author$project$Main$viewMenuItem = F3(
	function (label, iconClass, attrs) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				attrs,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('menuItem')
					])),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon'),
							$elm$html$Html$Attributes$class(iconClass)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						]))
				]));
	});
var $author$project$Main$viewMenu = function (model) {
	var usersActive = function () {
		var _v6 = model.i;
		if (_v6.$ === 4) {
			return true;
		} else {
			return false;
		}
	}();
	var profileActive = function () {
		var _v5 = model.i;
		if (_v5.$ === 5) {
			return true;
		} else {
			return false;
		}
	}();
	var orgName = function () {
		var _v4 = model.o;
		if (_v4.$ === 1) {
			var user = _v4.a;
			return user.cL;
		} else {
			return '';
		}
	}();
	var menuOpen = function () {
		var _v3 = model.ae;
		if (_v3.$ === 1) {
			return false;
		} else {
			var o = _v3.a;
			return o;
		}
	}();
	var menuClosed = function () {
		var _v2 = model.ae;
		if (_v2.$ === 1) {
			return false;
		} else {
			var o = _v2.a;
			return !o;
		}
	}();
	var editorActive = function () {
		var _v1 = model.i;
		if (_v1.$ === 3) {
			return true;
		} else {
			return false;
		}
	}();
	var boardActive = function () {
		var _v0 = model.i;
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('mainMenu', true),
						_Utils_Tuple2('menuOpen', menuOpen),
						_Utils_Tuple2('menuClosed', menuClosed)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('overlay'),
						$elm$html$Html$Events$onClick(
						$author$project$Main$MenuToggled(
							$elm$core$Maybe$Just(false)))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('menu')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('closeButton'),
								$elm$html$Html$Events$onClick(
								$author$project$Main$MenuToggled(
									$elm$core$Maybe$Just(false)))
							]),
						_List_fromArray(
							[$author$project$Components$Icon$viewCloseIcon])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('orgName')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(orgName)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('menuContent')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menuItems')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('assignSpacesCTA'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('active', boardActive)
													]))
											]),
										_List_fromArray(
											[
												A3(
												$author$project$Main$viewMenuItem,
												'Assign Spaces',
												'icon-checkmark',
												_List_fromArray(
													[
														$elm$html$Html$Events$onClick(
														$author$project$Main$MenuLinkClicked(
															$author$project$Routes$path($author$project$Routes$boardPath)))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('manageUsersCTA'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('active', usersActive)
													]))
											]),
										_List_fromArray(
											[
												A3(
												$author$project$Main$viewMenuItem,
												'Manage Users',
												'icon-user-group',
												_List_fromArray(
													[
														$elm$html$Html$Events$onClick(
														$author$project$Main$MenuLinkClicked(
															$author$project$Routes$path($author$project$Routes$manageUsersPath)))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('editSpacesCTA'),
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('active', editorActive)
													]))
											]),
										_List_fromArray(
											[
												A3(
												$author$project$Main$viewMenuItem,
												'Edit Spaces',
												'icon-document-edit',
												_List_fromArray(
													[
														$elm$html$Html$Events$onClick(
														$author$project$Main$MenuLinkClicked(
															$author$project$Routes$path($author$project$Routes$editorPath)))
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menuBreak')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A3(
										$author$project$Main$viewMenuItem,
										'Contact Support',
										'icon-question',
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$Main$ContactSupportClicked)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('profileCTA'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('active', profileActive)
											]))
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Main$viewMenuItem,
										'Profile',
										'icon-user',
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$Main$MenuLinkClicked(
													$author$project$Routes$path($author$project$Routes$profilePath)))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('menuBreak')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A3(
										$author$project$Main$viewMenuItem,
										'Logout',
										'icon-exit',
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$Main$LogoutClicked)
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('demoMessage')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('DISABLED FOR DEMO')
							]))
					]))
			]));
};
var $author$project$Main$viewMenuIcon = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('menuIcon'),
			$elm$html$Html$Events$onClick(
			$author$project$Main$MenuToggled(
				$elm$core$Maybe$Just(true)))
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('icon icon-menu')
				]),
			_List_Nil)
		]));
var $author$project$Pages$Board$Range = 1;
var $author$project$Pages$Board$Search = 0;
var $author$project$Pages$Board$TopPanelToggled = function (a) {
	return {$: 18, a: a};
};
var $author$project$Components$Icon$viewShareIcon = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('icon icon-forward')
		]),
	_List_Nil);
var $author$project$Pages$Board$JumpToDay = {$: 14};
var $author$project$Pages$Board$LoadNewDateRange = {$: 13};
var $CurrySoftware$elm_datepicker$DatePicker$Blur = {$: 6};
var $CurrySoftware$elm_datepicker$DatePicker$Focus = {$: 5};
var $CurrySoftware$elm_datepicker$DatePicker$SubmitText = {$: 4};
var $CurrySoftware$elm_datepicker$DatePicker$Text = function (a) {
	return {$: 3, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$ChangeFocus = function (a) {
	return {$: 1, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$MouseDown = {$: 7};
var $CurrySoftware$elm_datepicker$DatePicker$MouseUp = {$: 8};
var $CurrySoftware$elm_datepicker$DatePicker$Date$changeYear = F2(
	function (current, newYear) {
		var _v0 = $elm$core$String$toInt(newYear);
		if (!_v0.$) {
			var year = _v0.a;
			return A3(
				$justinmimbs$date$Date$fromCalendarDate,
				year,
				$justinmimbs$date$Date$month(current),
				$justinmimbs$date$Date$day(current));
		} else {
			return current;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$groupDates = function (dates) {
	var go = F4(
		function (i, xs, racc, acc) {
			go:
			while (true) {
				if (!xs.b) {
					return $elm$core$List$reverse(acc);
				} else {
					var x = xs.a;
					var xxs = xs.b;
					if (i === 6) {
						var $temp$i = 0,
							$temp$xs = xxs,
							$temp$racc = _List_Nil,
							$temp$acc = A2(
							$elm$core$List$cons,
							$elm$core$List$reverse(
								A2($elm$core$List$cons, x, racc)),
							acc);
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					} else {
						var $temp$i = i + 1,
							$temp$xs = xxs,
							$temp$racc = A2($elm$core$List$cons, x, racc),
							$temp$acc = acc;
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					}
				}
			}
		});
	return A4(go, 0, dates, _List_Nil, _List_Nil);
};
var $CurrySoftware$elm_datepicker$DatePicker$maybeOr = F2(
	function (lhs, rhs) {
		if (!rhs.$) {
			return rhs;
		} else {
			return lhs;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$mkClass = F2(
	function (_v0, c) {
		var classNamespace = _v0.aM;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(classNamespace, c));
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval = function (weekday) {
	switch (weekday) {
		case 0:
			return 4;
		case 1:
			return 5;
		case 2:
			return 6;
		case 3:
			return 7;
		case 4:
			return 8;
		case 5:
			return 9;
		default:
			return 10;
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$prepareDates = F2(
	function (date, firstDayOfWeek) {
		var weekdayAsInterval = $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval(firstDayOfWeek);
		var start = A2(
			$justinmimbs$date$Date$floor,
			weekdayAsInterval,
			A3(
				$justinmimbs$date$Date$fromCalendarDate,
				$justinmimbs$date$Date$year(date),
				$justinmimbs$date$Date$month(date),
				1));
		var firstOfMonth = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			$justinmimbs$date$Date$year(date),
			$justinmimbs$date$Date$month(date),
			1);
		var end = A2(
			$justinmimbs$date$Date$ceiling,
			weekdayAsInterval,
			A3($justinmimbs$date$Date$add, 1, 1, firstOfMonth));
		return {
			ck: A4($justinmimbs$date$Date$range, 11, 1, start, end),
			bQ: date
		};
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $CurrySoftware$elm_datepicker$DatePicker$Pick = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$mkClassList = F2(
	function (_v0, cs) {
		var classNamespace = _v0.aM;
		return $elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var c = _v1.a;
					var b = _v1.b;
					return _Utils_Tuple2(
						_Utils_ap(classNamespace, c),
						b);
				},
				cs));
	});
var $CurrySoftware$elm_datepicker$DatePicker$viewDay = F5(
	function (settings, picked, isOtherMonth, isToday, d) {
		var disabled = settings.bC(d);
		var props = (!disabled) ? _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$CurrySoftware$elm_datepicker$DatePicker$Pick(d))
			]) : _List_Nil;
		var classList = $CurrySoftware$elm_datepicker$DatePicker$mkClassList(settings);
		return A2(
			$elm$html$Html$td,
			_Utils_ap(
				_List_fromArray(
					[
						classList(
						_List_fromArray(
							[
								_Utils_Tuple2('day', true),
								_Utils_Tuple2('disabled', disabled),
								_Utils_Tuple2(
								'picked',
								picked(d)),
								_Utils_Tuple2(
								'today',
								isToday(d)),
								_Utils_Tuple2(
								'other-month',
								isOtherMonth(d))
							]))
					]),
				props),
			_List_fromArray(
				[
					settings.bN(
					$elm$core$String$fromInt(
						$justinmimbs$date$Date$day(d)))
				]));
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$yearRange = F2(
	function (_v0, range) {
		var currentMonth = _v0.bQ;
		var today = _v0.c7;
		switch (range.$) {
			case 1:
				var num = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth) - num,
					$justinmimbs$date$Date$year(currentMonth) + num);
			case 2:
				var start = range.a;
				var end = range.b;
				return A2($elm$core$List$range, start, end);
			case 3:
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					year_,
					$justinmimbs$date$Date$year(today));
			case 4:
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(today),
					year_);
			default:
				return _List_Nil;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$yearRangeActive = function (yearRange) {
	return !_Utils_eq(yearRange, $CurrySoftware$elm_datepicker$DatePicker$Date$Off);
};
var $CurrySoftware$elm_datepicker$DatePicker$datePicker = F3(
	function (pickedDate, settings, model) {
		var focused = model.E;
		var today = model.c7;
		var picked = function (d) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (pdate) {
						return _Utils_eq(
							$justinmimbs$date$Date$toRataDie(pdate),
							$justinmimbs$date$Date$toRataDie(d));
					},
					pickedDate));
		};
		var onChange = function (handler) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				A2($elm$json$Json$Decode$map, handler, $elm$html$Html$Events$targetValue));
		};
		var isToday = function (d) {
			return _Utils_eq(
				$justinmimbs$date$Date$toRataDie(d),
				$justinmimbs$date$Date$toRataDie(today));
		};
		var firstDayOffset = $justinmimbs$date$Date$weekdayToNumber(settings.bB) - 1;
		var dpClass = $CurrySoftware$elm_datepicker$DatePicker$mkClass(settings);
		var currentDate = A2(
			$elm$core$Maybe$withDefault,
			today,
			A2($CurrySoftware$elm_datepicker$DatePicker$maybeOr, pickedDate, focused));
		var isOtherMonth = function (d) {
			return !_Utils_eq(
				$justinmimbs$date$Date$month(currentDate),
				$justinmimbs$date$Date$month(d));
		};
		var arrow = F2(
			function (className, message) {
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							dpClass(className),
							$elm$html$Html$Events$onClick(message),
							$elm$html$Html$Attributes$tabindex(-1),
							$elm$html$Html$Attributes$type_('button')
						]),
					_List_Nil);
			});
		var _v0 = A2($CurrySoftware$elm_datepicker$DatePicker$prepareDates, currentDate, settings.bB);
		var currentMonth = _v0.bQ;
		var currentDates = _v0.ck;
		var dayList = A2(
			$elm$core$List$map,
			function (rowDays) {
				return A2(
					$elm$html$Html$tr,
					_List_fromArray(
						[
							dpClass('row')
						]),
					A2(
						$elm$core$List$map,
						A4($CurrySoftware$elm_datepicker$DatePicker$viewDay, settings, picked, isOtherMonth, isToday),
						rowDays));
			},
			$CurrySoftware$elm_datepicker$DatePicker$groupDates(currentDates));
		var isCurrentYear = function (selectedYear) {
			return _Utils_eq(
				$justinmimbs$date$Date$year(currentMonth),
				selectedYear);
		};
		var yearOption = F2(
			function (index, selectedYear) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(index),
					A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromInt(selectedYear)),
								$elm$html$Html$Attributes$selected(
								isCurrentYear(selectedYear))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(selectedYear))
							])));
			});
		var _v1 = function () {
			var front = function (to_) {
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth),
					to_);
			};
			var back = function (from_) {
				return A2(
					$elm$core$List$range,
					from_,
					$justinmimbs$date$Date$year(currentMonth));
			};
			var _v2 = settings.a1;
			switch (_v2.$) {
				case 3:
					var from_ = _v2.a;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(
							$justinmimbs$date$Date$year(today) + 1));
				case 4:
					var to_ = _v2.a;
					return _Utils_Tuple2(
						front(
							$justinmimbs$date$Date$year(today) - 1),
						back(to_ + 1));
				case 2:
					var from_ = _v2.a;
					var to_ = _v2.b;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(to_ + 1));
				case 1:
					var y = _v2.a;
					return _Utils_Tuple2(_List_Nil, _List_Nil);
				default:
					return _Utils_Tuple2(_List_Nil, _List_Nil);
			}
		}();
		var addedYearsFront = _v1.a;
		var addedYearsBack = _v1.b;
		var dropdownYear = A3(
			$elm$html$Html$Keyed$node,
			'select',
			_List_fromArray(
				[
					onChange(
					A2(
						$elm$core$Basics$composeR,
						$CurrySoftware$elm_datepicker$DatePicker$Date$changeYear(currentDate),
						$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus)),
					dpClass('year-menu')
				]),
			A2(
				$elm$core$List$indexedMap,
				yearOption,
				$elm$core$List$concat(
					_List_fromArray(
						[
							addedYearsFront,
							A2(
							$CurrySoftware$elm_datepicker$DatePicker$Date$yearRange,
							{bQ: currentMonth, c7: today},
							settings.a1),
							addedYearsBack
						]))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					dpClass('picker'),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mousedown',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseDown, true))),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mouseup',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseUp, true)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							dpClass('picker-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('prev-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'prev',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, 1, -1, currentDate)))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('month-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('month')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											settings.b_(
												$justinmimbs$date$Date$month(currentMonth)))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('year')
										]),
									_List_fromArray(
										[
											(!$CurrySoftware$elm_datepicker$DatePicker$yearRangeActive(settings.a1)) ? $elm$html$Html$text(
											settings.cb(
												$justinmimbs$date$Date$year(currentMonth))) : A3(
											$elm$html$Html$Keyed$node,
											'span',
											_List_Nil,
											_List_fromArray(
												[
													_Utils_Tuple2(
													$elm$core$String$fromInt(
														$justinmimbs$date$Date$year(currentMonth)),
													dropdownYear)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('next-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'next',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, 1, 1, currentDate)))
								]))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							dpClass('table')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_fromArray(
								[
									dpClass('weekdays')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									A2(
										$elm$core$List$map,
										function (d) {
											return A2(
												$elm$html$Html$td,
												_List_fromArray(
													[
														dpClass('dow')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														settings.bR(d))
													]));
										},
										A2(
											$elm$core$List$take,
											7,
											A2(
												$elm$core$List$drop,
												firstDayOffset,
												$elm$core$List$concat(
													A2(
														$elm$core$List$repeat,
														2,
														_List_fromArray(
															[0, 1, 2, 3, 4, 5, 6])))))))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_fromArray(
								[
									dpClass('days')
								]),
							dayList)
						]))
				]));
	});
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $CurrySoftware$elm_datepicker$DatePicker$view = F3(
	function (pickedDate, settings, _v0) {
		var datepicker = _v0;
		var model = datepicker;
		var potentialInputId = A3(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$elm$core$List$filterMap($elm$core$Basics$identity),
			A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, settings.bW));
		var inputClasses = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(settings.aM + 'input', true)
				]),
			settings.bV);
		var inputCommon = function (xs) {
			return A2(
				$elm$html$Html$input,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(inputClasses),
							$elm$html$Html$Attributes$name(
							A2($elm$core$Maybe$withDefault, '', settings.bX)),
							$elm$html$Html$Attributes$type_('text'),
							A2(
							$elm$html$Html$Events$on,
							'change',
							$elm$json$Json$Decode$succeed($CurrySoftware$elm_datepicker$DatePicker$SubmitText)),
							$elm$html$Html$Events$onInput($CurrySoftware$elm_datepicker$DatePicker$Text),
							$elm$html$Html$Events$onBlur($CurrySoftware$elm_datepicker$DatePicker$Blur),
							$elm$html$Html$Events$onClick($CurrySoftware$elm_datepicker$DatePicker$Focus),
							$elm$html$Html$Events$onFocus($CurrySoftware$elm_datepicker$DatePicker$Focus)
						]),
					_Utils_ap(
						settings.bU,
						_Utils_ap(potentialInputId, xs))),
				_List_Nil);
		};
		var dateInput = inputCommon(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$placeholder(settings.b3),
					$elm$html$Html$Attributes$value(
					A2(
						$elm$core$Maybe$withDefault,
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Maybe$map, settings.dp, pickedDate)),
						model.J))
				]));
		var containerClassList = A2(
			$elm$core$List$cons,
			_Utils_Tuple2(settings.aM + 'container', true),
			settings.bP);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(containerClassList)
				]),
			_List_fromArray(
				[
					dateInput,
					model.V ? A3($CurrySoftware$elm_datepicker$DatePicker$datePicker, pickedDate, settings, model) : $elm$html$Html$text('')
				]));
	});
var $author$project$Pages$Board$viewRange = F2(
	function (device, model) {
		var _v0 = function () {
			var _v1 = model.av;
			if (!_v1.$) {
				return _Utils_Tuple2(true, $elm$core$Maybe$Nothing);
			} else {
				return _Utils_Tuple2(
					false,
					$elm$core$Maybe$Just('Date cannot be blank'));
			}
		}();
		var jumpCanSubmit = _v0.a;
		var jumpError = _v0.b;
		var jumpMessageEl = function () {
			if (jumpCanSubmit) {
				return $author$project$Pages$Board$emptyDiv;
			} else {
				if (!jumpError.$) {
					var e = jumpError.a;
					return $author$project$Pages$Board$viewErrorMessage(e);
				} else {
					return $author$project$Pages$Board$emptyDiv;
				}
			}
		}();
		var _v2 = function () {
			var _v3 = device.dN;
			if (_v3) {
				return _Utils_Tuple2('MM/dd/yyyy', 'MM/DD/YYYY');
			} else {
				return _Utils_Tuple2('dd/MM/yyyy', 'DD/MM/YYYY');
			}
		}();
		var dateFormat = _v2.a;
		var displayHint = _v2.b;
		var settings = _Utils_update(
			$CurrySoftware$elm_datepicker$DatePicker$defaultSettings,
			{
				dp: $justinmimbs$date$Date$format(dateFormat)
			});
		var _v4 = function () {
			var _v5 = _Utils_Tuple2(model.ah, model.ag);
			if ((!_v5.a.$) && (!_v5.b.$)) {
				var startDate = _v5.a.a;
				var endDate = _v5.b.a;
				var dateDelta = A3($justinmimbs$date$Date$diff, 3, startDate, endDate);
				var _v6 = dateDelta >= 0;
				if (_v6) {
					return _Utils_Tuple2(true, $elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2(
						false,
						$elm$core$Maybe$Just('End date must be after start date'));
				}
			} else {
				return _Utils_Tuple2(
					false,
					$elm$core$Maybe$Just('Date cannot be blank'));
			}
		}();
		var changeCanSubmit = _v4.a;
		var changeError = _v4.b;
		var changeMessageEl = function () {
			if (changeCanSubmit) {
				return $author$project$Pages$Board$emptyDiv;
			} else {
				if (!changeError.$) {
					var e = changeError.a;
					return $author$project$Pages$Board$viewErrorMessage(e);
				} else {
					return $author$project$Pages$Board$emptyDiv;
				}
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('range')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('heading')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Change Date Range')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('hint')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(displayHint)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dateWrap')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('label')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Start: ')
								])),
							A2(
							$elm$html$Html$map,
							$author$project$Pages$Board$StartDatePickerChanged,
							A3($CurrySoftware$elm_datepicker$DatePicker$view, model.ah, settings, model.bp))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dateWrap')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('label')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('End: ')
								])),
							A2(
							$elm$html$Html$map,
							$author$project$Pages$Board$EndDatePickerChanged,
							A3($CurrySoftware$elm_datepicker$DatePicker$view, model.ag, settings, model.a9))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[changeMessageEl])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Components$Button$viewSmallButton,
							0,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Pages$Board$LoadNewDateRange),
									$elm$html$Html$Attributes$disabled(!changeCanSubmit)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Change')
								]))
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('heading')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Jump to day')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('hint')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(displayHint)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('dateWrap')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('label')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Day: ')
								])),
							A2(
							$elm$html$Html$map,
							$author$project$Pages$Board$JumpToDayPickerChanged,
							A3($CurrySoftware$elm_datepicker$DatePicker$view, model.av, settings, model.bc))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[jumpMessageEl])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Components$Button$viewSmallButton,
							0,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Pages$Board$JumpToDay),
									$elm$html$Html$Attributes$disabled(!jumpCanSubmit)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Jump')
								]))
						]))
				]));
	});
var $author$project$Pages$Board$UserSearchCleared = {$: 20};
var $author$project$Pages$Board$UserSearchedFor = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$Board$viewSearchUserSelect = F2(
	function (userDict, model) {
		var userId = function () {
			var _v0 = model.al;
			if (!_v0.$) {
				var uid = _v0.a;
				return uid;
			} else {
				return 'blank';
			}
		}();
		var blankUser = A7($author$project$User$User, 'blank', 'Select user...', '', 1, '', false, '');
		var users = A2(
			$elm$core$List$cons,
			blankUser,
			A2(
				$elm$core$List$cons,
				$author$project$User$visitorPlaceholderUser,
				$elm$core$Dict$values(userDict)));
		var options = A2(
			$elm$core$List$map,
			function (user) {
				return A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value(user.dC),
							$elm$html$Html$Attributes$selected(
							_Utils_eq(user.dC, userId))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(user.U)
						]));
			},
			users);
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('select'),
							$elm$html$Html$Events$onInput($author$project$Pages$Board$UserSearchedFor)
						]),
					options)
				]));
	});
var $author$project$Pages$Board$viewSearch = F2(
	function (users, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('searchTitle')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Highlight User: ')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('searchInputs')
						]),
					_List_fromArray(
						[
							A2($author$project$Pages$Board$viewSearchUserSelect, users, model),
							A3(
							$author$project$Components$Button$viewSmallButton,
							0,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Pages$Board$UserSearchCleared),
									$elm$html$Html$Attributes$class('unimportant')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Clear')
								]))
						]))
				]));
	});
var $author$project$Pages$Board$viewTopPanel = F3(
	function (device, users, model) {
		var content = function () {
			var _v0 = model.v;
			if (!_v0.$) {
				if (!_v0.a) {
					var _v1 = _v0.a;
					return A2($author$project$Pages$Board$viewSearch, users, model);
				} else {
					var _v2 = _v0.a;
					return A2($author$project$Pages$Board$viewRange, device, model);
				}
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('topPanel'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'show',
							!_Utils_eq(model.v, $elm$core$Maybe$Nothing))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('closeTopPanelCTA'),
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Board$TopPanelToggled($elm$core$Maybe$Nothing))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon-cross')
								]),
							_List_Nil)
						])),
					content
				]));
	});
var $author$project$Pages$Board$viewTopBarContent = F3(
	function (device, users, model) {
		var searchToggleValue = function () {
			var _v2 = model.v;
			if ((!_v2.$) && (!_v2.a)) {
				var _v3 = _v2.a;
				return $elm$core$Maybe$Nothing;
			} else {
				return $elm$core$Maybe$Just(0);
			}
		}();
		var rangeToggleValue = function () {
			var _v0 = model.v;
			if ((!_v0.$) && (_v0.a === 1)) {
				var _v1 = _v0.a;
				return $elm$core$Maybe$Nothing;
			} else {
				return $elm$core$Maybe$Just(1);
			}
		}();
		var activeSearch = !_Utils_eq(model.al, $elm$core$Maybe$Nothing);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('topBarExtraContent')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('shareButton'),
									$elm$html$Html$Events$onClick(
									A2($author$project$Pages$Board$ModalOpened, false, $author$project$Pages$Board$Share))
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$Components$Icon$viewShareIcon,
											$elm$html$Html$text('Share')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('cta'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'active',
											_Utils_eq(
												model.v,
												$elm$core$Maybe$Just(1)))
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Pages$Board$TopPanelToggled(rangeToggleValue))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-calendar')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('cta searchUserCTA'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'active',
											_Utils_eq(
												model.v,
												$elm$core$Maybe$Just(0))),
											_Utils_Tuple2('activeSearch', activeSearch)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Pages$Board$TopPanelToggled(searchToggleValue))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon-search')
										]),
									_List_Nil)
								]))
						])),
					A3($author$project$Pages$Board$viewTopPanel, device, users, model)
				]));
	});
var $author$project$Pages$Editor$viewTopBarContent = function (model) {
	var saveStatus = function () {
		var _v0 = model.aG;
		switch (_v0) {
			case 2:
				return 1;
			case 4:
				return 3;
			default:
				return 0;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('topBarExtraContent')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Button$viewSmallButton,
				saveStatus,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Pages$Editor$SaveClicked)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]))
			]));
};
var $author$project$Pages$Users$viewTopBarContent = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('topBarExtraContent')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Button$viewSmallButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Pages$Users$AddPeopleClicked)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Add People')
					]))
			]));
};
var $author$project$Main$viewTopBar = function (model) {
	var extraContent = function () {
		var _v0 = model.i;
		switch (_v0.$) {
			case 4:
				var usersPageModel = _v0.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$UsersPageMsg,
					$author$project$Pages$Users$viewTopBarContent(usersPageModel));
			case 0:
				var boardPageModel = _v0.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$BoardPageMsg,
					A3($author$project$Pages$Board$viewTopBarContent, model.aq, model.z, boardPageModel));
			case 3:
				var editorPageModel = _v0.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Main$EditorPageMsg,
					$author$project$Pages$Editor$viewTopBarContent(editorPageModel));
			default:
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('topBar')
			]),
		_List_fromArray(
			[$author$project$Main$viewMenuIcon, extraContent]));
};
var $author$project$Main$viewCommon = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Main$viewMenu(model),
				$author$project$Main$viewTopBar(model)
			]));
};
var $author$project$Main$GoToHomeClicked = {$: 23};
var $author$project$Main$viewErrorPage = F2(
	function (hero, body) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('errorPage')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('errorPageContent')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('hero')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(hero)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('body')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(body)
								])),
							A3(
							$author$project$Components$Button$viewButton,
							0,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Main$GoToHomeClicked)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Go to Home')
								]))
						]))
				]));
	});
var $author$project$Main$viewContent = function (model) {
	var _v0 = model.i;
	switch (_v0.$) {
		case 0:
			var boardModel = _v0.a;
			return _Utils_Tuple2(
				'Assign - Parzay',
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Main$viewCommon(model),
							A2(
							$elm$html$Html$map,
							$author$project$Main$BoardPageMsg,
							A3($author$project$Pages$Board$view, model.o, model.z, boardModel))
						])));
		case 1:
			var loginModel = _v0.a;
			return _Utils_Tuple2(
				'Login - Parzay',
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Main$LoginPageMsg,
							$author$project$Pages$Login$view(loginModel))
						])));
		case 2:
			var signUpModel = _v0.a;
			return _Utils_Tuple2(
				'Sign Up - Parzay',
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Main$SignUpPageMsg,
							$author$project$Pages$SignUp$view(signUpModel))
						])));
		case 3:
			var editorModel = _v0.a;
			return _Utils_Tuple2(
				'Edit Spaces - Parzay',
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Main$viewCommon(model),
							A2(
							$elm$html$Html$map,
							$author$project$Main$EditorPageMsg,
							$author$project$Pages$Editor$view(editorModel))
						])));
		case 4:
			var manageUsersModel = _v0.a;
			return _Utils_Tuple2(
				'Manage Users - Parzay',
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Main$viewCommon(model),
							A2(
							$elm$html$Html$map,
							$author$project$Main$UsersPageMsg,
							A2($author$project$Pages$Users$view, model.z, manageUsersModel))
						])));
		case 5:
			var profileModel = _v0.a;
			return _Utils_Tuple2(
				'Edit Profile - Parzay',
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Main$viewCommon(model),
							A2(
							$elm$html$Html$map,
							$author$project$Main$ProfilePageMsg,
							$author$project$Pages$Profile$view(profileModel))
						])));
		case 6:
			return _Utils_Tuple2(
				'Not found - Parzay',
				A2($author$project$Main$viewErrorPage, '404', 'Not Found'));
		default:
			return _Utils_Tuple2(
				'Error - Parzay',
				A2($author$project$Main$viewErrorPage, '500', 'Something Went Wrong'));
	}
};
var $author$project$Main$CloseUnexpectedError = {$: 12};
var $author$project$Main$viewGlobalError = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('globalError')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Something went wrong! Try refreshing the page or contacting support'),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('close'),
					$elm$html$Html$Events$onClick($author$project$Main$CloseUnexpectedError)
				]),
			_List_fromArray(
				[$author$project$Components$Icon$viewCloseIcon]))
		]));
var $author$project$Main$ModalClosed = {$: 16};
var $author$project$AddUsersModal$AddManyUsersClicked = {$: 4};
var $author$project$AddUsersModal$NewUserAdded = {$: 0};
var $author$project$AddUsersModal$NewUsersSubmitted = {$: 3};
var $author$project$AddUsersModal$GotCanAssign = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$AddUsersModal$GotIsAdmin = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$AddUsersModal$GotNewUserEmail = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$AddUsersModal$GotNewUserName = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$AddUsersModal$NewUserEmailBlurred = function (a) {
	return {$: 2, a: a};
};
var $author$project$AddUsersModal$NewUserRemoved = function (a) {
	return {$: 1, a: a};
};
var $author$project$AddUsersModal$addError = F2(
	function (showError, error) {
		if (showError) {
			return error;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$AddUsersModal$viewAddPersonRow = F3(
	function (showErrors, index, user) {
		var nameInput = A2(
			$author$project$Components$Form$viewTextField,
			A3(
				$author$project$Components$Form$TextField,
				'Name',
				user.U,
				A2($author$project$AddUsersModal$addError, showErrors, user.bF)),
			_List_fromArray(
				[
					$elm$html$Html$Events$onInput(
					$author$project$AddUsersModal$GotNewUserName(index))
				]));
		var isAdmin = A2(
			$author$project$Components$Form$viewCheckbox,
			A3($author$project$Components$Form$CheckboxField, 'Admin', user.bb, $elm$core$Maybe$Nothing),
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					A2(
						$author$project$AddUsersModal$GotIsAdmin,
						index,
						$author$project$Util$flipBool(user.bb)))
				]));
		var emailInput = A2(
			$author$project$Components$Form$viewTextField,
			A3(
				$author$project$Components$Form$TextField,
				'Email',
				user.R,
				A2($author$project$AddUsersModal$addError, showErrors, user.bA)),
			_List_fromArray(
				[
					$elm$html$Html$Attributes$placeholder('name@example.com'),
					$elm$html$Html$Events$onInput(
					$author$project$AddUsersModal$GotNewUserEmail(index)),
					$elm$html$Html$Events$onBlur(
					$author$project$AddUsersModal$NewUserEmailBlurred(index))
				]));
		var canAssign = A2(
			$author$project$Components$Form$viewCheckbox,
			A3($author$project$Components$Form$CheckboxField, 'Can Assign Spaces', user.a0, $elm$core$Maybe$Nothing),
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					A2(
						$author$project$AddUsersModal$GotCanAssign,
						index,
						$author$project$Util$flipBool(user.a0)))
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('newUserItem')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('inputs')
						]),
					_List_fromArray(
						[emailInput, nameInput])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('bottomRow')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('options')
								]),
							_List_fromArray(
								[canAssign, isAdmin])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('remove')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Components$Button$viewSmallButton,
									0,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$AddUsersModal$NewUserRemoved(index))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Remove')
										]))
								]))
						]))
				]));
	});
var $author$project$AddUsersModal$viewAdd = function (model) {
	var newUsers = model.f;
	var message = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var header = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var hasValues = A2(
		$elm$core$List$any,
		function (user) {
			return (user.U !== '') || (user.R !== '');
		},
		newUsers);
	var hasErrors = A2($elm$core$List$any, $author$project$AddUsersModal$userHasError, newUsers);
	var showErrors = function () {
		var _v1 = _Utils_Tuple2(model.aA, hasErrors);
		if ((_v1.a === 3) && _v1.b) {
			var _v2 = _v1.a;
			return true;
		} else {
			return false;
		}
	}();
	var rows = A2(
		$elm$core$List$intersperse,
		A2($elm$html$Html$hr, _List_Nil, _List_Nil),
		A2(
			$elm$core$List$indexedMap,
			$author$project$AddUsersModal$viewAddPersonRow(showErrors),
			newUsers));
	var exitButton = function () {
		if (hasValues) {
			return A2(
				$author$project$Components$Button$viewCancelButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$AddUsersModal$ModalClosed)
					]));
		} else {
			return A2(
				$author$project$Components$Button$viewCloseButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$AddUsersModal$ModalClosed)
					]));
		}
	}();
	var footer = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('buttons')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Button$viewButton,
				model.aA,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$AddUsersModal$NewUsersSubmitted)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Add People')
					])),
				exitButton
			]));
	var content = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('addUsersContent')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('rows')
					]),
				_List_fromArray(
					[
						A2($elm$html$Html$div, _List_Nil, rows)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('addActions')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('a'),
								$elm$html$Html$Events$onClick($author$project$AddUsersModal$NewUserAdded)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Add another')
							])),
						$elm$html$Html$text(' or '),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('a'),
								$elm$html$Html$Events$onClick($author$project$AddUsersModal$AddManyUsersClicked)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('add many at once')
							]))
					])),
				message
			]));
	return _Utils_Tuple3(header, content, footer);
};
var $author$project$AddUsersModal$GotBulkUserString = function (a) {
	return {$: 9, a: a};
};
var $author$project$AddUsersModal$GotListOfNewUsers = function (a) {
	return {$: 10, a: a};
};
var $author$project$AddUsersModal$ModalBack = {$: 12};
var $author$project$User$commaOrWhitespaceRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[,\\s+]'));
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$regex$Regex$split = _Regex_splitAtMost(_Regex_infinity);
var $elm$core$String$trim = _String_trim;
var $author$project$User$namesAndEmailsFromEmailList = function (rawList) {
	var resultsFromEmails = function (list) {
		return A2(
			$elm$core$List$map,
			function (email) {
				return _Utils_Tuple2(
					$author$project$User$getEmailUserName(email),
					email);
			},
			A2($elm$core$List$map, $elm$core$String$trim, list));
	};
	var emailsOnly = $elm$core$List$filter($author$project$Validation$emailIsValid);
	var angledBracketList = A2($elm$core$String$contains, '<', rawList) && A2($elm$core$String$contains, '<', rawList);
	var people = function () {
		if (angledBracketList) {
			return A2(
				$elm$core$List$map,
				function (parts) {
					var _v1 = function () {
						if ((parts.b && parts.b.b) && (!parts.b.b.b)) {
							var first = parts.a;
							var _v3 = parts.b;
							var last = _v3.a;
							return _Utils_Tuple2(first, last);
						} else {
							return _Utils_Tuple2('', '');
						}
					}();
					var namePart = _v1.a;
					var emailPart = _v1.b;
					var email = $elm$core$String$trim(
						A3($elm$core$String$replace, '>', '', emailPart));
					var name = $elm$core$String$trim(namePart);
					return _Utils_Tuple2(name, email);
				},
				A2(
					$elm$core$List$map,
					$elm$core$String$split('<'),
					A2($elm$core$String$split, ',', rawList)));
		} else {
			return resultsFromEmails(
				emailsOnly(
					A2($elm$regex$Regex$split, $author$project$User$commaOrWhitespaceRegex, rawList)));
		}
	}();
	return A2(
		$elm$core$List$filter,
		function (p) {
			return (p.a !== '') || (p.b !== '');
		},
		people);
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$AddUsersModal$viewAddMany = function (model) {
	var users = $author$project$User$namesAndEmailsFromEmailList(model.aL);
	var header = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('header')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('backButton'),
						$elm$html$Html$Events$onClick($author$project$AddUsersModal$ModalBack)
					]),
				_List_fromArray(
					[$author$project$Components$Icon$viewBackIcon]))
			]));
	var content = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Enter list of email addresses')
					])),
				A2(
				$elm$html$Html$textarea,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('bulkInput input'),
						$elm$html$Html$Attributes$value(model.aL),
						$elm$html$Html$Events$onInput($author$project$AddUsersModal$GotBulkUserString)
					]),
				_List_Nil)
			]));
	var cannotSubmit = !$elm$core$List$length(users);
	var footer = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('buttons')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Button$viewButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$disabled(cannotSubmit),
						$elm$html$Html$Events$onClick(
						$author$project$AddUsersModal$GotListOfNewUsers(users))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Ok')
					])),
				A2(
				$author$project$Components$Button$viewCancelButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$AddUsersModal$ModalBack)
					]))
			]));
	return _Utils_Tuple3(header, content, footer);
};
var $author$project$AddUsersModal$view = function (model) {
	var _v0 = model.aW;
	if (_v0) {
		return $author$project$AddUsersModal$viewAddMany(model);
	} else {
		return $author$project$AddUsersModal$viewAdd(model);
	}
};
var $author$project$ContactSupportModal$GotMessage = function (a) {
	return {$: 0, a: a};
};
var $author$project$ContactSupportModal$SendClicked = {$: 1};
var $author$project$ContactSupportModal$view = function (model) {
	var header = A2($elm$html$Html$div, _List_Nil, _List_Nil);
	var footer = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('actions')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Components$Button$viewCloseButton,
				0,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$ContactSupportModal$CloseClicked)
					])),
				A3(
				$author$project$Components$Button$viewButton,
				model.aG,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$ContactSupportModal$SendClicked)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Send')
					]))
			]));
	var content = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('What can we help you with?')
					])),
				A2(
				$elm$html$Html$textarea,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(model.aR),
						$elm$html$Html$Events$onInput($author$project$ContactSupportModal$GotMessage),
						$elm$html$Html$Attributes$class('supportMessage')
					]),
				_List_Nil)
			]));
	return _Utils_Tuple3(header, content, footer);
};
var $author$project$Main$viewModal = F2(
	function (model, modal) {
		if (!modal) {
			var _v1 = $author$project$AddUsersModal$view(model.aJ);
			var header = _v1.a;
			var content = _v1.b;
			var footer = _v1.c;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('topLevelModal')
					]),
				_List_fromArray(
					[
						A6(
						$author$project$Components$Modal$viewBasicModal,
						$elm$html$Html$Events$onClick($author$project$Main$ModalClosed),
						'addUsersModal',
						A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Add People')
								])),
						A2($elm$html$Html$map, $author$project$Main$AddUsersModalMsg, header),
						A2($elm$html$Html$map, $author$project$Main$AddUsersModalMsg, content),
						A2($elm$html$Html$map, $author$project$Main$AddUsersModalMsg, footer))
					]));
		} else {
			var _v2 = $author$project$ContactSupportModal$view(model.aN);
			var header = _v2.a;
			var content = _v2.b;
			var footer = _v2.c;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('topLevelModal')
					]),
				_List_fromArray(
					[
						A6(
						$author$project$Components$Modal$viewBasicModal,
						$elm$html$Html$Events$onClick($author$project$Main$ModalClosed),
						'contactSupportModal',
						A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Contact Support')
								])),
						A2($elm$html$Html$map, $author$project$Main$ContactSupportModalMsg, header),
						A2($elm$html$Html$map, $author$project$Main$ContactSupportModalMsg, content),
						A2($elm$html$Html$map, $author$project$Main$ContactSupportModalMsg, footer))
					]));
		}
	});
var $author$project$Main$view = function (model) {
	var userTypeClass = function () {
		var _v6 = model.o;
		if (_v6.$ === 1) {
			var user = _v6.a;
			var _v7 = user.c9;
			if (!_v7) {
				return 'admin';
			} else {
				return 'readOnly';
			}
		} else {
			return '';
		}
	}();
	var userRoleClass = function () {
		var _v4 = model.o;
		if (_v4.$ === 1) {
			var user = _v4.a;
			var _v5 = user.a0;
			if (_v5) {
				return 'canAssign';
			} else {
				return '';
			}
		} else {
			return '';
		}
	}();
	var globalError = function () {
		var _v3 = model.bt;
		if (_v3) {
			return $author$project$Main$viewGlobalError;
		} else {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	}();
	var _v0 = function () {
		var _v1 = $elm_community$list_extra$List$Extra$last(model.af);
		if (!_v1.$) {
			var m = _v1.a;
			return _Utils_Tuple2(
				A2($author$project$Main$viewModal, model, m),
				true);
		} else {
			return _Utils_Tuple2(
				A2($elm$html$Html$div, _List_Nil, _List_Nil),
				false);
		}
	}();
	var modal = _v0.a;
	var showingTopLevelModal = _v0.b;
	var _v2 = $author$project$Main$viewContent(model);
	var title = _v2.a;
	var content = _v2.b;
	return {
		dl: _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('mainContent'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('showingTopLevelModal', showingTopLevelModal),
								_Utils_Tuple2('demo', model.aO)
							])),
						$elm$html$Html$Attributes$class(userTypeClass),
						$elm$html$Html$Attributes$class(userRoleClass)
					]),
				_List_fromArray(
					[content])),
				modal,
				globalError
			]),
		d6: title
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		dF: $author$project$Main$init,
		dS: A2($elm$core$Basics$composeR, $author$project$Routes$match, $author$project$Main$UrlChanged),
		dT: $author$project$Main$LinkClicked,
		d3: $author$project$Main$subscriptions,
		d8: $author$project$Main$update,
		eb: $author$project$Main$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));