import './css/main.css';
import { Elm } from './Main.elm';
import { lock, unlock } from './js/growUp';
import ReconnectingWebSocket from 'reconnecting-websocket';
import jstz from 'jstimezonedetect';
import dateFormatDetect from './js/dateFormatDetect';

// monkey patch ajax calls so we can listen for 401s. When a 401 happens send message thru port so elm can
// route to login page
(function(open) {
    XMLHttpRequest.prototype.open = function(method, url, async, user, pass) {
        this.addEventListener("readystatechange", function() {
            if (this.status === 401) {
                if (app.ports.authFailed) {
                    app.ports.authFailed.send(true)
                }
            }
        }, false);

        open.call(this, method, url, async, user, pass);
    };

})(XMLHttpRequest.prototype.open)

window.addEventListener('touchstart', () => {
    // the user touched the screen! We can no be certain the device is touch screen
    // add a class to body so we can use this information in css
    document.body.classList.add('isTouchDevice');
    // and send it into elm
    if (app.ports.isTouchDevice) {
        app.ports.isTouchDevice.send(true)
    }
});

const flags = {
    now: new Date().toISOString().split('T')[0],
    timezone: jstz.determine().name(),
    mmddDateFormat: dateFormatDetect(),
};

if (window._demoBoard) {
    flags.board = window._demoBoard;
}

const app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: flags,
});

app.ports.captureErrorToJs.subscribe((errorString) => {
    // TODO log error
    console.log(errorString)

    if (app.ports.unexpectedError) {
        app.ports.unexpectedError.send(true);
    }
});

app.ports.toggleScrollLockForModal.subscribe(enabled => {
    if (enabled) {
        lock();
    } else {
        unlock();
    }
});

app.ports.getPastDays.subscribe((boardRange) => {
    // here we want to lock the board so it does not jump around while elm renders the new day
    lock();

    // then send in the board range data
    if (app.ports.gotPastDays) {
        app.ports.gotPastDays.send(JSON.stringify(boardRange))
    }

    // and after a small delay (to give elm a chance to render) we unlock
    setTimeout(() => {
        unlock();
    }, 200);
});

/////////// WEBSOCKETS ////////////////

let rws;

const devWebsocketUrl = 'ws://localhost:3030/ws'
const prodWebsocketUrl = location.origin.replace(/^https/, 'wss') + "/ws"

function connectWebsockets(url) {
    rws = new ReconnectingWebSocket(url);

    rws.addEventListener('message', (msg) => {
        if (app.ports.orgUpdateMsg) {
            app.ports.orgUpdateMsg.send(msg.data)
        }

        if (app.ports.boardUpdateMsg) {
            app.ports.boardUpdateMsg.send(msg.data)
        }
    });

    rws.addEventListener('error', (e) => {
        console.log('ERROR', e) // TODO log error
    });
}

window._c = connectWebsockets

// do not connect websockets for demo
if (window._demoBoard == null) {
    const websocketUrl = window.location.hostname === 'localhost' ? devWebsocketUrl : prodWebsocketUrl;

    connectWebsockets(websocketUrl);
}
