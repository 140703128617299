const mainContentDivId = 'mainContent';

let heightBefore;

function lock() {
    // get board scroll position
    const scrollTop =
        window.pageYOffset !== undefined
            ? window.pageYOffset
            : (document.documentElement || document.body.parentNode || document.body).scrollTop;

    const content = document.getElementById(mainContentDivId);

    if (!content) {
        throw 'Could not get content el';
    }

    const rec = content.getBoundingClientRect();

    // get height of board wrapper (scene)
    const boardHeight = rec.height;

    heightBefore = boardHeight - scrollTop;

    // get viewport height (viewport)
    const vpHeight = window.innerHeight || document.documentElement.clientHeight;

    const offset = (heightBefore - vpHeight) * -1;

    content.style.position = 'fixed';
    content.style.left = 0;
    content.style.right = 0;
    content.style.bottom = `${offset}px`;
}

window.lock = lock;

function unlock() {
    const content = document.getElementById(mainContentDivId);

    const heightAfter = content.offsetHeight;

    const newOffset = heightAfter - heightBefore;

    content.style.position = 'static';

    const scrollOffset = Math.abs(newOffset);

    window.scrollTo({
        top: scrollOffset,
    });
}

window.unlock = unlock;

module.exports.lock = lock;
module.exports.unlock = unlock;
